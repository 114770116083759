import { Box, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import React from "react";
import useSearchlogic from "../../hooks/useSearchlogic";
import { firstNWordsFromString } from "../Utils/shortenText";

const SearchOverlay = ({ resetSearchUrl }) => {
  const { queryTags } = useSearchlogic();
  const articleName = firstNWordsFromString(queryTags, 10);
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        maxWidth: "560px",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "50px",
      }}
    >
      <Tag
        size="md"
        borderRadius="md"
        variant="solid"
        bgColor="#F8F8F8"
        color="rgba(0,0,0,0.8)"
      >
        <TagLabel fontWeight="medium" fontSize="xs">
          {articleName}
        </TagLabel>
        <TagCloseButton
          onClick={() => {
            resetSearchUrl();
          }}
        />
      </Tag>
    </Box>
  );
};

export default SearchOverlay;

export const colors = {
  primary: "#191D30",
  secondary: "#282C3E",
  accentSnippets: "#CCC9DC",
  accentHighlights: "#A8DCD9",
  secondaryGrey: "#727272",
  tertiary: "#2D3748",
  success: "#45818E",
  default: "white",
  textGrey: "#7c7c7c",
  white: "#ffffff",
  black: "#000000",
  danger: "#FF35BA1A",
  modalCardBackground: "#282C3E",
  link: "#007AFF",
  aiAccent: {
    500: "#f4ac45",
  },
  progress: {
    500: "#191D30",
    200: "#ffffff",
  },
};

import {
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  List,
  ListIcon,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CheckIcon } from "@chakra-ui/icons";
import {
  deleteSubscription,
  handleStripePayment,
} from "../../../services/StripeSvc";
import useNotification from "../../../hooks/useNotification";
import { PLAN_INTERVALS } from "../../../data/SubscriptionData";
import { DowngradePlanModal } from "./DowngradePlanModal";

export const PlanCard = ({
  id,
  icon,
  title,
  priceMonth,
  priceYear,
  perks,
  currentPlan,
  subscriptionId,
}) => {
  const query = new URLSearchParams(useHistory().location.search);
  const planInterval = query.get("type") || PLAN_INTERVALS.MONTHLY;
  const currentPrice =
    planInterval === PLAN_INTERVALS.YEARLY ? priceYear : priceMonth;
  const durationLabel = planInterval === PLAN_INTERVALS.MONTHLY ? "/mo" : "/yr";
  const [isSubsribing, setIsSubscribing] = useState(false);
  const { notify } = useNotification();
  const showNotification = (title, description, status) => {
    notify({
      title: title,
      description: description,
      status: status,
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handlePayment = async (id, plan) => {
    if (isOpen) onClose();
    if (isSubsribing) return;
    setIsSubscribing(true);
    switch (id) {
      case "premium":
        // eslint-disable-next-line no-case-declarations
        const session = await handleStripePayment(
          plan,
          "user/profile/plans?payment=success",
          "user/profile/plans?payment=cancel"
        );
        window.location.assign(session.url);
        setIsSubscribing(false);
        break;
      case "enterprise":
        // open page in a new tab
        window.open(
          "https://calendly.com/mosaiqlabs/intro-call-with-mosaiq-labs-1",
          "_blank"
        );
        setIsSubscribing(false);
        break;
      default:
        //downgrade to starter
        console.log("downgrade to starter");
        // eslint-disable-next-line no-case-declarations
        const response = await deleteSubscription(subscriptionId);
        if (response) {
          showNotification(
            "Subscription canceled.",
            "We've deleted your subscription for you.",
            "success"
          );
        } else {
          showNotification(
            "Error canceling subscription.",
            "Please try again later.",
            "error"
          );
        }
        setIsSubscribing(false);
        break;
    }
  };
  return (
    <Flex
      align="center"
      w={{ base: "100%", "2xl": "70%", lg: "50%" }}
      flexDir="column"
      h="100%"
      pos="relative"
      justify="space-between"
      border={id === currentPlan && "1px solid black"}
      px="2"
      pt="4"
      pb="2"
      borderRadius="md"
    >
      <Flex gap={2} flexDir="column" align="center">
        <Text
          visibility={id !== "premium" ? "hidden" : "visible"}
          fontSize="12px"
          fontWeight="bold"
        >
          MOST POPULAR
        </Text>
        {<Icon as={icon} color="primary" fontSize="56px" fontWeight="bolder" />}
        <Text fontSize="18px">{title}</Text>
        {id !== "enterprise" ? (
          <Flex align="center" height="60px">
            <Text fontSize="35px" letterSpacing="0" fontWeight="bolder">
              US${currentPrice}
            </Text>
            <Text fontSize="13px" color="#727272">
              {durationLabel}
            </Text>
          </Flex>
        ) : (
          <Flex gap={4} align="center" justify="bottom" height="60px">
            <Text textAlign="center" color="#727272">
              Contact us <br /> for a bespoke consultation
            </Text>
          </Flex>
        )}
      </Flex>
      <Divider w="90%" my="8" borderColor="#BCBCBC" />
      <Flex w="90%" h="100%">
        <List w="100%" spacing={3}>
          {perks
            .filter(({ value }) => value)
            .map((perk) => {
              return (
                perk.value && (
                  <ListItem
                    w="100%"
                    display="flex"
                    alignContent="center"
                    key={perk.value}
                  >
                    <ListIcon color="#59808D" fontSize="11px" as={CheckIcon} />
                    <Text fontSize="11px" color="#212121">
                      {perk.value}
                    </Text>
                  </ListItem>
                )
              );
            })}
        </List>
      </Flex>
      <Button
        bg="primary"
        size="sm"
        h="fit-content"
        py="2"
        mt="12"
        fontSize="12px"
        w="100%"
        onClick={() => {
          if (id === "free") {
            onOpen();
          } else {
            handlePayment(id, planInterval);
          }
        }}
        isLoading={isSubsribing}
        isDisabled={id === currentPlan}
      >
        {id === currentPlan
          ? "Current Plan"
          : id === "free"
          ? "Downgrade to Starter"
          : id === "premium"
          ? "Upgrade"
          : id === "enterprise"
          ? "Book a call"
          : "Upgrade"}
      </Button>
      <DowngradePlanModal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        planName={currentPlan}
        subscriptionId={subscriptionId}
      />
    </Flex>
  );
};

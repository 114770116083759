import { useState, useEffect } from "react";
import { db, q as query, collection, onSnapshot } from "../firebase";

const useSharedWith = (projectId) => {
  const [sharedWith, setSharedWith] = useState([]);

  useEffect(() => {
    if (!projectId) return;

    const q = query(collection(db, "projects", projectId, "sharedWith"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log(`Updating sharedWith for ${projectId}`);
      const newSharedWith = [];
      querySnapshot.forEach((doc) => {
        newSharedWith.push({ ...doc.data(), uid: doc.id });
      });

      setSharedWith(newSharedWith);
    });

    return () => {
      unsubscribe();
    };
  }, [projectId]);

  return sharedWith;
};

export default useSharedWith;

import { db, doc, updateDoc, getDoc } from "../firebase.js";

export const checkIfTourTaken = (userId, tourType) => {
  const userRef = doc(db, "users/" + userId);
  return new Promise((resolve) => {
    getDoc(userRef)
      .then((doc) => {
        if (doc.exists()) {
          const projectTourObj = doc.data().projectTour[tourType];
          if (projectTourObj) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const checkIfOnboardingIsdone = (userId) => {
  const userRef = doc(db, "users/" + userId);
  return new Promise((resolve) => {
    getDoc(userRef)
      .then((doc) => {
        if (doc.exists()) {
          resolve(doc.data().onboardingProcess.completed);
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const updateOnboardingCompleted = async (userId) => {
  const userRef = doc(db, "users/" + userId);

  console.log("Onboarding Completed");
  await updateDoc(userRef, {
    "onboardingProcess.completed": true,
  });
};

export const updateOnboardingStep = (userId, step) => {
  const userRef = doc(db, "users/" + userId);

  updateDoc(userRef, {
    "onboardingProcess.step": step,
  }).then(() => {
    console.log("Onboarding Step Updated");
  });
};

export const getProjectTourDocRef = (userId) => {
  const userRef = doc(db, "users/" + userId);
  //Get the document
  return new Promise((resolve) => {
    getDoc(userRef)
      .then((doc) => {
        const projectTouObj = doc.data()?.projectTour;
        if (projectTouObj) {
          resolve({
            status: true,
            data: doc.data().projectTour,
            message: "docFound",
          });
        } else {
          resolve({ status: true, data: [], message: "noDoc" });
        }
      })
      .catch(() => {
        resolve({ status: true, data: false, message: "error" });
      });
  });
};

// create projectTourDoc
export const createProjectTourField = (userId, projectTourObject) => {
  const projectTourDocRef = doc(db, "users/" + userId);
  updateDoc(projectTourDocRef, { projectTour: projectTourObject }).then(() => {
    console.log("Project Tour Doc Created");
  });
};

export const tourTypeDone = (userId, tourType) => {
  const userRef = doc(db, "users/" + userId);
  //Get the document
  return new Promise((resolve) => {
    getDoc(userRef)
      .then((doc) => {
        // console.log(doc.data().projectTour);
        const projectTourObj = doc.data().projectTour;
        projectTourObj[tourType] = true;
        updateDoc(userRef, { projectTour: projectTourObj }).then(() => {
          resolve({
            status: true,
            data: doc.data().projectTour,
            message: "docFound",
          });
        });
      })
      .catch(() => {
        resolve({ status: true, data: false, message: "error" });
      });
  });
};

export const restartTour = (userId) => {
  const userRef = doc(db, "users/" + userId);
  //Get the document
  return new Promise((resolve) => {
    getDoc(userRef)
      .then((doc) => {
        const projectTourObj = doc.data().projectTour;
        for (let key in projectTourObj) {
          projectTourObj[key] = false;
        }
        updateDoc(userRef, { projectTour: projectTourObj }).then(() => {
          resolve({
            status: true,
            data: doc.data().projectTour,
            message: "docFound",
          });
        });
      })
      .catch(() => {
        resolve({ status: true, data: false, message: "error" });
      });
  });
};

import {
  arrayRemove,
  arrayUnion,
  db,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "../firebase";

export const archiveProject = ({ projectId, userDocRef }) => {
  // var docRef = doc(db, "projects/" + projectId);
  // updateDoc(docRef, {
  //   archived: true,
  // });
  updateDoc(userDocRef, {
    archivedProjects: arrayUnion(projectId),
  });
};

export const unArchiveProject = ({ projectId, userDocRef }) => {
  // var docRef = doc(db, "projects/" + projectId);
  // updateDoc(docRef, {
  //   archived: false,
  //   modifiedAt: Date.now(),
  // });
  updateDoc(userDocRef, {
    archivedProjects: arrayRemove(projectId),
  });
};

/**
 * Updates the pinnedProjects array of a user in Firestore database.
 * @param {string} userId - The ID of the user whose pinnedProjects array is to be updated.
 * @param {string} projectId - The ID of the project to be added or removed from the pinnedProjects array.
 * @param {boolean} removeFromArray - A flag indicating whether to remove the project from the pinnedProjects array (true) or add it (false).
 */
export const updateUserPinnedProjectsArr = (
  userId,
  projectId,
  removeFromArray
) => {
  const docRef = doc(db, "users/" + userId);
  updateDoc(docRef, {
    pinnedProjects: removeFromArray
      ? arrayRemove(projectId)
      : arrayUnion(projectId),
  });
};

/**
 * Retrieves the pinned projects of a user from the database.
 *
 * @param {string} userId - The ID of the user.
 * @param {function} callback - The callback function to be called with the pinned projects data.
 * @returns {function} - The unsubscribe function to stop listening to changes in the database.
 */
export const getUsersPinnedProjects = (userId, callback) => {
  const docRef = doc(db, `users/${userId}`);
  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      console.log("Updating pinned projects from db", doc.data());
      callback(doc.data().pinnedProjects);
    }
  });
  return unsubscribe;
};

export const getProject = ({ projectId, setProjectCallback }) => {
  var docRef = doc(db, "projects/" + projectId);
  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists) {
      console.log("Updating project from db", doc.data());
      setProjectCallback({ ...doc.data(), uid: doc.id });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such project!", projectId);
    }
  });
  return unsubscribe;
};

export const getProjectData = async (projectId) => {
  var docRef = doc(db, "projects/" + projectId);
  console.log(`Fetching project data for ${projectId}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), uid: docSnap.id };
  } else {
    return null;
  }
};

export const createProject = ({ projectId, payload }, handlePostSave) => {
  const projectDocRef = doc(db, "projects/" + projectId);
  setDoc(projectDocRef, payload)
    .then(() => {
      console.log("Added project", projectId);
      handlePostSave();
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
};

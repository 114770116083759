import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";

export const OnboardingCardPlan = ({
  tagline,
  frequency,
  handleSubscription,
  isLoading,
  title,
  priceMonth,
  priceYear,
  perks,
}) => {
  const { isPremium } = useAuth();

  const LinkButton = ({ text }) => (
    <Link as={ReactRouterLink} to={`/welcome?step=6`}>
      <Button w="100%" bg="primary" mt={4} color="white">
        {text}
      </Button>
    </Link>
  );

  const SubscriptionButton = ({ text }) => (
    <Button
      w="100%"
      bg="primary"
      mt={4}
      color="white"
      onClick={() => handleSubscription()}
      isLoading={isLoading}
      isDisabled={isPremium}
    >
      {text}
    </Button>
  );

  return (
    <Flex
      maxW="50%"
      minW="50%"
      m="0 auto"
      flexDir="column"
      h="100%"
      justify="space-between"
    >
      <Flex
        flexDir="column"
        p="1.4rem"
        bg={priceMonth > 0 ? "white" : "transparent"}
        borderRadius="md"
      >
        <Flex align="end" gap={2} mb={4}>
          <Heading fontSize="18px">{title}</Heading>
          <Text fontSize="11px">{tagline}</Text>
        </Flex>
        {priceMonth > 0 ? (
          <Flex align="end" gap={1} mb={4}>
            <Heading fontSize="18px">
              US$
              {frequency === "month" ? priceMonth : priceYear}
            </Heading>
            <Heading fontSize="11px" color="#8C8E98">
              per {frequency}
            </Heading>
          </Flex>
        ) : (
          <Flex align="end" gap={1} mb={4}>
            <Heading fontSize="18px">Free</Heading>
          </Flex>
        )}
        <Divider orientation="horizontal" />
        <List my={6} spacing={2}>
          {perks
            .filter(({ value }) => value)
            .map((perk) => {
              return (
                perk.value && (
                  <ListItem
                    w="100%"
                    display="flex"
                    alignContent="center"
                    key={perk.value}
                  >
                    <ListIcon color="#59808D" fontSize="11px" as={CheckIcon} />
                    <Text fontSize="11px" color="#212121" textAlign="left">
                      {perk.value}
                    </Text>
                  </ListItem>
                )
              );
            })}
        </List>
        {/* Disabled because it doesn't lead anywhere */}
        {/* <Flex>
          <Link
            visibility={priceMonth > 0 ? "visible" : "hidden"}
            isExternal
            fontSize="13px"
            href="http://www.mosaiqlabs.com/plans#premium"
            textDecor="underline"
            color="#0C36F9"
          >
            See all the available features
          </Link>
        </Flex> */}
      </Flex>
      {priceMonth > 0 ? (
        isPremium ? (
          <LinkButton text={"Already Premium"} />
        ) : (
          <SubscriptionButton text={"Upgrade to Premium"} />
        )
      ) : isPremium ? null : (
        <LinkButton text={"Continue with starter plan"} />
      )}
    </Flex>
  );
};

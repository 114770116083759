import {
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiFillMail } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getCustomizationData } from "../../Components/Utils/customizationUtils";
import { auth, signInWithGoogle } from "../../firebase";

const customization = getCustomizationData();

export const SignUpPage = () => {
  const [initSignInWithGoogle, setInitSignInWithGoogle] = useState(false);
  const [userAuth, loading] = useAuthState(auth);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (userAuth) {
      //If userAuth email is not verfiefied, send them to the email verification page
      if (!userAuth.emailVerified) {
        history.replace("/verifyemail");
      } else {
        if (location.state && location.state.from) {
          history.replace(location.state.from);
        } else {
          history.replace("/user");
        }
      }
    }
  }, [userAuth, loading]);
  return (
    <Flex
      flexDir="column"
      position="relative"
      height="100vh"
      align="center"
      justify="center"
      w={{ lg: "50%", base: "80%", "2xl": "25%" }}
      m="0 auto"
    >
      <Heading mb={12} fontSize="24px" color="#1D1D1D" fontWeight="500">
        Welcome to {customization.name}
      </Heading>
      <Button
        w="fit-content"
        leftIcon={
          initSignInWithGoogle ? (
            <Spinner
              size="sm"
              display={initSignInWithGoogle ? "block" : "none"}
            />
          ) : (
            <FcGoogle fontSize="16px" />
          )
        }
        color="tertiary"
        fontWeight="600"
        fontSize="14px"
        border="1px solid rgba(0, 0, 0, 0.12)"
        onClick={async () => {
          await signInWithGoogle();
          setInitSignInWithGoogle(true);
        }}
      >
        <Text>Sign up with Google</Text>
      </Button>
      <Flex w="80%" my={2} flexDir="row" align="center" justify="center">
        <Divider borderColor="rgba(0, 0, 0, 0.12)" />
        <Text mx={4} color="tertiary" fontSize="14px">
          Or
        </Text>
        <Divider borderColor="rgba(0, 0, 0, 0.12)" />
      </Flex>
      <Button
        as={ReactRouterLink}
        to="/signup-with-email"
        w="fit-content"
        leftIcon={<AiFillMail fontSize="16px" />}
        mt={0}
        color="tertiary"
        fontWeight="600"
        fontSize="14px"
        border="1px solid rgba(0, 0, 0, 0.12)"
      >
        <Text>Sign up with email</Text>
      </Button>
      <Flex mt={8}>
        <Text color="primary" fontSize="14px">
          Already have an account?{" "}
          <Link color="#007AFF" as={ReactRouterLink} to="/">
            Sign in
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};

import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useLimitations } from "../../hooks/useLimitations";

const AssistantCreditInfoComponent = () => {
  const history = useHistory();
  const { aiCreditsTotal, assistantDisabled } = useLimitations();
  return (
    <Flex align={"center"} gap="5px">
      <Text
        color={assistantDisabled ? "#cc3300" : "black"}
        fontWeight="medium"
        fontSize="xs"
        pl={4}
        noOfLines={1}
        flexShrink={0}
      >
        AI Credits: {aiCreditsTotal}
      </Text>
      <Tooltip
        label={
          assistantDisabled
            ? "You have no AI Credits left. Click here to upgrade your plan to continue using the assistant."
            : "AI Credits are used to get responses from the assistant."
        }
        placement="bottom"
        hasArrow
      >
        <Flex>
          <Icon
            as={AiOutlineInfoCircle}
            color={assistantDisabled ? "#cc3300" : "#000000"}
            opacity={assistantDisabled ? "1" : "0.7"}
            onClick={
              assistantDisabled
                ? () => {
                    history.push("/user/profile/plans");
                  }
                : null
            }
            cursor={assistantDisabled ? "pointer" : "default"}
            boxSize={"16px"}
          />
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default AssistantCreditInfoComponent;

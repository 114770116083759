import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ZoomContextProvider } from "../Context/ZoomContext";

import { createModulePermission } from "../Components/AiModuleEditor/multistepAiModuleUtils";
import { PROJECT_TOUR_ALL_STEPS_DONE } from "../Components/Tour/TourComponent";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import {
  auth,
  db,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "../firebase";
import { LoadingAuth } from "./LoadingAuth";

const customization = getCustomizationData();

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [userDocLoading, setUserDocLoading] = useState(true);
  const [userDocRef, setUserDocRef] = useState(null);
  const [userDoc, setUserDoc] = useState(null);
  const [authClaims, setAuthClaims] = useState({});
  const [isAiModulesAdmin, setIsAiModulesAdmin] = useState(false);
  const [currentUser, loading] = useAuthState(auth);
  const [isPowerUser, setIsPowerUser] = useState(false);
  const updateLoginStatus = async (userDocRef, status) => {
    updateDoc(userDocRef, {
      status,
    }).catch((err) => {
      // When user is just created, before the userDoc is created, we try to update the status to online,
      // and it will fail. This is expected to happen and we can ignore the error.
      console.log(
        `Error updating user status to ${status}: ${err}. This is expected to happen 1 time for a new user.
        If you see this error more than once, or if you see this error for an existing user, it is wrong.
        `
      );
    });
  };

  const logout = async () => {
    //Update user status to offline
    const userDocRef = doc(db, `users/${currentUser.uid}`);
    updateLoginStatus(userDocRef, "offline").then(async () => {
      await auth.signOut();
    });
  };

  // load userDoc
  useEffect(() => {
    if (loading) {
      console.log("AuthState is loading, waiting");
      setUserDocLoading(true);
      return;
    }
    if (!currentUser) {
      console.log("No current user, setting userDoc to null");
      setUserDoc(null);
      setUserDocLoading(false);
      return;
    }

    // retrieve user custom claims
    currentUser.getIdTokenResult().then((idTokenResult) => {
      setAuthClaims(idTokenResult.claims);
      setIsAiModulesAdmin(!!idTokenResult.claims.aiModulesAdmin);
    });

    // if email ends with @mosaiqlabs.com
    setIsPowerUser(currentUser.email.endsWith("@mosaiqlabs.com"));

    const userDocRef = doc(db, `users/${currentUser.uid}`);
    setUserDocRef(userDocRef);
    // whenever we access userDoc, it means the user is online
    try {
      updateLoginStatus(userDocRef, "online");
    } catch (err) {
      console.log(`Error updating user status to online: ${err}`);
    }
    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        console.log(`Updating userDoc for ${currentUser.uid}`);
        if (!doc.exists()) {
          const userInitialData = {
            status: "online",
            createdAt: Date.now(),
            authProvider: currentUser.providerData[0].providerId ?? "",
          };

          if (customization.disableOnboarding) {
            userInitialData.onboardingProcess = {
              completed: true,
            };
          }

          if (customization.disableProductTour) {
            userInitialData.projectTour = PROJECT_TOUR_ALL_STEPS_DONE;
          }

          if (customization.skipSampleProject) {
            userInitialData.skipSampleProject = true;
          }

          setDoc(userDocRef, userInitialData, { merge: true });
        } else {
          console.log(`UserDoc for ${currentUser.uid} exists`);
          setUserDoc(doc.data());
          setUserDocLoading(false);
        }
      },
      (err) => {
        console.log(`Error updating userDoc for ${currentUser.uid}: ${err}`);
      }
    );
    return () => {
      console.log(`Unsubscribing from userDoc for ${currentUser.uid}`);
      setUserDocLoading(true);
      unsubscribe();
    };
  }, [currentUser, loading]);

  const addCustomAiModule = useCallback(
    async (moduleId) => {
      await createModulePermission({
        moduleId,
        userId: currentUser.uid,
        userEmail: currentUser.email,
        role: "user",
      });
    },
    [currentUser?.uid]
  );

  const removeCustomAiModule = useCallback(
    async (moduleId) => {
      const permissionDocRef = doc(
        db,
        `aiModules/${moduleId}/aiModulePermissions/${currentUser.uid}`
      );
      await deleteDoc(permissionDocRef);
    },
    [currentUser?.uid]
  );

  const contextValue = {
    currentUser,
    authClaims,
    isAiModulesAdmin,
    userDoc,
    userDocRef,
    isPremium: userDoc?.subscriptionStatus === "premium",
    isPowerUser,
    logout,
    addCustomAiModule,
    removeCustomAiModule,
  };

  if (loading || userDocLoading) {
    console.log("Loading auth, userDoc or userClaims");
    return <LoadingAuth />;
  }

  if (currentUser && !userDoc) {
    console.log("User is logged in but userDoc is null");
    return <LoadingAuth />;
  }

  // console.log("AuthContext.Provider value:", contextValue);
  return (
    <AuthContext.Provider value={contextValue}>
      <ZoomContextProvider>{children}</ZoomContextProvider>
    </AuthContext.Provider>
  );
}

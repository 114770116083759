import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useSearchlogic = () => {
  const [hasOverlay, setHasOverlay] = useState(false);
  const { projectId } = useParams();
  const currentSearchParams = new URLSearchParams(location.search);
  const poppedArticle = currentSearchParams.get("poppedArticle");
  const [queryTags, setQueryTags] = useState("");
  const history = useHistory();

  const resetSearchUrl = () => {
    currentSearchParams.delete("poppedArticle");
    history.replace(`?${currentSearchParams.toString()}`);
  };

  useEffect(() => {
    if (projectId && poppedArticle) {
      setHasOverlay(true);
      getDoc(doc(db, `projects/${projectId}/articles/${poppedArticle}`)).then(
        (doc) => setQueryTags(doc.data().title)
      );
      return;
    }
    setHasOverlay(false);
  }, [hasOverlay, poppedArticle]);

  return {
    hasOverlay,
    setHasOverlay,
    resetSearchUrl,
    queryTags,
  };
};

export default useSearchlogic;

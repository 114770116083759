import { createContext, useContext, useState } from "react";
import { CONTENT_TYPE } from "../data/ContentModalData";
import useNotification from "../hooks/useNotification";

const ContentContext = createContext();


export const ContentContextProvider = ({ children }) => {
  const [content, setContent] = useState(CONTENT_TYPE.DEFAULT);
  const { notify } = useNotification();
  
  const showToaster = (title, description, status) => {
     notify({
       title,
       description,
       status,
       duration: 5000,
     });
   };
  return (
    <ContentContext.Provider
      value={{
        content,
        setContent,
        showToaster
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  const context = useContext(ContentContext);
  if (context === undefined) {
    throw new Error("context must be used within a AssistantProvider");
  }
  return context;
};

export default ContentContext;

import { AvatarGroup, Button, Flex, useDisclosure, Icon } from "@chakra-ui/react";
import React from "react";
import SharedWithAvatar from "../../Elements/SharedWithAvatar";
import ShareIconComponent from "../../Icons/ShareIconComponent";
import { SharingSettingModal } from "./SharingSettingModal";

export const SharingView = ({ projectData, isEditor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex gap={2} justify="right" align="center">
      <Flex>
        <AvatarGroup gap="4px" size="sm" max={2}>
          {projectData.authorDisplayName && (
            <SharedWithAvatar
              user={{
                photoURL: projectData.authorPhotoURL,
                displayName: projectData.authorDisplayName,
              }}
              tooltipPrefix="Owner: "
            />
          )}
        </AvatarGroup>
      </Flex>
      {isEditor() && (
        <>
          <Button
            borderColor="white"
            variant="outline"
            color="primary"
            borderRadius="8px"
            fontSize="12px"
            size="md"
            fontWeight="bold"
            bg="white"
            _hover={{ bg: "#E2E8F0" }}
            _active={{ bg: "#E2E8F0" }}
            _focus={{ boxShadow: "none" }}
            p="0.4rem 1rem"
            h="fit-content"
            onClick={onOpen}
            leftIcon={<Icon as={ShareIconComponent} />}
          >
            Share
          </Button>
          <SharingSettingModal
            projectData={projectData}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl"
          />
        </>
      )}
    </Flex>
  );
};

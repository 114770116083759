import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  Box,
  Flex,
  Text,
  IconButton,
  HStack,
  Spacer,
  Input,
  InputGroup,
  Button,
  chakra,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { RiCloseLine } from "react-icons/ri";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { auth } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { formateDate, formatTimestamp } from "../Utils/dateutils";
import { sortCommentByTimestamp } from "../Utils/commentUtil";
import { useUserPermission } from "../../Context/UserPermissionContext";

const CommentModal = ({
  close,
  isOpen,
  content,
  createFunc,
  rootId,
  deleteFunc,
}) => {
  const [mode, setMode] = useState("VIEW");
  const [newComment, setNewComment] = useState("");
  const authorId = auth?.currentUser?.uid;
  const [currentCommentEdit, setCurrentCommentEdit] = useState(null);
  const { isReader } = useUserPermission();

  const CommentModalCard = ({ comment }) => {
    const [truncate, setTruncate] = useState(1);
    return (
      <Box mt="4" px="12px" pb={1} bg="modalCardBackground" rounded="md">
        <Flex alignItems="center">
          <Box flex="1">
            <Text
              pt={4}
              fontSize="14px"
              color="white"
              fontWeight="black"
              noOfLines={truncate}
            >
              {comment.text}
            </Text>
            <Text
              onClick={() => {
                truncate >= 1 ? setTruncate(0) : setTruncate(1);
              }}
              cursor="pointer"
              display={comment.text.length >= 60 ? "block" : "none"}
              textDecoration="underline"
              fontSize="10px"
              color="white"
            >
              {truncate >= 1 ? "Show more" : "Show less"}
            </Text>
          </Box>
          <HStack>
            <Button
              onClick={() => {
                setCurrentCommentEdit(comment.uid);
                setNewComment(comment.text);
              }}
              w="20px"
              h="20px"
              p="0"
              bg="primary"
              color="white"
              rounded="md"
              isDisabled={isReader()}
            >
              <EditIcon boxSize="3" p="0" />
            </Button>
            <Button
              onClick={() =>
                deleteFunc({
                  projectId: rootId,
                  docId: content.uid,
                  payload: comment,
                })
              }
              w="20px"
              h="20px"
              p="0"
              bg="primary"
              color="white"
              rounded="md"
              isDisabled={isReader()}
            >
              <DeleteIcon boxSize="3" />
            </Button>
          </HStack>
        </Flex>
        <Flex mt="1">
          <Spacer />
          <Text fontSize="xs" color="white">
            Created {formateDate(formatTimestamp(comment?.createdAt))}
          </Text>
        </Flex>
      </Box>
    );
  };

  const createComment = () => {
    const payload = {
      text: newComment,
      createdAt: Date.now(),
      createdBy: authorId,
      uid: uuidv4(),
    };
    createFunc({ projectId: rootId, docId: content.uid, payload });
    setNewComment("");
    setMode("VIEW");
  };

  const updateComment = (comment) => {
    deleteFunc({ projectId: rootId, docId: content.uid, payload: comment });
    const payload = {
      text: newComment,
      createdAt: comment.createdAt,
      createdBy: authorId,
      uid: comment.uid,
    };
    createFunc({ projectId: rootId, docId: content.uid, payload });
    setCurrentCommentEdit(null);
    setNewComment("");
    setMode("VIEW");
  };
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={close}
      isOpen={isOpen}
      isCentered
      size="90%"
    >
      <AlertDialogOverlay />
      <AlertDialogContent bg="primary" w="70%">
        <Box p={8} h="70vh">
          <Flex>
            <Box flex="1" overflow="scroll">
              <Text fontSize="20" fontWeight="black" color="white">
                All comments for{" "}
                <chakra.span display="inline-block" textDecor="underline">
                  {content?.title}
                </chakra.span>
              </Text>
            </Box>
            <IconButton
              aria-label="Close Modal"
              size="sm"
              onClick={close}
              icon={<RiCloseLine />}
              variant="solid"
              bg="#282C3E"
            />
          </Flex>
          <Box mt="10" maxHeight="400px" overflowY="scroll">
            {sortCommentByTimestamp(content?.comments)?.map((comment) => (
              <div id="note" key={comment.uid}>
                <div style={{ width: "100%" }}>
                  <div>
                    {comment.uid === currentCommentEdit ? (
                      <Flex mt="4" alignItems="flex-end" gap="0.4rem">
                        <InputGroup size="lg">
                          <Input
                            pr="4.5rem"
                            placeholder="Type here..."
                            name="newNote"
                            onChange={(e) => setNewComment(e.target.value)}
                            value={newComment}
                            color="white"
                            borderRadius="0"
                            border="none"
                            borderBottom="2px solid white"
                            _focusVisible={{ outline: "none" }}
                            _active={{ outline: "none" }}
                            pl="0"
                          />
                        </InputGroup>
                        <HStack rowGap={6}>
                          <Button
                            border="1px solid transparent"
                            bg="white"
                            size="sm"
                            color="primary"
                            fontSize="13px"
                            onClick={() => updateComment(comment)}
                          >
                            Update Comment
                          </Button>
                          <Button
                            onClick={() => {
                              setCurrentCommentEdit("");
                            }}
                            bg="transparent"
                            size="sm"
                            border="1px solid white"
                            color="white"
                            fontSize="13px"
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </Flex>
                    ) : (
                      <CommentModalCard comment={comment} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Box>
          {mode === "ADD" && (
            <Flex mt="4" alignItems="flex-end" gap="0.4rem">
              <InputGroup size="lg" w="90%">
                <Input
                  pr="4.5rem"
                  placeholder="Type here..."
                  name="newComment"
                  onChange={(e) => setNewComment(e.target.value)}
                  value={newComment}
                  color="white"
                  borderRadius="0"
                  border="none"
                  borderBottom="2px solid white"
                  _focusVisible={{ outline: "none" }}
                  _active={{ outline: "none" }}
                  pl="0"
                />
              </InputGroup>
              <HStack rowGap={6}>
                <Button
                  border="1px solid transparent"
                  bg="white"
                  size="xs"
                  color="primary"
                  fontSize="13px"
                  onClick={createComment}
                  px={1}
                >
                  Add comment
                </Button>
                <Button
                  onClick={() => setMode("VIEW")}
                  bg="transparent"
                  size="xs"
                  border="1px solid white"
                  color="white"
                  fontSize="13px"
                  px={1}
                >
                  Cancel
                </Button>
              </HStack>
            </Flex>
          )}
          <Flex mt="10">
            <Spacer />
            <Tooltip
              label={
                isReader()
                  ? "You cannot add comments, because you are not an editor in this project"
                  : ""
              }
            >
              <Button
                onClick={() => setMode("ADD")}
                cursor="pointer"
                py="2"
                px="4"
                rounded="lg"
                bg="modalCardBackground"
                color="white"
                display={mode === "VIEW" ? "flex" : "none"}
                isDisabled={isReader() ? true : false}
              >
                <HStack>
                  <AiOutlinePlusCircle />
                  <Text fontSize="14px" fontWeight="black">
                    Add New Comment
                  </Text>
                </HStack>
              </Button>
            </Tooltip>
          </Flex>
        </Box>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CommentModal;

import { BsTable } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import AudioIcon from "../Components/Icons/AudioIcon";
import DocxIcon from "../Components/Icons/DocxIcon";
import PdfIconComponent from "../Components/Icons/PdfIconComponent";
import { PdfIcon } from "../Components/Icons/PdfIcons";
import SlidesIcon from "../Components/Icons/SlidesIcon";
import WebIconComponent from "../Components/Icons/WebIconComponent";
import { VIEW_MODE_MAP } from "./ViewModeData";

export const WHITELIST = [
  "https://docs.google.com/",
  "https://drive.google.com/",
  "https://mail.google.com/",
  "https://www.youtube.com/",
  "https://www.hubspot.com/",
  "https://mailchimp.com/",
  "https://github.com/",
  // 'https://www.linkedin.com/',
  "https://www.facebook.com/",
  "https://twitter.com/",
  "https://www.instagram.com/",
  "https://www.pinterest.com/",
  "https://www.reddit.com/",
  "https://about.gitlab.com/",
  "https://www.canva.com/",
  "https://www.figma.com/",
  "https://www.sketch.com/",
  "https://www.invisionapp.com/",
  "https://www.adobe.com/",
  "https://www.photoshop.com/",
  "https://www.affinity.co/",
  "https://evernote.com/",
  "https://www.notion.so/",
  "https://www.trello.com/",
  "https://www.asana.com/",
  "https://www.monday.com/",
  "https://www.clickup.com/",
  "https://www.atlassian.com/",
  "https://slack.com/",
  "https://www.salesforce.com/",
  "https://www.dropbox.com/",
  "https://www.box.com/",
  "https://www.google.com/",
  "https://www.bing.com/",
  "https://www.yahoo.com/",
  "https://onedrive.live.com/",
  "https://www.icloud.com/",
  "https://www.amazon.com/",
  "https://www.ebay.com/",
  "https://microsoft.sharepoint.com/",
  "https://www.onenote.com/",
];

export const isUrlInWhitelist = (url, whitelist) => {
  const baseUrl = new URL(url).origin;
  console.log(`Checking if ${url} is in whitelist, base url is ${baseUrl}`);
  for (let i = 0; i < whitelist.length; i++) {
    if (whitelist[i].includes(baseUrl)) {
      console.log(`Found ${url} in whitelist`);
      return true;
    }
  }
  return false;
};

// options for what is opened when article title is clicked on the project page
export const TITLE_OPENS = {
  url: "url",
  viewer: "viewer",
};

export const ACTIONS = {
  AI_INSIGHT: "AI_INSIGHT",
  OPEN_VIEWER: "OPEN_VIEWER",
  COMMENT: "COMMENT",
  GO_TO_LINK: "GO_TO_LINK",
  DOWNLOAD: "DOWNLOAD",
};

export const VIEW_FILE_FUNCTIONS = {
  DOWNLOAD: "DOWNLOAD",
  VIEWER: "VIEWER",
  OPEN_LINK: "OPEN_LINK",
};

const defaultArticleType = {
  uiName: "Unknown",
  uiNamePlural: "Unknown",
  insightTypes: [],
  actions: [],
  requestFromVectorDb: false,
  titleOpens: null,
  icon: null,
  fileType: null,
};

export const ARTICLE_TYPE_DATA = new Proxy(
  {
    pdf: {
      uiName: "PDF",
      uiNamePlural: "PDFs",
      insightTypes: ["summary", "keyPoint"],
      actions: [
        ACTIONS.AI_INSIGHT,
        ACTIONS.OPEN_VIEWER,
        ACTIONS.COMMENT,
        ACTIONS.GO_TO_LINK,
        ACTIONS.DOWNLOAD,
      ],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: PdfIcon,
      defaultInsightCardMode: VIEW_MODE_MAP.comfort.id,
      fileType: "pdf",
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "url",
      downloadBttnTxt: "Download",
    },
    html: {
      uiName: "Web article",
      uiNamePlural: "Web articles",
      insightTypes: ["summary", "keyPoint"],
      actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: WebIconComponent,
      viewFileFunction: VIEW_FILE_FUNCTIONS.OPEN_LINK,
    },
    supportingLink: {
      uiName: "Link",
      uiNamePlural: "Links",
      insightTypes: [],
      actions: [ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
      requestFromVectorDb: false,
      titleOpens: TITLE_OPENS.url,
      icon: IoDocumentTextOutline,
      skipIndexing: true,
      hideMarkAsLink: true,
      toolTip: `Links cannot be accessed by AI Assistant. You can use the "Text" option in Add Content menu to copy-paste the link content manually.`,
      viewFileFunction: VIEW_FILE_FUNCTIONS.OPEN_LINK,
    },
    table: {
      uiName: "Table",
      uiNamePlural: "Tables",
      insightTypes: ["summary", "keyPoint"],
      actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: BsTable,
      viewFileFunction: VIEW_FILE_FUNCTIONS.DOWNLOAD,
    },
    voice: {
      uiName: "Voice recording",
      uiNamePlural: "Voice recordings",
      insightTypes: ["summary", "keyPoint"],
      actions: [
        ACTIONS.AI_INSIGHT,
        ACTIONS.OPEN_VIEWER,
        ACTIONS.COMMENT,
        ACTIONS.GO_TO_LINK,
        ACTIONS.DOWNLOAD,
      ],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: AudioIcon,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "pathToPdf",
      downloadBttnTxt: "Download transcript",
    },
    docx: {
      uiName: "Docx",
      uiNamePlural: "Docx",
      insightTypes: ["summary", "keyPoint"],
      actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: DocxIcon,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "pathToPdf",
    },
    pptx: {
      uiName: "PPTX",
      uiNamePlural: "PPTX",
      insightTypes: ["summary", "keyPoint"],
      actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.GO_TO_LINK],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: SlidesIcon,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "pathToPdf",
    },
    intelqVerbatim: {
      uiName: "Statement",
      uiNamePlural: "Statements",
      insightTypes: ["summary", "keyPoint"],
      actions: [
        ACTIONS.AI_INSIGHT,
        ACTIONS.OPEN_VIEWER,
        ACTIONS.COMMENT,
        ACTIONS.GO_TO_LINK,
        ACTIONS.DOWNLOAD,
      ],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      fileType: "pdf",
      icon: PdfIconComponent,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "url",
      downloadBttnTxt: "Download",
    },
    intelqQna: {
      uiName: "Q&A",
      uiNamePlural: "Q&As",
      insightTypes: ["summary", "keyPoint"],
      actions: [
        ACTIONS.AI_INSIGHT,
        ACTIONS.OPEN_VIEWER,
        ACTIONS.COMMENT,
        ACTIONS.GO_TO_LINK,
        ACTIONS.DOWNLOAD,
      ],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      fileType: "pdf",
      icon: PdfIconComponent,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "url",
      downloadBttnTxt: "Download",
    },
    plainText: {
      uiName: "Text file",
      uiNamePlural: "Text files",
      insightTypes: ["summary", "keyPoint"],
      actions: [ACTIONS.AI_INSIGHT, ACTIONS.COMMENT, ACTIONS.OPEN_VIEWER],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: IoDocumentTextOutline,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "pathToPdf",
    },
    smartImage: {
      uiName: "Smart Image",
      uiNamePlural: "Smart Images",
      insightTypes: ["summary", "keyPoint"],
      actions: [
        ACTIONS.AI_INSIGHT,
        ACTIONS.COMMENT,
        ACTIONS.GO_TO_LINK,
        ACTIONS.OPEN_VIEWER,
      ],
      requestFromVectorDb: true,
      titleOpens: TITLE_OPENS.viewer,
      icon: IoDocumentTextOutline,
      viewFileFunction: VIEW_FILE_FUNCTIONS.VIEWER,
      viewFilePdfField: "pathToPdf",
    },
  },
  {
    get: function (target, name) {
      if (!(name in target)) {
        console.error(`Article type ${name} not found`);
        return defaultArticleType;
      }
      return target[name];
    },
  }
);

import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import useNotification from "../../../hooks/useNotification";
import { deleteSubscription } from "../../../services/StripeSvc";
import { plansList } from "./plansLists";
import { getCustomizationData } from "../../Utils/customizationUtils";

const customization = getCustomizationData();

const ComparismItem = ({ planData, compareName }) => {
  const item = planData.perks.find((item) => item.compareName === compareName);
  // if compareValue is boolean, make primary color
  const color = typeof item.compareValue === "boolean" ? "primary" : "#909090";

  return (
    <Text
      fontSize="11px"
      w="50%"
      textAlign="center"
      display="flex"
      justifyContent="center"
      color={color}
    >
      {item.compareValue === true ? (
        <AiOutlineCheck />
      ) : item.compareValue === false ? (
        <AiOutlineClose />
      ) : (
        item.compareValue
      )}
    </Text>
  );
};

export const DowngradePlanModal = ({ ...props }) => {
  const currentUserPlan = props.planName;
  const newSelectedPlan = "free";
  const [isCanceling, setIsCanceling] = React.useState(false);
  const { notify } = useNotification();
  const showNotification = (title, description, status) => {
    notify({
      title: title,
      description: description,
      status: status,
    });
  };
  const currentPlanData = plansList[currentUserPlan];
  const newSelectedPlanData = plansList[newSelectedPlan];

  const allPlans = [
    {
      compareName: "Unlimited Projects",
    },
    {
      compareName: "Unlimited articles / file uploads",
    },
    {
      compareName: "PDF Pages",
    },
    {
      compareName: "Sharing and collaboration",
    },
    {
      compareName: "AI responses",
    },
  ];

  const cancelSubscription = async () => {
    setIsCanceling(true);
    const response = await deleteSubscription(props.subscriptionId);
    if (response) {
      showNotification(
        "Subscription canceled.",
        "We've deleted your subscription for you.",
        "success"
      );
      props.onClose();
    } else {
      showNotification(
        "Error canceling subscription.",
        "Please try again later.",
        "error"
      );
    }
    setIsCanceling(false);
  };
  return (
    <Modal size="3xl" isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent w="609px">
        <ModalHeader>
          <Text fontSize="18px" fontWeight="bold">
            Are you sure you want to downgrade your {customization.name}{" "}
            subscription?
          </Text>
          <Text mt={6} fontSize="12px" fontWeight="normal">
            By downgrading your plan from{" "}
            <Text textTransform="uppercase" display="inline-block">
              {currentUserPlan}
            </Text>{" "}
            to{" "}
            <Text textTransform="uppercase" display="inline-block">
              {newSelectedPlan}
            </Text>{" "}
            you will lose access to the following:
          </Text>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Flex
            p="4"
            w="100%"
            border="1px solid"
            borderColor="#C2C2C2"
            borderRadius="md"
            flexDir="column"
          >
            <Flex
              w="100%"
              justify="end"
              gap={6}
              borderBottom="1px solid #C2C2C2"
              pb="2"
            >
              <Flex w="50" />
              <Flex w="50%" justify="center" gap="2">
                <Heading
                  w="50%"
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="bold"
                  textTransform="uppercase"
                  color="#191D30"
                >
                  {newSelectedPlan}
                </Heading>
                <Heading
                  w="50%"
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="bold"
                  textTransform="uppercase"
                  color="#191D30"
                >
                  {currentUserPlan}
                  <Text
                    textTransform="capitalize"
                    fontSize="12px"
                    color="#8C8E97"
                    mt="2"
                    fontWeight="normal"
                  >
                    Your current plan
                  </Text>
                </Heading>
              </Flex>
            </Flex>
            <Flex w="100%">
              <Flex py={4} flexDir="column" gap={4} w="100%">
                {allPlans.map((plan, index) => {
                  return (
                    <Flex w="100%" flexDir="row" key={index}>
                      <Text
                        w="50%"
                        fontSize="11px"
                        fontWeight="normal"
                        color="#191D30"
                      >
                        {plan.compareName}
                      </Text>
                      <Flex w="50%" gap={4} justify="center">
                        <ComparismItem
                          planData={newSelectedPlanData}
                          compareName={plan.compareName}
                        />
                        <ComparismItem
                          planData={currentPlanData}
                          compareName={plan.compareName}
                        />
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter gap={4}>
          <Button
            bg="primary"
            color="white"
            onClick={props.onClose}
            fontWeight="normal"
            px="4"
          >
            No, I&rsquo;ll keep my current plan
          </Button>
          <Button
            border="1px solid"
            borderColor="primary"
            bg="white"
            color="primary"
            fontWeight="normal"
            px="4"
            onClick={() => {
              cancelSubscription();
            }}
            isLoading={isCanceling}
          >
            Yes, continue to downgrade
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { getTooltipText } from "../InfoTooltip";

const HeaderTab = ({
  icon,
  title,
  boxSize = 5,
  handler,
  toBeHighlighted,
  fontSize = "sm",
  color,
  iconMt,
  infoTooltipType,
  isCompact,
  disabled,
  disabledTooltip,
  ...rest
}) => {
  if (isCompact) {
    return (
      <Tooltip
        label={disabled ? disabledTooltip : title}
        placement="bottom"
        hasArrow
      >
        <Flex
          align="center"
          gap="3px"
          onClick={(e) => {
            if (disabled) {
              return;
            }
            if (!e.target.classList.value.includes("chakra-editable")) {
              // if the click is not on the editable text
              handler?.();
            }
          }}
          id={toBeHighlighted && "createSection"}
          cursor={disabled ? "not-allowed" : "pointer"}
          {...rest}
          userSelect="none"
        >
          <Icon
            as={icon}
            fill={disabled ? "gray.400" : color || "primary"}
            boxSize={boxSize}
            width="16px"
            mt={iconMt}
          />
        </Flex>
      </Tooltip>
    );
  }

  //Add ID if the title is Add Section
  return (
    <Tooltip
      label={disabled ? disabledTooltip : getTooltipText(infoTooltipType)}
      placement="bottom"
      hasArrow
    >
      <Flex
        align="center"
        gap="3px"
        onClick={(e) => {
          if (disabled) {
            return;
          }
          if (!e.target.classList.value.includes("chakra-editable")) {
            // if the click is not on the editable text
            handler?.();
          }
        }}
        id={toBeHighlighted && "createSection"}
        cursor={disabled ? "not-allowed" : "pointer"}
        userSelect="none"
        {...rest}
      >
        {icon && (
          <Icon
            as={icon}
            fill={disabled ? "gray.400" : color || "primary"}
            boxSize={boxSize}
            width="16px"
            mt={iconMt}
          />
        )}
        {typeof title === "string" ? (
          <Text
            fontSize={fontSize}
            color={disabled ? "gray.400" : color || "primary"}
            fontWeight="medium"
            noOfLines={1}
          >
            {title}
          </Text>
        ) : (
          <Box flex="1">{title}</Box>
        )}
        {/* {infoTooltipType ? <InfoTooltip type={infoTooltipType} /> : null} */}
      </Flex>
    </Tooltip>
  );
};

export default HeaderTab;

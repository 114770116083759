import { Avatar, Tooltip } from "@chakra-ui/react";

const SharedWithAvatar = ({ user, tooltipPrefix = "", size = "sm" }) => {
  return (
    <Tooltip
      label={
        tooltipPrefix + (user.displayName || user.email || "Anonymous user")
      }
    >
      <Avatar
        size={size}
        name={user.displayName || user.email}
        src={user.photoURL}
      />
    </Tooltip>
  );
};

export default SharedWithAvatar;

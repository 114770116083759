import { MoonIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import AccountIconComponent from "../Components/Icons/AccountIconComponent";
import ExtensionIconComponent from "../Components/Icons/ExtensionIconComponent";
import HelpIconComponent from "../Components/Icons/HelpIconComponent";
import LogoutIconComponent from "../Components/Icons/LogoutIconComponent";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { restartTour } from "../db/projectTour";

const customization = getCustomizationData();

export const MENU_TYPE = {
  ACCOUNT: "ACCOUNT",
  PRODUCT_TOUR: "PRODUCT_TOUR",
  MOSAIQ_EXTENSION: "MOSAIQ_EXTENSION",
  LOGOUT: "LOGOUT",
  DARK_MODE: "DARK_MODE",
};

export const MENU_DATA = [
  {
    id: 1,
    title: "Account",
    icon: AccountIconComponent,
    hasLink: true,
    type: MENU_TYPE.ACCOUNT,
    rest: {
      isExternal: false,
      to: "/user/profile",
    },
    router: ReactRouterLink,
  },
  {
    id: 2,
    title: "Help & Support",
    icon: HelpIconComponent,
    hasLink: true,
    rest: {
      isExternal: true,
      to: "/help",
    },
    router: ReactRouterLink,
    onlyForPowerUser: true,
  },
  {
    id: 3,
    title: "Product Tour",
    icon: HelpIconComponent,
    hasLink: false,
    handler: async (currentUser) => {
      await restartTour(currentUser.uid);
      // reload the page to restart the tour but take out the query parameter that signifies it is a shared link
      let urlWithoutQueryParams = window.location.origin;
      urlWithoutQueryParams = urlWithoutQueryParams.replace(/\/$/, "");
      window.history.replaceState(null, "", urlWithoutQueryParams);
      window.location.reload();
    },
    type: MENU_TYPE.PRODUCT_TOUR,
    hidden: !!customization.accountMenu.hideProductTour,
  },
  {
    id: 4,
    title: "Mosaiq Extension",
    icon: ExtensionIconComponent,
    hasLink: true,
    type: MENU_TYPE.MOSAIQ_EXTENSION,
    router: "a",
    rest: {
      isExternal: true,
      href: process.env.REACT_APP_CHROME_WEB_STORE_URL,
    },
    hidden: !!customization.accountMenu.hideChromeExtensionLink,
  },
  {
    id: 5,
    title: "Dark Mode",
    icon: MoonIcon,
    hasLink: false,
    handler: () => {
      const root = document.documentElement;
      if (!root.style.filter) {
        root.style.filter = "invert(90%)";
      } else {
        root.style.filter = "";
      }
    },
    type: MENU_TYPE.DARK_MODE,
    onlyForPowerUser: true,
  },
  {
    id: 6,
    title: "Logout",
    icon: LogoutIconComponent,
    hasLink: false,
    handler: (logout, history) => {
      logout();
      history.push("/");
    },
    type: MENU_TYPE.LOGOUT,
  },
];

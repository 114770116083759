import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

export const TitleVideoAndTextsSection = ({
  title,
  paragraphs,
  videoParams,
}) => {
  return (
    <Flex>
      <Flex direction={"column"}>
        <Flex pb="52px" w="100%" justify="center">
          <Heading textAlign="center" fontWeight="600" fontSize="16px">
            {title}
          </Heading>
        </Flex>
        <Flex flexDirection="column">
          <Flex pb="24px">
            <Flex h="100%" bg="#D9D9D9" borderRadius="4px" w="100%">
              <iframe
                style={{
                  width: "100%",
                  height: videoParams.height,
                  borderRadius: "4px",
                }}
                src={videoParams.src}
                title={videoParams.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Flex>
          </Flex>
          <Flex flexDir="column" w="100%" gap="14px">
            {paragraphs.map((paragraph, index) => (
              <Text
                fontWeight="400"
                fontSize="12px"
                key={index}
                color="#000000"
              >
                {paragraph}
              </Text>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const SecondaryTitleVideoAndTextsSection = ({
  title,
  paragraphs,
  videoParams,
}) => {
  return (
    <Flex>
      <Flex align="start" w="995px" gap="16px">
        <Flex pb="24px">
          <Flex h="240px" bg="#D9D9D9" borderRadius="4px" w="426px">
            <iframe
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
              src={videoParams.src}
              title={videoParams.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Flex>
        </Flex>

        <Flex w="553px" flexDir="column" gap="14px">
          <Heading fontWeight="500" fontSize="14px">
            {title}
          </Heading>
          {paragraphs.map((paragraph, index) => (
            <Text
              fontWeight="400"
              fontSize="12px"
              key={index}
              color="#000000"
              opacity="0.54"
            >
              {paragraph}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Divider, Flex, Image, Link } from "@chakra-ui/react";
import React from "react";
import {
  SecondaryTitleVideoAndTextsSection,
  TitleVideoAndTextsSection,
} from "../../Components/Help/TitleVideoAndTextsSection";
import { LAYOUT_PROPS } from "../../Components/Layout";
import { getCustomizationData } from "../../Components/Utils/customizationUtils";

const customization = getCustomizationData();

function Logo() {
  return (
    <Flex>
      <Flex
        gap={4}
        w="full"
        maxW={LAYOUT_PROPS.maxWidth}
        justify="space-between"
      >
        <Link to="/">
          <Image w="100px" minW="72px" src={customization.logo} />
        </Link>
      </Flex>
    </Flex>
  );
}

export const HelpAndSupport = () => {
  return (
    <Flex px="24px" py="37px" flexDir="column">
      <Logo />
      <Flex mt="60px" maxW="995px" m="0 auto" flexDir="column">
        <TitleVideoAndTextsSection
          title="General overview"
          paragraphs={[
            "Meet John, a finance consultant with a relentless drive for excellence. In an industry where time is of the essence and insights are everything, John faces the constant challenge of delivering high-quality results under tight deadlines. But with MosaiQ Lab by his side, John's workflow has undergone a remarkable transformation.",
            "Like many finance consultants, John grappled with the time-consuming task of analysing mountains of documents to extract valuable insights. With traditional methods, this process was not only laborious but also prone to human error, leaving John feeling overwhelmed and frustrated. [...]",
          ]}
          videoParams={{
            src: "https://www.youtube.com/embed/DIToNTcBmw8",
            title: "Introducing Apple Vision Pro",
            height: "560px",
          }}
        />
        <Divider borderColor="#000000" opacity="0.1" my="36px" />
        <Flex gap="24px" flexDir="column">
          <SecondaryTitleVideoAndTextsSection
            title="Consulting"
            paragraphs={[
              "Meet John, a finance consultant with a relentless drive for excellence. In an industry where time is of the essence and insights are everything, John faces the constant challenge of delivering high-quality results under tight deadlines. But with MosaiQ Lab by his side, John's workflow has undergone a remarkable transformation. [...]",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/DIToNTcBmw8",
              title: "Introducing Apple Vision Pro",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="Marketing"
            paragraphs={[
              "Meet John, a finance consultant with a relentless drive for excellence. In an industry where time is of the essence and insights are everything, John faces the constant challenge of delivering high-quality results under tight deadlines. But with MosaiQ Lab by his side, John's workflow has undergone a remarkable transformation. [...]",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/DIToNTcBmw8",
              title: "Introducing Apple Vision Pro",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="Banking"
            paragraphs={[
              "Meet John, a finance consultant with a relentless drive for excellence. In an industry where time is of the essence and insights are everything, John faces the constant challenge of delivering high-quality results under tight deadlines. But with MosaiQ Lab by his side, John's workflow has undergone a remarkable transformation. [...]",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/DIToNTcBmw8",
              title: "Introducing Apple Vision Pro",
              height: "240px",
              width: "426px",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

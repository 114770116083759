import styles from "./home.module.css";
import google from "../assets/google.svg";
import React, { useEffect } from "react";
import { auth, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const fetcher = async (url, method, token, payload) => {
  const requestHeaders = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", token);

  const res = await fetch(url, {
    body: payload ? JSON.stringify(payload) : undefined,
    headers: requestHeaders,
    method,
  });

  let resobj = res;
  try {
    resobj = await res.json();
  } catch (e) {
    throw new Error("Unexpected issue. Please try again.");
  }
  return resobj;
};

export async function callApi(url, method, payload) {
  let res = { data: "", error: "" };
  try {
    const token = await auth.currentUser?.getIdToken(true);
    // console.log("clientToken>>>", token);
    res = await fetcher(url, method, token, payload);
  } catch (e) {
    res.error = e.message;
    console.error("Error: ", e.message);
  }
  return res;
}

export const getCustomTokenFromServer = async () => {
  const customToken = (
    await callApi(process.env.REACT_APP_GET_JWT_FUNCTION_URL, "GET")
  ).data.customToken;
  return customToken;
};

export const setCustomTokenInQS = (customToken) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set("custom_token", customToken);

  window.location.search = urlParams.toString();
};

const SignInExtension = () => {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    console.log("Opening SignInExtension");

    // if custom_token is in the query string, do nothing
    if (window.location.search.includes("custom_token")) {
      return;
    }

    if (user) {
      console.log("user :>> ", user);
      getCustomTokenFromServer().then((customToken) => {
        console.log("customToken :>> ", customToken);
        setCustomTokenInQS(customToken);
      });
    }
  }, [user, loading]);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h1>Information Hub</h1>
        <div>
          {!user && (
            <button className={styles.btn} onClick={signInWithGoogle}>
              <img
                src={google}
                style={{ height: "1.8rem", paddingRight: "10px" }}
                alt="google icon"
              />
              Sign in with Google
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInExtension;

import {
  db,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "../firebase.js";

export const addArticleComment = ({projectId, docId, payload}) => {

  const ArticleCommentDocRef = doc(
    db,
    "projects/" + projectId + "/articles/" + docId
  );
  updateDoc(ArticleCommentDocRef, { comments: arrayUnion(payload) });
};

export const deleteArticleComment = ({projectId, docId, payload}) => {
  const ArticleCommentDocRef = doc(
    db,
    "projects/" + projectId + "/articles/" + docId
  );
  updateDoc(ArticleCommentDocRef, { comments: arrayRemove(payload) });
};

export const addSnippetComment = ({projectId, docId, payload}) => {
  const snippetCommentDocRef = doc(
    db,
    "projects/" + projectId + "/snippets/" + docId
  );
  updateDoc(snippetCommentDocRef, { comments: arrayUnion(payload) });
};

export const deleteSnippetComment = ({projectId, docId, payload}) => {
  const snippetCommentDocRef = doc(
    db,
    "projects/" + projectId + "/snippets/" + docId
  );
  updateDoc(snippetCommentDocRef, { comments: arrayRemove(payload) });
};

export const addProjectComment = ({projectId, payload}) => {
  const projectCommentDocRef = doc(db, "projects/" + projectId);
  updateDoc(projectCommentDocRef, { comments: arrayUnion(payload) });
};

export const deleteProjectComment = ({projectId, payload}) => {
  const projectCommentDocRef = doc(db, "projects/" + projectId);
  updateDoc(projectCommentDocRef, { comments: arrayRemove(payload) });
};


import { Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useNotification from "../../hooks/useNotification";
import { handleStripePayment } from "../../services/StripeSvc";
import { plansList } from "../UserPage/Plans/plansLists";
import { OnboardingCardPlan } from "./PlanCard";

export const OnboardingPaymentPlan = () => {
  const [paymentPlan, setPaymentPlan] = useState("month");
  const [isLoading, setIsLoading] = useState(false);
  const query = new URLSearchParams(useHistory().location.search);
  const paymentStatus = query.get("payment");
  const { notify } = useNotification();
  const handleSubscription = async () => {
    setIsLoading(true);
    try {
      const session = await handleStripePayment(
        paymentPlan,
        "welcome?step=6&payment=success",
        "welcome?step=5&payment=cancel"
      );
      window.location.assign(session.url);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notify({
        title: "Error",
        description: err.message,
        status: "error",
      });
    }
  };
  useEffect(() => {
    if (paymentStatus === "cancel") {
      notify({
        title: "Error",
        description: "Payment cancelled by user",
        status: "error",
      });
    }
  }, [paymentStatus]);
  return (
    <Flex flexDir="column" pos="relative" zIndex={120}>
      <Text mt="12">
        Before we get down to business. Here’s the option to turn your work flow
        from great to outstanding
      </Text>
      <Flex
        bg="#D9D9D9"
        w="fit-content"
        p="4px"
        m="3rem auto 0 auto"
        justify="center"
        borderRadius="md"
      >
        <Button
          bg={paymentPlan === "month" ? "primary" : "transparent"}
          color={paymentPlan === "month" ? "white" : "black"}
          w="50%"
          borderRadius="3px"
          p="1rem"
          fontWeight="medium"
          onClick={() => setPaymentPlan("month")}
        >
          Monthly
        </Button>
        <Button
          bg={paymentPlan === "year" ? "primary" : "transparent"}
          color={paymentPlan === "year" ? "white" : "black"}
          w="50%"
          borderRadius="3px"
          p="1rem"
          fontWeight="medium"
          onClick={() => setPaymentPlan("year")}
        >
          Yearly
        </Button>
      </Flex>
      <Flex
        w={{ "2xl": "70%", base: "80%" }}
        m="2rem auto 0 auto"
        align="center"
        gap={12}
      >
        <OnboardingCardPlan
          planName="Starter Plan"
          tagline="Current Plan"
          // price={price[0]}
          features={[
            "Access to up to 3 projects",
            "Limited access to AI",
            "Limited collaboration",
          ]}
          frequency={paymentPlan}
          {...plansList.free}
        />
        <OnboardingCardPlan
          planName="Premium plan"
          tagline="most popular"
          // price={price[1]}
          features={[
            "Unlimited projects ",
            "Unlimited access to AI",
            "Unlimited collaboration",
          ]}
          frequency={paymentPlan}
          handleSubscription={handleSubscription}
          isLoading={isLoading}
          {...plansList.premium}
        />
      </Flex>
    </Flex>
  );
};

import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { DesktopOnlyCompatibilityNotice } from "../Components/Home/DesktopOnlyCompatibilityNotice";
import { useIsMobileDevice } from "../Components/Utils/userDevice";
import { ArticleContextProvider } from "../Context/ArticleContext";
import { CustomAiModulesProvider } from "../Context/CustomAiModuleContext";
import { ProjectContextProvider } from "../Context/ProjectContext";
import { useAuth } from "./AuthProvider";

const ProtectedRoute = ({ children, ...rest }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const isMobile = useIsMobileDevice();

  if (!currentUser) {
    return (
      <Redirect to={{ pathname: "/", state: { from: location.pathname } }} />
    );
  }

  if (isMobile) {
    return <DesktopOnlyCompatibilityNotice userEmail={currentUser.email} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        React.cloneElement(
          <CustomAiModulesProvider>
            <ProjectContextProvider>
              <ArticleContextProvider>{children}</ArticleContextProvider>
            </ProjectContextProvider>
          </CustomAiModulesProvider>,
          props
        )
      }
    />
  );
};

export default ProtectedRoute;

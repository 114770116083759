import {
  Box,
  Button,
  Center,
  FormControl,
  Image,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ResizeTextarea from "react-textarea-autosize";
import { v4 as uuidv4 } from "uuid";
import { useContentContext } from "../../Context/ContentContext";
import { MAX_FILE_SIZE } from "../../data/constants";
import { createSnippet } from "../../db/snippet";
import { auth } from "../../firebase";
import { saveFile } from "../../services/firebaseStorageSrv";

const ImageUploadComponent = () => {
  const inputRef = useRef(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageDescription, setImageDescription] = useState("");
  const [submissionIsLoading, setSubmissionIsLoading] = useState(false);
  const handleClick = () => inputRef.current?.click();
  const { showToaster } = useContentContext();
  const ImageTitle = "Screenshot Added from Saved Insights";
  const authorId = auth?.currentUser?.uid;
  let { projectId } = useParams();

  const uploadImage = async (file) => {
    try {
      if (fileIsUploading) return;
      if (file.size > MAX_FILE_SIZE) {
        throw new Error("File size is too large");
      }
      setFileIsUploading(true);
      const downloadURL = await saveFile({
        file,
        pathName: "manualInsightImages",
      });
      setImageUrl(downloadURL);
      setFileIsUploading(false);
    } catch (err) {
      console.log(err);
      setFileIsUploading(false);
    }
  };

  const clearState = () => {
    setImageUrl(null);
    setImageDescription("");
  };

  const handleInsightSubmission = () => {
    if (!imageUrl || !imageDescription) {
      showToaster(
        "Image or description is empty",
        "Please add an image and description",
        "error"
      );
      return;
    }
    setSubmissionIsLoading(true);
    const payload = {
      projectId,
      snippetId: uuidv4(),
      snippetType: "image",
      title: ImageTitle,
      text: imageDescription,
      authorId,
      curated: true,
      imageUrl,
    };
    createSnippet(payload);
    showToaster("Insight added", "Your insight has been added", "success");
    setSubmissionIsLoading(false);
    clearState();
  };

  const handleTextChange = (text) => {
    setImageDescription(text);
  };

  const handlePaste = async (event) => {
    const clipboardItems = event.clipboardData.items;
    for (let i = 0; i < clipboardItems.length; i++) {
      let item = clipboardItems[i];
      if (item.type === "text/plain") {
        item.getAsString((text) => {
          handleTextChange(text);
        });
      } else {
        if (item.type.indexOf("image") === -1) continue;
        const blob = item.getAsFile();
        uploadImage(blob);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    uploadImage(file);
  };

  return (
    <Box>
      <Box
        mt="8"
        rounded="md"
        border="1px dotted"
        borderColor="rgba(255, 255, 255, 0.5)"
      >
        {imageUrl || fileIsUploading ? (
          <>
            {fileIsUploading ? (
              <Box py={8}>
                <Center>
                  <Spinner size="sm" />
                </Center>
                <Text
                  mt="2"
                  fontSize="xs"
                  textAlign="center"
                  fontWeight="black"
                >
                  Uploading file ...
                </Text>
              </Box>
            ) : (
              <Center style={{ height: "200px" }} py={2}>
                <Image src={imageUrl} maxW="100%" maxH="100%" />
              </Center>
            )}
          </>
        ) : (
          <>
            <input
              type="file"
              multiple={false}
              hidden
              accept="image/*"
              ref={inputRef}
              onChange={handleFileUpload}
            />
            <Box
              onClick={handleClick}
              py={16}
              fontSize="xs"
              textAlign="center"
              cursor="pointer"
            >
              <Text fontWeight="black">
                Upload or Drag & Drop Image/Screenshot
              </Text>
              <Text>(JPG, SVG, PNG, Screenshot)</Text>
            </Box>
          </>
        )}
      </Box>
      <Box mt={4} w="full">
        <FormControl bg="#282C3E" position="relative" borderRadius="5px">
          <Textarea
            borderRadius="10px"
            color="#ffffffdd"
            fontSize="13px"
            _placeholder={{
              color: "#ffffffdd",
              opacity: "0.87",
            }}
            value={imageDescription}
            onChange={(e) => handleTextChange(e.target.value)}
            border="1px solid"
            borderColor="rgba(255, 255, 255, 0.3)"
            placeholder="Add image description"
            as={ResizeTextarea}
            minH="unset"
            resize="none"
            minRows={4}
          />
        </FormControl>
        <Button
          onClick={handleInsightSubmission}
          isDisabled={!imageUrl || !imageDescription || submissionIsLoading}
          isLoading={submissionIsLoading}
          bg="#282C3E"
          rounded="full"
          w="full"
          mt={4}
        >
          <Text fontSize="sm">Add Image</Text>
        </Button>
      </Box>
    </Box>
  );
};

export default ImageUploadComponent;

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoCheckmarkOutline, IoLinkOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import { db, deleteDoc, doc, setDoc, updateDoc } from "../../../firebase";
import useNotification from "../../../hooks/useNotification";
import useSharedWith from "../../../hooks/useSharedWith";
import SharedWithAvatar from "../../Elements/SharedWithAvatar";

import { useAuth } from "../../../Auth/AuthProvider";
import { formatClipboardCopy } from "../../../Components/Utils/urlUtils";

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 *
 * Make request to process.env.REACT_APP_GET_USER_ID_FUNCTION_URL to get the uid of the user with the email.
 *
 * @param {*} email
 */
export const getUserId = async (email, raiseIfNotFound = false) => {
  const response = await fetch(process.env.REACT_APP_GET_USER_ID_FUNCTION_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, raiseIfNotFound }),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data.data.uid;
};

const createdUserSharedWithDoc = async ({
  uid,
  email,
  projectId,
  permission,
}) => {
  const userSharedWithDocRef = doc(
    db,
    "projects",
    projectId,
    "sharedWith",
    uid
  );
  await setDoc(userSharedWithDocRef, {
    email: email,
    permission: permission,
    uid: uid,
  });
};

const updateUserPermission = async ({ userId, projectId, permission }) => {
  const userSharedWithDocRef = doc(
    db,
    "projects",
    projectId,
    "sharedWith",
    userId
  );
  if (permission === "none") {
    await deleteDoc(userSharedWithDocRef);
  } else {
    await setDoc(
      userSharedWithDocRef,
      {
        permission: permission,
      },
      { merge: true }
    );
  }
};

export const SharingSettingModal = ({ ...props }) => {
  const { notify } = useNotification();
  const { currentUser } = useAuth();

  const { projectId } = useParams();
  const { isEditor } = useUserPermission();

  const sharedWith = useSharedWith(projectId);

  const setAuthorDataInProjectDoc = async () => {
    updateDoc(doc(db, "projects", projectId), {
      authorDisplayName: currentUser.displayName || currentUser.email,
      authorPhotoURL: currentUser.photoURL,
    });
  };

  /**
   * Check if the user is already shared with.
   *
   * @param {*} email
   */
  const checkCannotAdd = (email) => {
    if (!email) {
      notify({
        title: "Error",
        description: "Please enter an email",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (!isValidEmail(email)) {
      notify({
        title: "Error",
        description: "Please enter a valid email",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (currentUser.email === email) {
      notify({
        title: "Error",
        description: "You cannot invite yourself",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (sharedWith.find((user) => user.email === email)) {
      notify({
        title: "Error",
        description: "User already has access",
        status: "error",
        position: "top-right",
      });
      return true;
    } else {
      return false;
    }
  };

  /**
   * When user is invited, there should be an invocation of a function that retrieves the uid of the user with that email.
   */
  const InviteSection = () => {
    const [inviteEmail, setInviteEmail] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [permission, setPermission] = React.useState("canView");

    const { projectId } = useParams();
    const handleGrantAccess = async () => {
      if (checkCannotAdd(inviteEmail)) {
        setSubmitting(false);
        return;
      }
      // set displayName and photoURL in the project
      await setAuthorDataInProjectDoc();
      const uid = await getUserId(inviteEmail);
      // console.log("uid :>> ", uid);
      await createdUserSharedWithDoc({
        uid,
        email: inviteEmail,
        projectId,
        permission,
      });
      setInviteEmail("");
      navigator.clipboard.writeText(
        formatClipboardCopy({
          url: window.location.href,
          source: "sharing",
        })
      );
      // Display a toast to inform user to share the link with the user
      notify({
        title: "Success",
        description:
          "User has been granted access. Link to the project copied to clipboard. Share the link with the user.",
        status: "success",
        position: "top-right",
      });
      setSubmitting(false);
    };

    return (
      <Flex px="6" flexDir="column" position="relative">
        <FormControl
          bg="#282C3E"
          display="flex"
          flexDir="row"
          borderRadius="md"
          p="2"
          justifyContent="space-between"
        >
          <Text
            zIndex={12}
            position="absolute"
            bottom="0"
            color="red"
            fontSize="10px"
            fontWeight="regular"
            display={inviteEmail.length > 0 ? "block" : "none"}
          >
            {isValidEmail(inviteEmail) ? "" : "Invalid Email"}
          </Text>
          <Input
            color="white"
            _focusVisible={{ outline: "none" }}
            w="55%"
            border="none"
            placeholder="Email"
            p="0"
            pl="4"
            onChange={(e) => {
              setInviteEmail(e.target.value);
            }}
            value={inviteEmail}
          />
          <Menu w="fit-content" gutter="-10">
            <MenuButton
              p="0"
              fontWeight="500"
              fontSize="12px"
              as={Button}
              rightIcon={<ChevronDownIcon fontSize="15px" />}
            >
              {permission === "canView" ? "Can view" : "Can edit"}
            </MenuButton>
            <MenuList
              css={{
                minWidth: "fit-content !important",
              }}
              fontWeight="500"
              bg="#282C3E"
              border="2px solid #191D30"
              p="2"
              borderRadius="md"
            >
              <MenuItem
                bg="#282C3E"
                onClick={() => {
                  setPermission("canView");
                }}
                color="white"
                fontSize="12px"
                _hover={{ bg: "#191D30" }}
                borderRadius="md"
              >
                Can view
              </MenuItem>
              {/* <MenuItem
                bg="#282C3E"
                onClick={() => {
                  setPermission("canEdit");
                }}
                color="white"
                fontSize="12px"
                _hover={{ bg: "#191D30" }}
                borderRadius="md"
              >
                Can edit
              </MenuItem> */}
            </MenuList>
          </Menu>
          <Button
            isLoading={submitting}
            p="0.7rem"
            fontWeight="500"
            fontSize="12px"
            w="17%"
            bg="#191D30"
            onClick={async () => {
              setSubmitting(true);
              await handleGrantAccess();
            }}
            isDisabled={isValidEmail(inviteEmail) ? false : true}
          >
            Invite
            {/* Grant access */}
          </Button>
        </FormControl>
      </Flex>
    );
  };

  const PermissionMenu = ({ currentPermission, setPermission }) => {
    return (
      <Menu placement="bottom" gutter="0" w="30%">
        <MenuButton
          p="0"
          fontWeight="500"
          fontSize="12px"
          as={Button}
          rightIcon={<ChevronDownIcon fontSize="15px" />}
          h="fit-content"
          isDisabled={!isEditor()}
        >
          {currentPermission === "canView" ? "Can view" : "Can edit"}
        </MenuButton>
        <MenuList
          bg="#282C3E"
          border="none"
          p="2"
          borderRadius="md"
          as={Flex}
          w="12%"
          flexDir="column"
          css={{
            minWidth: "fit-content !important",
          }}
          gap={2}
        >
          <MenuItem
            bg="#282C3E"
            onClick={() => setPermission("canView")}
            color="white"
            fontSize="12px"
            _hover={{ bg: "#191D30" }}
            borderRadius="md"
          >
            Can view
          </MenuItem>
          {/* <MenuItem
            bg="#282C3E"
            onClick={() => setPermission("canEdit")}
            color="white"
            fontSize="12px"
            _hover={{ bg: "#191D30" }}
            borderRadius="md"
          >
            Can edit
          </MenuItem> */}
          <MenuItem
            bg="#282C3E"
            onClick={() => {
              setPermission("none");
            }}
            color="#D11A2A"
            fontSize="12px"
            _hover={{ bg: "#191D30" }}
            borderRadius="md"
          >
            Remove
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const IndividualUser = ({ user, projectId }) => {
    return (
      <Flex mt={2} justify="space-between" align="center" gap={1}>
        <Flex align="center" gap="2">
          <SharedWithAvatar user={user} />
          <Text fontSize="11px" color="white">
            {user.displayName || user.email}{" "}
            {user.permission === "owner" ? " (you)" : ""}
          </Text>
        </Flex>
        {/* if owner, don't show the menu */}
        {user.permission === "owner" ? (
          <Text color="white" fontSize="12px">
            Owner
          </Text>
        ) : (
          <PermissionMenu
            userId={user.uid}
            projectId={projectId}
            currentPermission={user.permission}
            setPermission={(permission) => {
              updateUserPermission({ userId: user.uid, projectId, permission });
            }}
          />
        )}
      </Flex>
    );
  };

  const UserList = ({ userList, projectId }) => {
    return (
      <Flex px="6" mt="2rem" flexDir="column">
        <Text color="#8C8E98" fontSize="11px">
          People with access
        </Text>
        <Flex flexDir="column">
          {/* show current user (owner) first. In the future, when we implement editors, this would need to be changed in some way, 
          because current user is no longer the owner.*/}
          <IndividualUser
            key="owner"
            user={{
              uid: currentUser.uid,
              displayName: currentUser.displayName,
              photoURL: currentUser.photoURL,
              permission: "owner",
            }}
            projectId={projectId}
          />
          {userList.map((user, index) => (
            <IndividualUser key={index} user={user} projectId={projectId} />
          ))}
        </Flex>
      </Flex>
    );
  };

  const CopyLinkButton = () => {
    const [clicked, setClicked] = useState(false);
    const [buttonText, setButtonText] = useState("Copy link");
    const [icon, setIcon] = useState(<IoLinkOutline fontSize="20px" />);

    const handleClick = () => {
      setClicked(true);
      setButtonText("Copied!");
      setIcon(<IoCheckmarkOutline fontSize="20px" />);
      // copy current URL to clipboard
      navigator.clipboard.writeText(
        formatClipboardCopy({
          url: window.location.href,
          source: "sharing",
        })
      );

      setTimeout(() => {
        setClicked(false);
        setButtonText("Copy link");
        setIcon(<IoLinkOutline fontSize="20px" />);
      }, 3000); // reset after 3 seconds
    };

    const buttonStyle = {
      transform: clicked ? "scale(0.95)" : "scale(1)",
      transition: "transform 0.3s ease",
    };

    return (
      <Button
        p="0"
        w="fit-content"
        fontSize="12px"
        color="#37BDDB"
        iconSpacing="2"
        my="4"
        leftIcon={icon}
        fontWeight="normal"
        style={buttonStyle}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Modal {...props} p="0">
      <ModalOverlay w="full" h="full" />
      <ModalContent
        bg="#191D30"
        pb="0"
        pt="4"
        position="fixed"
        top="30%"
        left="35%"
      >
        <ModalCloseButton
          boxSize="24px"
          bg="#282C3E"
          color="white"
          p="0"
          fontSize="9px"
        />
        <ModalHeader px="6" color="white">
          {isEditor() ? "Invite" : "Sharing"}
        </ModalHeader>
        {isEditor() ? <InviteSection /> : null}
        <UserList userList={sharedWith} projectId={projectId} />
        <Flex px="6" mt="10" flexDir="column">
          <Text color="#8C8E98" fontSize="11px">
            General access
          </Text>
          <Flex flexDir="row" justify="space-between" align="center">
            <Menu w="fit-content" placement="bottom" gutter="0">
              <MenuButton
                mt="2"
                px="2"
                fontWeight="normal"
                fontSize="12px"
                as={Button}
                rightIcon={<ChevronDownIcon fontSize="15px" />}
                border="1px solid #8C8E97"
                isDisabled={!isEditor()}
              >
                Only people invited to this project
              </MenuButton>
              <MenuList
                h="fit-content"
                bg="#282C3E"
                border="none"
                p="2"
                borderRadius="md"
              >
                <MenuItem
                  bg="#282C3E"
                  onClick={() => {
                    // setPermission("canView");
                  }}
                  color="white"
                  fontSize="12px"
                  _hover={{ bg: "#191D30" }}
                  borderRadius="md"
                >
                  Only people invited to this project
                </MenuItem>
                {/* <MenuItem
                  bg="#282C3E"
                  onClick={() => {
                    // setPermission("canEdit");
                  }}
                  color="white"
                  fontSize="12px"
                  _hover={{ bg: "#191D30" }}
                  borderRadius="md"
                >
                  Anyone with the link
                </MenuItem> */}
              </MenuList>
            </Menu>
            <Text color="white" fontSize="12px" fontWeight="regular">
              Restricted
            </Text>
          </Flex>
        </Flex>
        <Divider mt="2" orientation="horizontal" borderColor="#282C3E" />
        <Flex px="6">
          <CopyLinkButton />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SharingSettingModal;

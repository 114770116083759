import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { getAllUserProjects } from "../db/article";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//Get the project context
const ProjectContext = React.createContext();

export const ProjectContextProvider = ({ children }) => {
  const { currentUser, userDoc } = useAuth();
  let { projectId: currentProjectId } = useParams();
  const [allProjects, setAllProjects] = useState([]);
  // const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProjectData, setCurrentProjectData] = useState(null);
  const [sortedByTimeProjects, setSortedByTimeProjects] = useState([]);
  const [activeProjectsWithoutArchived, setactiveProjectsWithoutArchived] =
    useState([]);
  const [activeAndSortedProjects, setActiveAndSortedProjects] = useState([]);
  const [sortedAndArchivedProjects, setSortedAndArchivedProjects] = useState(
    []
  );
  const [
    activeProjectsWithoutCurrentProject,
    setActiveProjectsWithoutCurrentProject,
  ] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const userId = currentUser.uid;
    let unsubscribeFromProjects;
    unsubscribeFromProjects = getAllUserProjects(userId, (projects) => {
      setAllProjects(projects);
    });

    return () => {
      unsubscribeFromProjects;
    };
  }, [currentUser]);

  useEffect(() => {
    if (currentProjectId) {
      const project = allProjects.find((p) => p.uid === currentProjectId);
      setCurrentProjectData(project);
    } else {
      setCurrentProjectData(null);
    }
  }, [currentProjectId, allProjects]);

  const archivedProjects = userDoc?.archivedProjects;
  useEffect(() => {
    const sortedArticleByTime = allProjects.sort((a, b) => {
      // use modifiedAt if it exists, otherwise createdAt
      const aTime = a.modifiedAt || a.createdAt;
      const bTime = b.modifiedAt || b.createdAt;
      return bTime - aTime;
    });

    const activeProjects = sortedArticleByTime.filter(
      (project) => !archivedProjects?.includes(project.uid)
    );

    const sortedAndArchivedProjects = sortedArticleByTime.filter((project) =>
      archivedProjects?.includes(project.uid)
    );

    const activeProjectWithoutCurrentProject = activeProjects.filter(
      (project) => {
        console.log(project.uid === currentProjectId, "<==project");

        return project.uid !== currentProjectId;
      }
    );

    setActiveAndSortedProjects(activeProjects, sortedArticleByTime);
    setactiveProjectsWithoutArchived(activeProjects);
    setSortedByTimeProjects(sortedArticleByTime);
    setSortedAndArchivedProjects(sortedAndArchivedProjects);
    setActiveProjectsWithoutCurrentProject(activeProjectWithoutCurrentProject);
  }, [
    allProjects,
    archivedProjects,
    setActiveAndSortedProjects,
    setactiveProjectsWithoutArchived,
    setSortedByTimeProjects,
    setSortedAndArchivedProjects,
    setActiveProjectsWithoutCurrentProject,
    currentProjectId,
  ]);

  return (
    <ProjectContext.Provider
      value={{
        allProjects,
        currentProjectData,
        currentProjectId,
        sortedByTimeProjects,
        // setCurrentProjectId,
        activeProjectsWithoutArchived,
        activeAndSortedProjects,
        sortedAndArchivedProjects,
        activeProjectsWithoutCurrentProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("context must be used within a ProjectProvider");
  }
  return context;
};
export default ProjectContext;

/**
 * Creates a map of article IDs to reference numbers based on the provided reference data and message content.
 *
 * @param {Array} referenceData - The reference data containing information about each chunk.
 * @param {string} messageContent - The message content to search for list prefixes to know which articles are cited.
 * @returns {Object} - The map of article IDs to reference numbers.
 */
export const makeArticleIdToReferenceNumberMap = (
  referenceData,
  messageContent
) => {
  if (!referenceData) {
    return {};
  }
  let articleCounter = 1;
  const articleIdToReferenceNumberMap = {};
  // for each chunk
  // console.log("referenceData: ", referenceData);
  referenceData.forEach((chunkData) => {
    // if the messageContent includes the listPrefix, which is [1](#1) for example, then
    if (messageContent.includes(chunkData.listPrefix)) {
      // if the articleId is not in the map yet, add it with increasing counter
      if (!articleIdToReferenceNumberMap[chunkData.articleId]) {
        articleIdToReferenceNumberMap[chunkData.articleId] = articleCounter;
        articleCounter++;
      }
    }
  });
  return articleIdToReferenceNumberMap;
};

export const makeChunkToArticleReferenceNumberMap = (
  referenceData,
  articleIdToReferenceNumberMap
) => {
  if (!referenceData) {
    return {};
  }
  const chunkToArticleReferenceNumberMap = {};
  // for each chunk
  referenceData.forEach((chunkData) => {
    // if the articleId is in the map
    if (articleIdToReferenceNumberMap[chunkData.articleId]) {
      // add the chunkId to the map with the value of the article reference number (e.g. 1, 2, etc.)
      chunkToArticleReferenceNumberMap[chunkData.referenceId] =
        articleIdToReferenceNumberMap[chunkData.articleId];
    }
  });
  return chunkToArticleReferenceNumberMap;
};

export const replaceReferenceCiteWithMatchingReferenceLink = ({
  msg,
  chunkToArticleReferenceNumberMap,
  articleIdToReferenceNumberMap,
}) => {
  if (!msg.content) {
    return "";
  }
  let words = msg.content.split(" ");
  let updateTextsWithReferenceLink = "";
  let citedRefText = "";
  words.map((word) => {
    // replace #1, #2 etc with the url
    // let updatedHashWithURL = word.replace(/#\d+/g, (match) => {
    //   return findReferenceData(match);
    // });

    // remove (#1) after [1] from the text
    const matchPattern = /\[(\d+)\]\(#(\d+)\)/g;
    let updatedHashWithURL = word.replace(matchPattern, (_match, p1) => {
      return `[${chunkToArticleReferenceNumberMap[`#${p1}`]}]`;
    });
    updateTextsWithReferenceLink =
      updateTextsWithReferenceLink + updatedHashWithURL + " ";
  });
  Object.entries(articleIdToReferenceNumberMap).forEach((articleReference) => {
    const articleId = articleReference[0];
    const referenceNumber = articleReference[1];
    const articleData = msg.referenceData.find(
      (r) => r.articleId === articleId
    );
    citedRefText = `${citedRefText}[${referenceNumber}] [${articleData?.articleTitle}](${articleData?.articleUrl})\n`;
  });
  console.log(Object.entries(articleIdToReferenceNumberMap));
  if (Object.entries(articleIdToReferenceNumberMap).length > 0) {
    updateTextsWithReferenceLink = `${updateTextsWithReferenceLink}\n\nCitations:\n${citedRefText}`;
  }
  navigator.clipboard.writeText(updateTextsWithReferenceLink);
};

import { Button, Flex, Heading, Icon, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
// import { useAuth } from "../../../Auth/AuthProvider";
import { formatDateStripe } from "../../Utils/dateutils";
import { subscriptionIsCancelled } from "../../Utils/stripe";

function YourCurrentPlan({
  isLoading,
  title,
  product,
  cancelOnOpen,
  userCurrentSubscription,
}) {
  const isTrialing = userCurrentSubscription?.status === "trialing";
  return (
    <Flex gap={2} flexDir="column">
      <Skeleton isLoaded={!isLoading}>
        <Heading fontSize="13px" fontWeight="medium">
          Your current plan
        </Heading>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        {product?.unit_amount ? (
          <Text color="#757575" fontSize="12px">
            {title}
            {isTrialing ? " (trial)" : null}
          </Text>
        ) : (
          <Text color="#757575" fontSize="12px">
            Starter
          </Text>
        )}
      </Skeleton>
      <Text color="#757575" fontSize="12px">
        <Text>
          <Skeleton isLoaded={!isLoading}>
            {userCurrentSubscription && (
              <Text color="primary" fontSize="12px">
                <Text>
                  {subscriptionIsCancelled(userCurrentSubscription)
                    ? `Your subscription will end on `
                    : `Next billing due on `}
                  {formatDateStripe(
                    userCurrentSubscription?.current_period_end
                  )}
                  .
                </Text>
              </Text>
            )}
          </Skeleton>
        </Text>
      </Text>
      <Skeleton mt={4} isLoaded={!isLoading}>
        {subscriptionIsCancelled(userCurrentSubscription) || (
          <Button
            w="fit-content"
            fontWeight="normal"
            mt="4"
            textDecor="underline"
            p="0"
            h="0"
            color="primary"
            fontSize="10px"
            onClick={cancelOnOpen}
          >
            {isTrialing ? "Cancel trial" : "Cancel subscription"}
          </Button>
        )}
      </Skeleton>
    </Flex>
  );
}

export const UserCurrentPlanCard = ({
  icon,
  isLoading,
  title,
  product,
  cancelOnOpen,
  userCurrentSubscription,
}) => {
  console.log("userCurrentSubscription", userCurrentSubscription);
  return (
    <Flex flexDir="column" justify="space-between">
      <Flex flexDir="column" gap={24}>
        <Flex gap={2}>
          <Skeleton isLoaded={!isLoading} h="fit-content">
            {
              <Icon
                as={icon}
                color="primary"
                fontSize="32px"
                fontWeight="bolder"
              />
            }
          </Skeleton>
          <YourCurrentPlan
            {...{
              isLoading,
              title,
              product,
              cancelOnOpen,
              userCurrentSubscription,
            }}
          />
        </Flex>
      </Flex>

      <Flex flexDir="column" mt={1}></Flex>
      {/* {isPremium ? null : (
        <Skeleton mt={4} isLoaded={!isLoading}>
          <Button
            border="1.5px solid #c2c2c2"
            fontSize="12px"
            bg="#ffffff"
            color="primary"
          >
            Upgrade
          </Button>
        </Skeleton>
      )} */}
    </Flex>
  );
};

import { Button, Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { PLAN_INTERVALS } from "../../data/SubscriptionData";
import useNotification from "../../hooks/useNotification";
import useSubscription from "../../hooks/useSubscription";
import { UserCurrentPlan } from "../UserPage/Plans/NewUserCurrentPlan";
import { PlanCard } from "../UserPage/Plans/PlanCard";
// import { useLimitations } from "../../hooks/useLimitations";
import useProducts from "../../hooks/useProducts";
import { plansList } from "../UserPage/Plans/plansLists";
// import { handleStripeAiCreditsPayment } from "../../services/StripeSvc";

export const Plans = () => {
  const { isPremium } = useAuth();
  // const {
  //   aiCreditsPeriodic,
  //   aiCreditsExtra,
  //   aiCreditsTotal,
  //   assistantDisabled,
  // } = useLimitations();
  const history = useHistory();
  const query = new URLSearchParams(useHistory().location.search);
  const [currentPlanDuration, setCurrentPlanDuration] = useState(
    query.get("type") || PLAN_INTERVALS.MONTHLY
  );
  const paymentStatus = query.get("payment");
  const { notify } = useNotification();
  const { userCurrentSubscription } = useSubscription();

  console.log("userCurrentSubscription", userCurrentSubscription);

  // const isCancelled = userCurrentSubscription?.cancel_at_period_end;
  // const nextBillingDate = userCurrentSubscription?.current_period_end;

  const { products } = useProducts();
  console.log("products", products);

  // const [isSubsribing, setIsSubscribing] = useState(false);

  // const handlePayment = async (nCredits) => {
  //   if (isSubsribing) return;
  //   setIsSubscribing(true);
  //   // switch (id) {
  //   //   case "premium":
  //   const session = await handleStripeAiCreditsPayment(
  //     nCredits,
  //     "user/profile/plans?payment=success",
  //     "user/profile/plans?payment=cancel"
  //   );
  //   window.location.assign(session.url);
  //   setIsSubscribing(false);
  // };

  useEffect(() => {
    if (paymentStatus === "success") {
      notify({
        title: "Success",
        description: "Payment successful",
        status: "success",
      });
      history.replace("/user/profile/plans");
    }

    if (paymentStatus === "cancel") {
      notify({
        title: "Error",
        description: "Payment cancelled by user",
        status: "error",
      });

      history.replace("/user/profile/plans");
    }
  }, [paymentStatus]);

  return (
    <Flex flexDir="column" w="100%">
      <Flex mb={6}>
        <Heading fontSize="24px">Plans</Heading>
      </Flex>
      <Flex flexDir="column" gap={6}>
        <UserCurrentPlan
          currentPlan={isPremium ? "premium" : "free"}
          subscriptionId={userCurrentSubscription?.id}
          subscriptionEndDate={userCurrentSubscription?.current_period_end}
        />
        <Flex bg="#D9D9D9" w="fit-content" p="4px" borderRadius="md">
          <Button
            p="0.5rem 1rem"
            bg={
              currentPlanDuration === PLAN_INTERVALS.MONTHLY
                ? "primary"
                : "transparent"
            }
            h="fit-content"
            color={
              currentPlanDuration === PLAN_INTERVALS.MONTHLY
                ? "white"
                : "primary"
            }
            fontWeight="normal"
            fontSize="14px"
            onClick={() => {
              setCurrentPlanDuration(PLAN_INTERVALS.MONTHLY);
              //Update url with currentPlanDuration
              history.push(`?type=month`);
            }}
          >
            Monthly
          </Button>
          <Button
            p="0.5rem 1rem"
            bg={
              currentPlanDuration === PLAN_INTERVALS.YEARLY
                ? "primary"
                : "transparent"
            }
            h="fit-content"
            color={
              currentPlanDuration === PLAN_INTERVALS.YEARLY
                ? "white"
                : "primary"
            }
            fontWeight="normal"
            fontSize="14px"
            onClick={() => {
              setCurrentPlanDuration(PLAN_INTERVALS.YEARLY);
              history.push(`?type=year`);
            }}
          >
            Yearly
          </Button>
        </Flex>
        <Flex
          gap={2}
          w={{ base: "90%", "2xl": "75%", lg: "80%" }}
          mt={12}
          align="center"
        >
          <PlanCard
            {...plansList.free}
            currentPlan={isPremium ? "premium" : "free"}
            subscriptionId={userCurrentSubscription?.id}
          />
          <PlanCard
            {...plansList.premium}
            currentPlan={isPremium ? "premium" : "free"}
            subscriptionId={userCurrentSubscription?.id}
          />
          <PlanCard
            {...plansList.enterprise}
            currentPlan={isPremium ? "premium" : "free"}
            subscriptionId={userCurrentSubscription?.id}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../../Auth/AuthProvider";
import { useLimitations } from "../../../hooks/useLimitations";
import useStripeIntegration from "../../../hooks/useSubscription";
import { AICreditsUsageOverview } from "./AICreditUsageOverview";
import { CancelPlanModal } from "./CancelPlanModal";
import { DowngradePlanModal } from "./DowngradePlanModal";
import { ExtraAICreditOverview } from "./ExtraAICreditOverview";
import { UserCurrentPlanCard } from "./UserCurrentPlanCard";
import { plansList } from "./plansLists";
import { MONTHLY_AI_CREDITS } from "../../../data/constants";

export const UserCurrentPlan = ({ currentPlan, subscriptionId }) => {
  console.log(currentPlan, "current planss");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const matchingPlan = plansList[currentPlan];
  const { productSubscribedToByUser, isLoading, userCurrentSubscription } =
    useStripeIntegration();
  const { aiCreditsPeriodic, aiCreditsExtra } = useLimitations();
  const { isPremium, userDoc } = useAuth();

  return (
    <Flex
      bg="white"
      pb={12}
      pt={6}
      px={{ base: 4 }}
      w={{ base: "100%", "2xl": "100%" }}
      overflowX="scroll"
      borderRadius="8"
    >
      <Flex
        gap={4}
        bg="white"
        flexDir="column"
        borderRadius="md"
        minW="1000px"
        w={{ base: "100%", "2xl": "100%" }}
      >
        <Flex mt="4" gap={6} justify="space-between">
          <Flex w="25%">
            <UserCurrentPlanCard
              icon={matchingPlan.icon}
              title={matchingPlan.title}
              isLoading={isLoading}
              product={productSubscribedToByUser}
              userCurrentSubscription={userCurrentSubscription}
              cancelOnOpen={onOpen}
            />
          </Flex>
          <Flex w="35%">
            <AICreditsUsageOverview
              maxCredits={MONTHLY_AI_CREDITS}
              remainingCredits={aiCreditsPeriodic}
              isLoading={isLoading}
              refreshCreditsTs={userDoc.refreshCreditsTs}
              userCurrentSubscription={userCurrentSubscription}
            />
          </Flex>
          <Flex w="30%">
            <ExtraAICreditOverview
              extraAICredit={aiCreditsExtra}
              isLoading={isLoading}
              canBuy={isPremium}
            />
          </Flex>
        </Flex>
        <CancelPlanModal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          planName={currentPlan}
          subscriptionId={subscriptionId}
        />
        <DowngradePlanModal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          planName={currentPlan}
          subscriptionId={subscriptionId}
          userCurrentSubscription={userCurrentSubscription}
        />
      </Flex>
    </Flex>
  );
};

import { Flex, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import { BsHeartbreakFill } from "react-icons/bs";
import { Link as ReactRouterLink } from "react-router-dom";

export const NotFound = () => {
  return (
    <Flex flexDir="column" h="95vh" align="center" justify="center">
      <BsHeartbreakFill size="100px" color="#191D30" />
      <Heading>You are lost</Heading>
      <Text>
        Let&rsquo;s take you back{" "}
        <Link textDecor="underline" to="/user" as={ReactRouterLink}>
          home
        </Link>{" "}
        - safely
      </Text>
    </Flex>
  );
};

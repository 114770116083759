import React, { useEffect, useState } from "react";
// import Joyride, { STATUS, EVENTS } from "react-joyride";
import Joyride, { EVENTS, STATUS } from "mosaiqjoyride";

import { useAuth } from "../../Auth/AuthProvider";
import {
  createProjectTourField,
  getProjectTourDocRef,
  tourTypeDone,
} from "../../db/projectTour";
// import { getCustomizationData } from "../Utils/customizationUtils";

// const clientCustomisation = getCustomizationData();

export const PROJECT_TOUR_ALL_STEPS_DONE = {
  projectPage: true,
  projectList: true,
  insightPage: true,
  curatedSection: true,
  aiChatTour: true,
};

const setAllToursDone = async (userId) => {
  try {
    const res = await getProjectTourDocRef(userId);

    if (res.data === false) {
      console.log("There was an error getting the project tour doc ref");
    } else {
      createProjectTourField(userId, PROJECT_TOUR_ALL_STEPS_DONE);
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const TourComponent = ({ steps, tourType, moveToLeft }) => {
  const [runTour, setRunTour] = useState(false);
  const { currentUser, userDoc } = useAuth();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    // userDoc contains the projectTour field, which is an object
    // with a key for each tour type, and true if tour is done
    setRunTour(!userDoc.projectTour?.[tourType]);
    // When tour is on triggered on the project page, set it as done also
    // for the home page (projectList). This is a workaround, because as of 2023-09-14
    // home page tour is only 1 step which leads directly to project page,
    // and it's not possible to set it as done from the project page.
    //
    // If it will be possible to set the tour as done from the project page,
    // this workaround can be removed.
    if (tourType === "projectPage") {
      setTourDone("projectList");
    }
  }, [tourType]);

  const setTourDone = (currentTourType) => {
    getProjectTourDocRef(currentUser.uid).then((res) => {
      console.log(`getProjectTourDocRef response:`, res);
      if (res.data === false) {
        console.log("There was an error getting the project tour doc ref");
      } else if (res.message === "noDoc") {
        const projectTourProgress = {
          projectPage: false,
          projectList: false,
          insightPage: false,
          curatedSection: false,
          aiChatTour: false,
        };
        projectTourProgress[currentTourType] = true;
        createProjectTourField(currentUser.uid, projectTourProgress);
      } else if (res.message === "docFound") {
        tourTypeDone(currentUser.uid, currentTourType);
      }
    });
  };

  const handleNextStep = (e) => {
    //Trigger next step if the id is #openOrganizerButton
    if (e?.target?.id === "openOrganizerButton") {
      //Get the next button and click it with aria-label="Next"
      const nextButton = document.querySelector(
        ".react-joyride__tooltip button[aria-label='Next']"
      );
      nextButton?.click();
    }
  };

  //Listen for click on the whole body and trigger the next step if the id is #openOrganizerButton
  useEffect(() => {
    document.body.addEventListener("click", handleNextStep);
    return () => {
      document.body.removeEventListener("click", handleNextStep);
    };
  }, []);

  // if (clientCustomisation.disableProductTour) {
  //   return null;
  // }

  return (
    runTour && (
      <Joyride
        definedCurrentStep={steps[currentStepIndex]?.heading}
        totalSteps={8}
        spotlightClicks={steps[currentStepIndex]?.spotlight}
        disableScrolling
        disableScrollParentFix
        disableCloseOnEsc
        continuous
        hideCloseButton
        run={true}
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            width: "649px",
            overlayColor: "#364C53",
            //Check if this current step has the moveToLeft prop and if so, set moveToLeft to true
            moveToLeft: moveToLeft ? true : false,
          },
        }}
        callback={({ status, type, action }) => {
          handleNextStep();
          if (action === "next" || action === "close") {
            setCurrentStepIndex((prev) => prev + 1);
          } else if (action === "prev") {
            // Handle the "Back" action
            setCurrentStepIndex((prev) => prev - 1);
          }
          if (status === STATUS.SKIPPED) {
            setAllToursDone(currentUser.uid);
          }

          if ([STATUS.FINISHED].includes(status)) {
            setTourDone(tourType);
          }
          if (status === STATUS.RUNNING && type === EVENTS.STEP_AFTER) {
            setTourDone(tourType);
          }
        }}
      />
    )
  );
};

import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

export const LoadingAuth = ({ h = "90vh", size = "xl" }) => {
  return (
    <Flex h={h} align="center" justify="center">
      <Spinner
        thickness="3px"
        speed="0.65s"
        emptyColor="#191D30"
        color="grey"
        size={size}
      />
    </Flex>
  );
};

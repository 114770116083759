import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { getProjectData } from "../db/project";
import { auth, db, doc, getDoc } from "../firebase";

export const UserPermissions = {
  AUTHOR: "author",
  CAN_EDIT: "canEdit",
  CAN_VIEW: "canView",
  NONE: "none",
  LOADING: "loading",
};

const UserPermissionContext = createContext();

const getUserPermission = async (projectId, userId) => {
  let projectData;
  try {
    projectData = await getProjectData(projectId);
  } catch (e) {
    return UserPermissions.NONE;
  }

  if (projectData.author === userId) {
    return UserPermissions.AUTHOR;
  }

  const userPermissionRef = doc(
    db,
    "projects",
    projectId,
    "sharedWith",
    userId
  );
  const userPermissionDoc = await getDoc(userPermissionRef);
  if (userPermissionDoc.exists()) {
    return userPermissionDoc.data().permission;
  } else {
    return UserPermissions.NONE;
  }
};

export const UserPermissionProvider = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [userPermission, setUserPermission] = useState(UserPermissions.LOADING);
  const { projectId } = useParams();

  const fetchUserRole = async (projectId) => {
    const userPermission = await getUserPermission(projectId, user?.uid);
    setUserPermission(userPermission);
  };

  useEffect(() => {
    console.log(user, projectId);
    if (loading) return;
    if (projectId) {
      fetchUserRole(projectId);
    } else {
      setUserPermission(UserPermissions.NONE); // Reset to NONE if no projectId is present
    }
  }, [user, projectId]);

  // Existing utility functions
  const isReader = () => userPermission === UserPermissions.CAN_VIEW;
  const isAuthor = () => userPermission === UserPermissions.AUTHOR;
  const isEditor = () =>
    isAuthor() || userPermission === UserPermissions.CAN_EDIT;

  return (
    <UserPermissionContext.Provider
      value={{
        userPermission,
        setUserPermission,
        isReader,
        isEditor,
        isAuthor,
      }}
    >
      {children}
    </UserPermissionContext.Provider>
  );
};

export const useUserPermission = () => {
  const context = useContext(UserPermissionContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserPermissionProvider");
  }
  return context;
};

export default UserPermissionContext;

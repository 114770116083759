import { Flex, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineBell,
  AiOutlineCreditCard,
  AiOutlineUser,
} from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { BsCashStack } from "react-icons/bs";
import { TbGridDots } from "react-icons/tb";
import { Link as ReactRouterLink } from "react-router-dom";
import { UserPageMenuBttn } from "../UserPageMenuBttn";

export const MainProfileNav = () => {
  return (
    <Flex
      h="100vh"
      top="0%"
      gap={12}
      pl="30px"
      pr="12px"
      py={8}
      bg="white"
      flexDir="column"
      position="relative"
    >
      <Flex align="center" gap={4}>
        <Link as={ReactRouterLink} to="/user">
          <AiOutlineArrowLeft fontSize="18px" />
        </Link>
        <Heading fontSize="16px" fontWeight="600">
          Account
        </Heading>
      </Flex>
      <Flex flexDir="column" gap="2">
        <UserPageMenuBttn
          buttonName="Personal Info"
          buttonId="profile"
          BttnIcon={AiOutlineUser}
        />
        <UserPageMenuBttn
          buttonName="Plans"
          buttonId="plans"
          BttnIcon={AiOutlineCreditCard}
          isComingSoon={false}
        />
        <UserPageMenuBttn
          buttonName="Notification & Privacy"
          buttonId="notification"
          BttnIcon={AiOutlineBell}
          isComingSoon={true}
        />
        <UserPageMenuBttn
          buttonName="Integrations"
          buttonId="integrations"
          BttnIcon={TbGridDots}
          isComingSoon={true}
        />
        <UserPageMenuBttn
          buttonName="Activity log"
          buttonId="activityLog"
          BttnIcon={BiHistory}
          isComingSoon={true}
        />
        <UserPageMenuBttn
          buttonName="Billing"
          buttonId="billing"
          BttnIcon={BsCashStack}
          isComingSoon={true}
        />
      </Flex>

      <Flex
        position="fixed"
        bottom="0"
        left="0"
        w="100%"
        px="30px"
        pb={5}
        flexDir="column"
      >
        <Text fontSize="2xs" color="gray.500">
          v{process.env.REACT_APP_VERSION}
        </Text>
      </Flex>
    </Flex>
  );
};

import { v4 as uuidv4 } from "uuid";
import { removeUndefinedNullRecursive } from "../Components/Utils/dbUtils.js";
import {
  collection,
  db,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  q,
  setDoc,
  updateDoc,
  where,
} from "../firebase.js";
import { SNIPPET_TYPE_DATA } from "src/data/SnippetTypeData.js";

export const createSnippet = async ({
  projectId,
  articleId,
  articleTitle,
  articleUrl,
  snippetId,
  snippetType,
  title,
  text,
  authorId,
  curated,
  imageUrl,
  ...rest
}) => {
  if (!snippetId) {
    snippetId = uuidv4();
  }

  const snippetDocRef = doc(
    db,
    "projects/" + projectId + "/snippets/" + snippetId
  );
  const snippetData = {
    type: snippetType,
    articleTitle,
    articleId,
    articleUrl,
    title,
    text,
    createdAt: Date.now(),
    createdBy: authorId,
    curated,
    imageUrl,
    ...rest,
  };

  removeUndefinedNullRecursive(snippetData);

  console.log("createSnippet snippetData :>> ", snippetData);

  try {
    await setDoc(snippetDocRef, snippetData);
    console.log(
      "Created snippet",
      snippetId,
      "for article",
      articleId,
      "with data",
      snippetData
    );
  } catch (err) {
    console.error("Error creating snippet", err);
  }
};

// example usage for updating curated:
// updateSnippet({projectId, snippetId, curated: true})
export const updateSnippet = ({
  projectId,
  snippetId,
  title,
  text,
  curated,
  authorId,
  inTextLinkAddedToProject,
  ...rest
}) => {
  const snippetDocRef = doc(
    db,
    "projects/" + projectId + "/snippets/" + snippetId
  );

  const snippetData = {
    title,
    text,
    curated,
    updatedAt: Date.now(), //new Date().toISOString(),
    updatedBy: authorId,
    inTextLinkAddedToProject,
    ...rest,
  };

  removeUndefinedNullRecursive(snippetData);
  updateDoc(snippetDocRef, snippetData);
  console.log("Updated snippet", snippetId, "with data", snippetData);
};

export const deleteSnippet = ({ projectId, snippetId }) => {
  const snippetDocRef = doc(
    db,
    "projects/" + projectId + "/snippets/" + snippetId
  );
  deleteDoc(snippetDocRef);
  console.log("Deleted snippet", snippetId);
};

export const sortSnippets = (snippets) => {
  const sortSnippetsByType = (a, b) => {
    if (SNIPPET_TYPE_DATA[a.type].order === SNIPPET_TYPE_DATA[b.type].order) {
      return b.createdAt - a.createdAt;
    }
    return SNIPPET_TYPE_DATA[a.type].order - SNIPPET_TYPE_DATA[b.type].order;
  };
  return [...snippets].sort(sortSnippetsByType);
};

export const getSnippets = ({
  projectId,
  snippetTypes,
  articleId,
  setSnippetsCallback,
  curated,
}) => {
  if (!projectId) {
    throw new Error("No projectId provided");
  }

  // Initialize snippetsQuery with base collection
  let snippetsQuery = q(collection(db, `projects/${projectId}/snippets`));

  // Add conditions if snippetType or articleId are provided
  snippetsQuery = snippetTypes
    ? q(snippetsQuery, where("type", "in", snippetTypes))
    : snippetsQuery;
  snippetsQuery = articleId
    ? q(snippetsQuery, where("articleId", "==", articleId))
    : snippetsQuery;
  snippetsQuery = curated
    ? q(snippetsQuery, where("curated", "==", curated))
    : snippetsQuery;
  // Order reverse chronologically
  snippetsQuery = q(snippetsQuery, orderBy("createdAt", "desc"));

  const unsubscribe = onSnapshot(snippetsQuery, (querySnapshot) => {
    const snippets = [];
    querySnapshot.forEach((doc) => {
      snippets.push({ ...doc.data(), uid: doc.id });
    });
    console.log(
      "Updated snippets from db for project",
      projectId,
      "articleId",
      articleId,
      "snippetTypes",
      snippetTypes,
      snippets
    );
    setSnippetsCallback(sortSnippets(snippets));
  });
  return unsubscribe;
};

import moment from "moment";
import { formatTimestamp, ReturnDate } from "./dateutils";

export const formatSource = (source) => {
  return source ? source.substring(0, 9) : source;
};

export const formatDate = (createdAt) => {
  let articleDate = "";
  const given = moment(formatTimestamp(createdAt), "YYYY-MM-DD");
  const current = moment().startOf("day");
  const monthDuration = moment.duration(given.diff(current)).asMonths();
  const daysDuration = moment.duration(given.diff(current)).asDays();

  if (Math.abs(monthDuration) > 0.99) {
    articleDate = ReturnDate(formatTimestamp(createdAt));
  } else if (Math.abs(Math.round(daysDuration)) <= 0) {
    articleDate = "Today";
  } else if (Math.abs(monthDuration) < 0.99) {
    articleDate = `${Math.abs(Math.round(daysDuration))} Days Ago`;
  }
  return articleDate;
};

import { Icon } from "@chakra-ui/react";
import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { handleStar, handleUnStar } from "../../../services/projectSvc";

export function StarredStatusButton({
  starred,
  userCanFavourite,
  projectId,
  uid,
}) {
  return (
    <Icon
      as={starred ? AiFillStar : AiOutlineStar}
      color="black"
      opacity={starred ? 1 : 0.54}
      onClick={() => {
        if (!userCanFavourite) return;
        starred ? handleUnStar(projectId, uid) : handleStar(projectId, uid);
      }}
      cursor={userCanFavourite ? "pointer" : "not-allowed"}
      fontSize="14px"
    />
  );
}

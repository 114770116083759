import { onCurrentUserSubscriptionUpdate } from "@invertase/firestore-stripe-payments";
import { useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { payments } from "../services/StripeSvc";
import useProducts from "./useProducts";

const useSubscription = () => {
  const { currentUser } = useAuth();
  const { products } = useProducts();
  const [userCurrentSubscription, setUserCurrentSubscription] = useState(null);
  const [productSubscribedToByUser, setProductSubscribedToByUser] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // setLoading(true);
    if (currentUser && products.length) {
      const getUserSubscription = async () => {
        onCurrentUserSubscriptionUpdate(payments, async (snapshot) => {
          console.log("snapshot", snapshot);
          for (const change of snapshot.changes) {
            const userSubscrition = change?.subscription;
            // if active or trialing
            if (["active", "trialing"].includes(userSubscrition?.status)) {
              setUserCurrentSubscription(userSubscrition);

              // find and set corresponding product
              const currentUserProduct = products[0]?.prices.find(
                (price) => price.id === userSubscrition.price
              );
              setProductSubscribedToByUser(currentUserProduct);
            } else {
              setUserCurrentSubscription(null);
              setProductSubscribedToByUser([]);
            }
            setLoading(false);
          }
          setTimeout(() => {
            setLoading(false);
          }, 2000); // delay to show loading
        });
      };
      getUserSubscription();
    }
  }, [currentUser, products]);

  return {
    userCurrentSubscription,
    productSubscribedToByUser,
    isLoading: loading,
  };
};

export default useSubscription;

import { Button, Flex, Heading, Img, Link, Text } from "@chakra-ui/react";
import React from "react";
import Homesampleimg from "../../assets/extensionPreview.webp";
import { getCustomizationData } from "../Utils/customizationUtils";
import { FirstOnboardingStep } from "./FirstOnboardingStep";
import {
  AIAssistantRelevance,
  CustomModules,
  GetStarted,
  HowCanWeHelp,
  HowItWorks,
  Industry,
  OverviewOfMosaiQ,
  PublicModules,
  Testimonials,
  UsingMosaiQ,
  Welcome,
} from "./OnboardingFlowStepsItems";
import { OnboardingPaymentConfirmation } from "./OnboardingPaymentConfirmation";
import { OnboardingPaymentPlan } from "./OnboardingPaymentPlan";

const customization = getCustomizationData();

//Get current user data
export const onboardingFlowData = [
  {
    step: 1,
    content: <FirstOnboardingStep />,
    icon: "MdFitbit",
  },
  {
    step: 2,
    content: (
      <Text fontSize="19px" w={{ base: "90%", "2xl": "100%" }} m="0 auto">
        I’m {customization.assistant.name}, your AI knowledge assistant{" "}
      </Text>
    ),
    icon: "BsDot",
  },
  {
    step: 3,
    content: (
      <Text
        fontSize="19px"
        w={{ base: "90%", "2xl": "100%" }}
        m="0 auto"
        style={{ textWrap: "balance" }}
      >
        My job is to help you organise, manage and analyse information; from
        simple topic monitoring to production of reports and presentations for
        clients
      </Text>
    ),
    icon: "BsDot",
  },
  {
    step: 4,
    content: (
      <Flex pos="relative" gap={2} flexDir="column" align="center">
        <Img src={Homesampleimg} w="206px" h="412px" />
        <Heading fontSize="18px">Gather information with the extension</Heading>
        <Text mt={2} fontSize="13px" color="#727272">
          Create projects and add content to them directly in the web app and/or
          the extension. <br></br> Install the extension to collect web content
          in just a few clicks.
        </Text>
        <Button
          as={Link}
          href={process.env.REACT_APP_CHROME_WEB_STORE_URL}
          to="/user"
          mt={2}
          p="1rem"
          border="1px solid #282C3E"
          color="#282C3E"
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Install now
        </Button>
        <Text mt={2} fontSize="13px" color="#727272">
          Or later from settings
        </Text>
      </Flex>
    ),
    icon: "BsDot",
  },
  //Bring these back when we have payment plans
  {
    step: 5,
    content: <OnboardingPaymentPlan />,
    icon: "IoIosStats",
  },
  {
    step: 6,
    content: (
      <OnboardingPaymentConfirmation headingText="Payment confirmed, thank you!" />
    ),
    icon: "BsDot",
  },
];

export const updatedOnboardingFlowData = [
  {
    step: 1,
    content: Welcome,
  },
  {
    step: 2,
    content: UsingMosaiQ,
  },
  {
    step: 3,
    content: Industry,
  },
  {
    step: 4,
    content: HowCanWeHelp,
  },
  {
    step: 5,
    content: Testimonials,
  },
  {
    step: 6,
    content: AIAssistantRelevance,
  },
  {
    step: 7,
    content: OverviewOfMosaiQ,
  },
  {
    step: 8,
    content: HowItWorks,
  },
  {
    step: 9,
    content: CustomModules,
  },
  {
    step: 10,
    content: PublicModules,
  },
  {
    step: 11,
    content: GetStarted,
  },
];

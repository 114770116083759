import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ProtectedRoute from "./Auth/AuthProtectedRoute";
// import { MainHeader } from "./Components/Home/Header";
import { Helmet } from "react-helmet";
import { AiModuleEditor } from "./Components/AiModuleEditor/AiModuleEditor";
import { MultistepAiModuleEditor } from "./Components/AiModuleEditor/MultistepAiModuleEditor";
import { EmailSignUp } from "./Components/UserAuth/EmailSignUp";
import { ResetPassword } from "./Components/UserAuth/ResetPassword";
import { Plans } from "./Components/UserProfile/Plans";
import { UserProfileActions } from "./Components/UserProfile/UserProfileActions";
import { getCustomizationData } from "./Components/Utils/customizationUtils";
import { AssistantContextProvider } from "./Context/AssistantContext";
import { OnboardingContextProvider } from "./Context/OnboardingContext";
import { UserPermissionProvider } from "./Context/UserPermissionContext";
import ArticleDetail from "./Pages/ArticleDetailPage";
import { FullScreenAssistant } from "./Pages/Assistant/FullScreenAssistant";
import { SignUpPage } from "./Pages/Auth/Signup";
import { HelpAndSupport } from "./Pages/Help/HelpAndSupport";
import Home from "./Pages/Home";
import InsightPage from "./Pages/InsightPage";
import { NotFound } from "./Pages/NotFound/NotFound";
import { OnboardingFlow } from "./Pages/Onboarding/OnboardingFlow";
import { UpdatedOnboardingFlow } from "./Pages/Onboarding/UpdatedOnboardingFlow";
import PaymentStatusPage from "./Pages/PaymentStatusPage";
import ProjectPage from "./Pages/ProjectPage";
import SignInExtension from "./Pages/SignInExtension";
import SignOutExtension from "./Pages/SignOutExtension";
import { UserProfileWrapper } from "./Pages/User/UserProfileWrapper";
import LibraryPage from "./Pages/UserHomePage/LibraryPage";
import UserHomePage from "./Pages/UserHomePage/UserHomePage.js";
import { VerifyEmail } from "./Pages/VerifyEmail";
import ViewerPage from "./Pages/ViewerPage";

const customization = getCustomizationData();
const showNewOnboarding = process.env.REACT_APP_USE_ONBOARDING_FLOW === "true";
function App() {
  return (
    <Router>
      <Helmet>
        <title>{customization.meta.tabTitle}</title>
        <meta name="description" content={customization.meta.description} />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href={customization.meta.icon} />
      </Helmet>
      <Switch>
        <Route exact path="/signinExtension">
          <SignInExtension />
        </Route>
        <Route exact path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route exact path="/help">
          <HelpAndSupport />
        </Route>
        <Route exact path="/signoutExtension">
          <SignOutExtension />
        </Route>
        <ProtectedRoute path="/verifyemail">
          <VerifyEmail />
        </ProtectedRoute>
        <ProtectedRoute exact path="/user/profile">
          <UserProfileWrapper>
            <UserProfileActions />
          </UserProfileWrapper>
        </ProtectedRoute>
        <ProtectedRoute exact path="/welcome">
          {showNewOnboarding ? (
            <OnboardingContextProvider>
              <UpdatedOnboardingFlow />
            </OnboardingContextProvider>
          ) : (
            <OnboardingFlow />
          )}
        </ProtectedRoute>
        <ProtectedRoute path="/user/profile/plans">
          <UserProfileWrapper>
            <Plans />
          </UserProfileWrapper>
        </ProtectedRoute>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/signup">
          <SignUpPage />
        </Route>
        <Route exact path="/signup-with-email">
          <EmailSignUp />
        </Route>
        <ProtectedRoute path="/ai-module-editor/:moduleId?/:versionId?">
          <AssistantContextProvider>
            <AiModuleEditor />
          </AssistantContextProvider>
        </ProtectedRoute>
        <ProtectedRoute path="/multistep-ai-module-editor/:moduleId?/:versionId?">
          <AssistantContextProvider>
            <MultistepAiModuleEditor />
          </AssistantContextProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/user">
          <AssistantContextProvider>
            <UserHomePage />
          </AssistantContextProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/library">
          <AssistantContextProvider>
            <LibraryPage />
          </AssistantContextProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/user/payment/:status">
          <PaymentStatusPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/:projectId">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <ProjectPage />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/:projectId/assistant">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <FullScreenAssistant />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/:projectId/:articleId/insights">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <InsightPage />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/:projectId/:articleId/viewer/#highlight-:uid"
        >
          <UserPermissionProvider>
            <AssistantContextProvider>
              <ViewerPage />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/:projectId/:articleId/viewer">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <ViewerPage />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        <ProtectedRoute exact path="/:projectId/:articleId/articledetail/">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <ArticleDetail />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute>
        {/* <ProtectedRoute exact path="/:projectId/saved-insights">
          <UserPermissionProvider>
            <AssistantContextProvider>
              <CuratedContentPage />
            </AssistantContextProvider>
          </UserPermissionProvider>
        </ProtectedRoute> */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

export const VideoModal = ({ isOpen, onClose, videoUrl }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent h="400px">
        <ModalCloseButton />
        <ModalBody pt="12" pb="12">
          <Flex h="100%" bg="#D9D9D9" borderRadius="4px" w="100%">
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title="MosaiqLabs Product Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

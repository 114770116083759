import {
  Center,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  chakra,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { callApi } from "../../Pages/SignInExtension";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import useSearchlogic from "../../hooks/useSearchlogic";
import ImageWithIconFallback from "../Elements/ImageWithIconFallback";
import FolderIconComponent from "../Icons/FolderIconComponent";
import SearchIconComponent from "../Icons/SearchIconComponent";
import SearchOverlay from "./SearchOverlay";

const SEARCH_LIMIT = 5;

const ProjectSearchList = ({ results, router, setQuery }) => (
  <>
    {results?.projects?.slice(0, SEARCH_LIMIT).map((project) => {
      return (
        <AutoCompleteItem
          key={project.uid}
          value={project.name}
          label={project.name}
          onClick={() => {
            router.push(`/${project.uid}?sortBy=type`);
            setQuery("");
          }}
          _focus={{
            bg: "white",
          }}
        >
          <Flex
            direction="row"
            align="center"
            w={{ lg: "100%" }}
            gap={4}
            ml={1}
          >
            <Icon as={FolderIconComponent} />
            <Text
              noOfLines={{ base: 2, lg: 1 }}
              fontWeight="semibold"
              fontSize="sm"
            >
              {project.name}
            </Text>
          </Flex>
        </AutoCompleteItem>
      );
    })}
  </>
);

const ArticleSearchList = ({ results, articleType, setQuery }) => {
  const router = useHistory();

  const currentArticles = results?.articles?.filter(
    (article) => article.type === articleType
  );

  if (!currentArticles.length) {
    return null;
  }

  return (
    <>
      {currentArticles.slice(0, SEARCH_LIMIT).map((article) => {
        return (
          <AutoCompleteItem
            key={article.uid}
            value={article.title}
            label={article.title}
            onClick={() => {
              setQuery("");
              router.push(
                `/${article.projectId}?sortBy=type&poppedArticle=${article.uid}`
              );
            }}
            _focus={{
              bg: "white",
            }}
            mt={1}
          >
            <Flex direction="row" align="center" w="100%" gap={4} ml={1}>
              <ImageWithIconFallback imgSrc={article.icon} />
              <Flex direction="column" gap={1}>
                <Text
                  noOfLines={{ base: 2, lg: 1 }}
                  fontWeight="semibold"
                  fontSize="sm"
                >
                  {article.title}
                </Text>
                <Text maxW="full" fontSize="xs" fontStyle="italic">
                  {article.projectName}
                </Text>
              </Flex>
            </Flex>
          </AutoCompleteItem>
        );
      })}
    </>
  );
};

const SearchInput = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState("");
  const { hasOverlay, resetSearchUrl } = useSearchlogic();

  const router = useHistory();

  /**
   * Handle debouncing of search query. If the user stops typing for 500ms,
   * then we will call the search API.
   */
  useEffect(() => {
    if (!query) {
      setResults(null);
      return;
    }
    setIsLoading(true);
    const timeout = setTimeout(async () => {
      const response = await callApi(
        process.env.REACT_APP_SEARCH_API_URL,
        "POST",
        { query }
      );
      setResults(response);
      setIsEmpty(!response?.projects?.length && !response?.articles?.length);
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [query]);

  const emptyState = (
    <Flex direction="column" gap="6" align="center" justify="center" py="2">
      <chakra.span fontWeight="semibold" fontSize="12px">
        No Result Found
      </chakra.span>
    </Flex>
  );

  const titleStyles = {
    fontSize: "xs",
    fontWeight: "medium",
    color: "rgba(0,0,0,0.5)",
    textTransform: "initial",
  };

  return (
    <AutoComplete
      openOnFocus
      closeOnSelect={false}
      suggestWhenEmpty
      emptyState={!isLoading && isEmpty && emptyState}
    >
      <InputGroup position="relative">
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIconComponent} mt={1} />
        </InputLeftElement>
        <AutoCompleteInput
          rounded="8px"
          placeholder={`${hasOverlay ? "" : "Search for a project or article"}`}
          _placeholder={{
            opacity: 0.54,
            fontWeight: "400",
            fontSize: "12px",
          }}
          bg="white"
          border="none"
          flex="1"
          maxW={560}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          fontSize="12px"
        />
        {hasOverlay && <SearchOverlay resetSearchUrl={resetSearchUrl} />}
      </InputGroup>
      <AutoCompleteList
        mt={-1}
        h="auto"
        shadow="lg"
        display={isLoading || results ? "block" : "none"}
      >
        {isLoading ? (
          <Center py="9">
            <Spinner color="#63B3ED" />
          </Center>
        ) : results ? (
          <>
            <AutoCompleteGroup>
              <AutoCompleteGroupTitle {...titleStyles}>
                Projects
              </AutoCompleteGroupTitle>
              {/* Nested components don't seem to work in auto complete, so calling as a function */}
              {ProjectSearchList({ results, router, setQuery })}
            </AutoCompleteGroup>
            {Object.entries(ARTICLE_TYPE_DATA).map(([type, data]) => {
              return (
                <AutoCompleteGroup key={type}>
                  <AutoCompleteGroupTitle {...titleStyles}>
                    {data.uiNamePlural}
                  </AutoCompleteGroupTitle>
                  {/* Nested components don't seem to work in auto complete, so calling as a function */}
                  {ArticleSearchList({ results, articleType: type, setQuery })}
                </AutoCompleteGroup>
              );
            })}
          </>
        ) : null}
      </AutoCompleteList>
    </AutoComplete>
  );
};

export default memo(SearchInput);

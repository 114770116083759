import {
  Button,
  Flex,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import React from "react";
// import { auth } from "../../firebase";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FAQ } from "../../data/constants";
import { useAuth } from "../../Auth/AuthProvider";
import { Link as ReactRouterLink } from "react-router-dom";
import { addUrlParam } from "../Utils/urlUtils";
import ReactMarkdown from "react-markdown";
import { updateOnboardingCompleted } from "../../db/projectTour";

export const OnboardingPaymentConfirmation = ({ headingText, subText }) => {
  // const currentUser = auth.currentUser;
  let query = new URLSearchParams(useLocation().search);
  const step = query.get("step");
  const { isPremium, currentUser } = useAuth();
  if (Number(step) === 6 && !isPremium) {
    headingText = "Awesome! You’re all set";
  }
  return (
    <Flex
      flexDir="column"
      w="60%"
      m="0 auto"
      pos="relative"
      zIndex={22}
      mt="-200px"
    >
      <Flex flexDir="column" gap={2}>
        <Heading fontSize="24px" color="primary">
          {headingText}
        </Heading>
        {subText && (
          <Text color="primary" fontSize="13px">
            {subText}
          </Text>
        )}
      </Flex>
      <Button
        color="primary"
        m="3rem auto 3rem auto"
        w="fit-content"
        border="1px solid #282C3E"
        as={ReactRouterLink}
        to={addUrlParam("/user", "fromOnboarding", "true")}
        onClick={() => {
          updateOnboardingCompleted(currentUser?.uid);
        }}
      >
        Let&apos;s get to work
      </Button>
      <Flex
        gap={2}
        mt={12}
        flexDir="column"
        align="start"
        w="100%"
        m="0 auto"
        position="relative"
      >
        <Text color="#191D30">FAQ</Text>
        <Accordion
          position="absolute"
          mt={8}
          w="100%"
          allowMultiple
          overflowY="scroll"
          h="300px"
        >
          {FAQ.map((item, index) => (
            <AccordionItem
              borderRadius="md"
              boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.06)"
              border="none"
              my={4}
              key={index}
              bg="white"
            >
              <AccordionButton>
                <Box fontWeight="bold" flex="1" textAlign="left">
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} fontSize="14px" textAlign="left">
                <ReactMarkdown
                  components={{
                    p: ({ ...props }) => (
                      <p {...props} style={{ marginBottom: "1em" }} />
                    ),
                    ul: ({ ...props }) => (
                      <ul
                        {...props}
                        style={{ listStyleType: "disc", paddingLeft: "1.5em" }}
                      />
                    ),
                    li: ({ ...props }) => (
                      <li {...props} style={{ marginBottom: "0.5em" }} />
                    ),
                  }}
                >
                  {item.answer}
                </ReactMarkdown>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  );
};

import {
  Box,
  Flex,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router-dom";
import InsightCard from "../Components/Elements/SnippetCards/ComfortCard";
import TabButton from "../Components/Elements/TabButton";
import ViewModeMenu from "../Components/Elements/ViewModeMenu";
import ArrowBackIconComponent from "../Components/Icons/ArrowBackIconComponent";
import Layout from "../Components/Layout";
import { ARTICLE_TYPE_DATA } from "../data/articleTypeData";
// import { filterDataByType } from "../Components/Utils/insightUtils";
import InsightSide, { PAGES } from "../Components/insights/InsideSide";
import { useArticle } from "../Context/ArticleContext";
import { useAssistantContext } from "../Context/AssistantContext";
import {
  ACTIVE_INSIGHT_BUTTONS,
  SNIPPET_TYPE_DATA,
} from "../data/SnippetTypeData";
import { VIEW_MODE_MAP } from "../data/ViewModeData";
// import { sortSnippets } from "../db/snippet";
import { LoadingAuth } from "../Auth/LoadingAuth";
import useGetSnippets from "../hooks/useGetSnippets";
import useNotification from "../hooks/useNotification";

function InsightTypeButtonsSection({ handleButtonSelection, activeButtons }) {
  const { activeArticle } = useArticle();

  // console.log("activeArticle :>> ", activeArticle);
  if (!activeArticle) return null;

  return (
    <Flex id="aianalystBttn" flexWrap="wrap" columnGap={8} rowGap={4}>
      {Object.entries(SNIPPET_TYPE_DATA).map(([key, button]) => {
        const showButton = ARTICLE_TYPE_DATA[
          activeArticle.type
        ].insightTypes.includes(button.type);
        return (
          <>
            {showButton && (
              <Tooltip
                hasArrow
                label={button.insightToolTipMsg || button.toolTipMsg}
                bg="primary"
                key={key}
                openDelay={1000}
              >
                <Box>
                  <TabButton
                    handleButtonSelection={() =>
                      handleButtonSelection(button.type)
                    }
                    buttonDetails={button}
                    activeButtons={activeButtons}
                    key={button.name}
                    currentEnabledButtons={ACTIVE_INSIGHT_BUTTONS}
                    buttonName={button.defaultTitle || button.title}
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      })}
    </Flex>
  );
}

function InsightCardsSection({ activeButtons, snippetMode }) {
  const { articleId, projectId } = useParams();
  const { activeArticle } = useArticle();

  const { snippets } = useGetSnippets({
    snippetTypes: ["summary", "keyPoint"],
    projectId,
    articleId,
  });

  if (!snippets)
    return (
      <Flex
        align={"center"}
        width="900px"
        direction={"column"}
        gap={"20px"}
        py={"40px"}
      >
        <Text fontSize="14px" fontWeight={"bold"}>
          {"Getting insights..."}
        </Text>
        <LoadingAuth h="40px" size="lg" />
      </Flex>
    );

  const activeSnippets = snippets.filter((snippet) =>
    activeButtons.includes(snippet.type)
  );

  return (
    <Box
      // mt="4"
      display={activeButtons[0] === "Comments" && "none"}
      h="85vh"
      overflowY="scroll"
      className="webkit-scrollbar-display-none"
      pb="10"
    >
      {activeSnippets &&
        activeSnippets.map((insight, index) => (
          <Draggable
            key={insight.uid}
            isDragDisabled={false}
            draggableId={insight?.uid}
            index={index}
          >
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                className="draggable-card"
              >
                <InsightCard
                  insight={insight}
                  articleText={activeArticle?.extractedText}
                  projectId={projectId}
                  articleTitle={activeArticle?.title}
                  viewMode={snippetMode}
                  isInsightPage={true}
                  isLastItem={index === snippets.length - 1}
                />
              </Box>
            )}
          </Draggable>
        ))}
    </Box>
  );
}

const InsightPage = () => {
  const history = useHistory();
  const searchParams = history.location?.state?.params || "";
  let { projectId, articleId } = useParams();
  // const location = useLocation();
  const [activeButtons, setActiveButtons] = useState([
    SNIPPET_TYPE_DATA.summary.type,
  ]);
  const { notify } = useNotification();

  const { activeArticle } = useArticle();

  const articleType = activeArticle?.type;
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);

  // https://www.notion.so/mosaiqlabs/Change-default-view-for-AI-Insights-on-PDFs-from-Default-to-Comfort-a75374d3d54148949775ebce92a21a44?pvs=4
  const [snippetMode, setSnippetMode] = useState(
    ARTICLE_TYPE_DATA[articleType]?.defaultInsightCardMode ||
      VIEW_MODE_MAP.default.id
  );

  const { assistantIsOpen, setAssistantIsOpen } = useAssistantContext();

  const handleButtonSelection = (type) => {
    if (activeButtons.includes(type)) {
      if (activeButtons.length > 1) {
        const newButtons = activeButtons.filter((button) => button != type);
        setActiveButtons(newButtons);
      } else {
        notify({
          title: "A tab must be active",
          status: "error",
        });
      }
    } else {
      setActiveButtons([
        ...activeButtons.filter((item) => item !== "articleNote"),
        type,
      ]);
    }
  };

  // handle changing the URL to a different articles
  const routerHandler = (article) => {
    if (article.uid === articleId) return;
    history.push({
      pathname: `/${projectId}/${article.uid}/insights`,
      state: {
        articleData: article,
        // params: location.state.params,
      },
    });
  };

  /**
   * Open the assistant by default
   * https://www.notion.so/mosaiqlabs/Make-assistant-open-by-default-on-AI-Insights-c54dae700a7a41d19688adc444d7f02a?pvs=4
   */
  useEffect(() => {
    setAssistantIsOpen(true);
  }, []);

  useEffect(() => {
    if (assistantIsOpen) {
      setMenuIsOpen(false);
      setMenuIsExpanded(false);
    }
  }, [assistantIsOpen]);

  useEffect(() => {
    console.log("menuIsExpanded", menuIsExpanded);
    if (menuIsExpanded) {
      setAssistantIsOpen(false);
    }
  }, [menuIsExpanded]);

  return (
    <Layout>
      <Box
        h={"100%"}
        w={assistantIsOpen ? `calc(100% - 10px)` : "100%"}
        maxW="100%"
        transition="width 0.3s ease"
      >
        <Flex
          templateColumns="repeat(48, 1fr)"
          gap={6}
          height="95vh"
          className="webkit-scrollbar-display-none"
        >
          <Box
            minW={assistantIsOpen ? "auto" : "250px"}
            maxW={assistantIsOpen ? "auto" : "250px"}
          >
            <VStack align="start" color="black" py="8">
              <Flex
                gap="18px"
                cursor="pointer"
                color="primary"
                fontWeight="medium"
                onClick={() => {
                  history.push(`/${projectId}/${searchParams}`);
                }}
                mb={5}
              >
                <ArrowBackIconComponent color="primary" fontSize="24px" />
              </Flex>
              <InsightSide
                routerHandler={(article) => routerHandler(article)}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                menuIsOpen={menuIsOpen}
                setMenuIsExpanded={setMenuIsExpanded}
                page={PAGES.INSIGHTS}
                menuIsExpanded={menuIsExpanded}
              />
            </VStack>
          </Box>
          <Box maxW={assistantIsOpen ? "100%" : "70%"}>
            <Box w="100%" pb="8" pt="4" ml={3} my="4">
              {/* {!isLoading ? ( */}
              <Droppable droppableId="CARDS" isDropDisabled={true}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    w="100%"
                  >
                    <Stack
                      id="aianalystBttn"
                      spacing={2}
                      direction="row"
                      align="center"
                      mb="20px"
                    >
                      <InsightTypeButtonsSection
                        handleButtonSelection={handleButtonSelection}
                        activeButtons={activeButtons}
                      />
                      <Spacer />
                      <ViewModeMenu
                        setViewMode={(id) => setSnippetMode(id)}
                        viewMode={snippetMode}
                      />
                    </Stack>
                    {/* {activeArticle?.summaryReady ? ( */}
                    <InsightCardsSection
                      activeButtons={activeButtons}
                      snippetMode={snippetMode}
                    />

                    {/* )} */}
                  </Box>
                )}
              </Droppable>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default InsightPage;

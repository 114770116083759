import { removeUndefinedNullRecursive } from "../Components/Utils/dbUtils.js";
import {
  collection,
  collectionGroup,
  db,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  q,
  setDoc,
  updateDoc,
  where,
} from "../firebase";

import { v4 as uuidv4 } from "uuid";

const createArticleDocRef = ({ projectId, articleId }) =>
  `projects/${projectId}/articles/${articleId}`;

// const createArticlesCollectionPath = ({ projectId }) =>
//   "projects/" + projectId + "/articles";

// star Web Article
export const starArticle = (projectId, articleId) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  // dbref.update({
  updateDoc(articleDocRef, {
    starred: true,
  });
};

// Unstar Web Article
export const unStarArticle = (projectId, articleId) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  updateDoc(articleDocRef, {
    starred: false,
  });
};

//Update the requestInsight attr in an article
// export const updateArticleRequestInsights = ({ projectId, articleId }) => {
//   const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
//   updateDoc(articleDocRef, {
//     requestInsights: true,
//   }).then(() => {
//     return "done";
//   });
// };

export const updateArticleType = ({ projectId, articleId, type }) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  updateDoc(articleDocRef, {
    type,
  }).then(() => {
    return "done";
  });
};

//Get an article
export const getArticle = ({ projectId, articleId }) => {
  return new Promise((resolve) => {
    const articleDocRef = doc(
      db,
      createArticleDocRef({ projectId, articleId })
    );
    getDoc(articleDocRef).then((result) => {
      if (result.data()) {
        resolve({
          ...result.data(),
          uid: result.id,
        });
      }
    });
  });
};

export const editArticleTitle = (projectId, articleId, newtitle) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  updateDoc(articleDocRef, {
    title: newtitle,
  });
};

// add article to project
export const createArticle = ({ projectId, articleId, payload }) => {
  console.log("Adding article", articleId, "to project", projectId);
  const articleDocRef = doc(db, `projects/${projectId}/articles/${articleId}`);
  setDoc(articleDocRef, payload).then(() => {
    console.log("Added article", articleId, "to project", projectId);
  });
  // .catch((e) => {
  //   console.log(e);
  // });
};

export const deleteArticle = (projectId, articleId) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  deleteDoc(articleDocRef);
};

export const getAllArticles = ({ projectId, callback }) => {
  let articleCollectionQuery = q(
    collection(db, `projects/${projectId}/articles`)
  );
  const unsubscribe = onSnapshot(articleCollectionQuery, (snapshot) => {
    console.log(`Updating articles for project ${projectId}`);
    const articles = [];
    snapshot.forEach((doc) => {
      const articleData = doc.data();
      // if article has to title, set title to url
      if (!articleData.title) {
        articleData.title = articleData.url;
      }
      if (!articleData.source) {
        articleData.source = "";
      }
      articles.push({ ...articleData, uid: doc.id });
    });
    callback(articles);
  });
  return () => {
    console.log("Unsubscribed from articles");
    unsubscribe();
  };
};

export const getArticleById = ({ projectId, articleId, callback }) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  const unsubscribe = onSnapshot(articleDocRef, (doc) => {
    if (doc.exists()) {
      const article = { ...doc.data(), uid: doc.id };
      console.log("Updated article from db", article);
      callback(article);
    }
  });
  return () => {
    console.log("Unsubscribing from article", articleId);
    unsubscribe();
  };
};

//Create oneAiInsightRequest collection.
export const createAiInsightsRequests = ({
  projectId,
  articleId,
  articleTitle,
  articleUrl,
  createdBy,
}) => {
  const requestId = uuidv4();
  const incomingReqRef = doc(
    db,
    `getOneAiInsightsIncomingRequests/${requestId}`
  );
  const incomingRequestData = {
    projectId,
    articleId,
    articleUrl,
    createdBy,
  };

  setDoc(incomingReqRef, incomingRequestData);

  const gptKeyPointsRef = doc(db, `gptKeyPointRequests/${requestId}`);
  setDoc(gptKeyPointsRef, {
    ...incomingRequestData,
    articleTitle,
    createdAt: Date.now(),
  });
  console.log(`GPT key points request ${requestId} sent successfully.`);
};

export const updateXfdString = ({ projectId, articleId, xfdString }) => {
  const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
  updateDoc(articleDocRef, {
    xfdString: xfdString,
  }).then(() => {
    // console.log("Updated xfdString for article", articleId, "to", xfdString);
  });
};

export const getXfdString = (projectId, articleId) => {
  const articleDocRef = doc(
    db,
    "projects/" + projectId + "/articles/",
    articleId
  );
  return new Promise((resolve) => {
    // console.log("articleDocRef", articleDocRef);
    getDoc(articleDocRef).then((doc) => {
      // console.log("Document data:", doc.data());
      resolve(doc.data().xfdString);
    });
  });
};

export const getArticleManualHighlights = ({
  projectId,
  articleId,
  callback,
}) => {
  const manualHighlightsQuery = q(
    collection(db, "projects/" + projectId + "/snippets"),
    where("articleId", "==", articleId),
    where("type", "in", ["manualHighlight", "fillTemplateInsight"])
  );

  const unsubscribe = onSnapshot(manualHighlightsQuery, (querySnapshot) => {
    const manualHighlights = [];
    querySnapshot.forEach((doc) => {
      manualHighlights.push({ ...doc.data(), uid: doc.id });
    });
    console.log("Updated manual extractions from db: ", manualHighlights);
    callback(manualHighlights);
  });
  return unsubscribe;
};

export const updateArticle = ({ projectId, articleId, ...articleData }) => {
  const articleDocRef = doc(db, `projects/${projectId}/articles/${articleId}`);
  removeUndefinedNullRecursive(articleData);
  updateDoc(articleDocRef, articleData);
  console.log("Updated article", articleId, "with data", articleData);
};

// export const getAllUserProjects = (userId, callback) => {
//   // grab all projects authored by the user; in the future,
//   // this can be expanded to include projects shared with the user
//   const collectionRef = q(
//     collection(db, `projects`),
//     where("author", "==", userId)
//   );
//   const unsubscribe = onSnapshot(collectionRef, (collectionObj) => {
//     const projects = [];
//     collectionObj.forEach((d) => {
//       // include uid in the project object
//       projects.push({ uid: d.id, ...d.data() });
//     });
//     console.log("Updated projects from db", projects);
//     callback(projects);
//   });
//   return unsubscribe;
// };

export const getAllUserProjects = (userId, callback) => {
  let authoredProjects = [];
  let sharedProjectsMap = new Map();

  const updateCallback = () => {
    const allProjects = [...authoredProjects, ...sharedProjectsMap.values()];
    console.log("Updated projects from db", allProjects);
    callback(allProjects);
  };

  // Query for authored projects
  const authoredProjectsRef = q(
    collection(db, "projects"),
    where("author", "==", userId)
  );

  // Subscribe to authored projects
  const unsubscribeAuthored = onSnapshot(
    authoredProjectsRef,
    (collectionObj) => {
      authoredProjects = [];
      collectionObj.forEach((d) => {
        authoredProjects.push({ uid: d.id, ...d.data() });
      });
      updateCallback();
    }
  );

  // Query for shared projects
  const sharedProjectsRef = q(
    collectionGroup(db, `sharedWith`),
    where("uid", "==", userId)
  );

  // const unsubscribeSharedProjects = [];

  // Subscribe to shared projects
  // const unsubscribeShared = onSnapshot(sharedProjectsRef, (collectionObj) => {
  //   const sharedProjectsId = [];
  //   collectionObj.forEach((d) => {
  //     sharedProjectsId.push(d.ref.parent.parent.id);
  //   });
  //   // subscribe to each of the shared projects, and collect unsubscribe functions
  //   sharedProjects = [];
  //   for (const projectId of sharedProjectsId) {
  //     const sharedProjectRef = doc(db, "projects", projectId);
  //     unsubscribeSharedProjects.push(
  //       onSnapshot(sharedProjectRef, (doc) => {
  //         console.log(`Subscribed to shared project ${projectId}`);
  //         if (doc.exists()) {
  //           sharedProjects.push({ ...doc.data(), uid: doc.id, shared: true });
  //         }
  //         updateCallback();
  //       })
  //     );
  //   }

  let unsubscribeSharedProjects = [];

  getDocs(sharedProjectsRef).then((snapshot) => {
    const sharedProjectsId = snapshot.docs.map(
      (doc) => doc.ref.parent.parent.id
    );
    for (const projectId of sharedProjectsId) {
      const sharedProjectRef = doc(db, "projects", projectId);
      unsubscribeSharedProjects.push(
        onSnapshot(sharedProjectRef, (doc) => {
          console.log(`Subscribed to shared project ${projectId}`);
          sharedProjectsMap.delete(projectId);
          if (doc.exists()) {
            sharedProjectsMap.set(projectId, {
              ...doc.data(),
              uid: doc.id,
              shared: true,
            });
          }
          updateCallback();
        })
      );
    }
  });

  // for (const projectId of sharedProjectsId) {
  //   console.log("Getting shared project", projectId);
  //   getDoc(doc(db, "projects", projectId)).then((doc) => {
  //     console.log("Got shared project", doc.data());
  //     if (doc.exists()) {
  //       sharedProjects.push({ ...doc.data(), uid: doc.id });
  //     }
  //   });
  // }
  // updateCallback();

  // });
  // Return a function to unsubscribe from both
  return () => {
    console.log(`Unsubscribing from all projects`);
    unsubscribeAuthored();
    unsubscribeSharedProjects.forEach((f) => f());
  };
};

import { Flex, MenuItem, Text, Tooltip, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import { FaRegFolderClosed } from "react-icons/fa6";
import { useAuth } from "../../../Auth/AuthProvider";
import { useArticle } from "../../../Context/ArticleContext";
import { useProjectContext } from "../../../Context/ProjectContext";
import { createArticle } from "../../../db/article";
import { removeUndefinedNullRecursive } from "../../Utils/dbUtils";
import { MenuItemBttn } from "./TableComponents";

const AllProjectList = ({
  sortedAndActiveProjects,
  display,
  closeMenu,
  addArticleToProject,
}) => {
  return (
    <Flex
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.04)"
      color="#D9D9D9"
      bg="white"
      position="absolute"
      left="102%"
      top="0.5%"
      flexDir="column"
      w="136px"
      pb="3"
      pt="2"
      visibility={display ? "visible" : "hidden"}
      zIndex="1000000"
      onMouseLeave={() => {
        closeMenu();
      }}
      borderRadius={4}
    >
      {sortedAndActiveProjects?.length === 0 ? (
        <MenuItem bg="white" color="#D9D9D9" pr="2">
          <Text>No other projects</Text>
        </MenuItem>
      ) : (
        sortedAndActiveProjects?.map((project) => {
          const isShared = project?.shared;

          const cantBeCopiedTo = isShared;

          return (
            <React.Fragment key={project.name}>
              {" "}
              <Tooltip
                label={isShared ? "You can not copy to a shared project" : ""}
                placement="right"
              >
                <Flex
                  opacity={cantBeCopiedTo ? "0.4" : "0.56"}
                  cursor={cantBeCopiedTo ? "not-allowed" : "pointer"}
                  as={MenuItem}
                  bg="white"
                  color="black"
                  pr="2"
                  fontWeight="600"
                  _hover={{
                    bg: "#D9D9D9",
                    color: "#858585",
                    opacity: "1",
                  }}
                  onClick={() => {
                    if (!cantBeCopiedTo) {
                      addArticleToProject(project);
                    }
                  }}
                >
                  <Text textTransform="capitalize" noOfLines={1}>
                    {project.name}
                  </Text>
                </Flex>
              </Tooltip>
            </React.Fragment>
          );
        })
      )}
    </Flex>
  );
};

export const TableRowCopyToProjectBttn = ({
  articleData,
  multiDocSelected,
  color,
}) => {
  const textColor = color || "#7b7b7b";
  const { allProjects, activeProjectsWithoutCurrentProject } =
    useProjectContext();
  const { userDoc, currentUser } = useAuth();
  const [sortedAndActiveProjects, setSortedAndActiveProjects] = useState();
  const toast = useToast();
  const { showArticleCopyMenu, setShowArticleCopyMenu, selectedArticlesData } =
    useArticle();
  useEffect(() => {
    setSortedAndActiveProjects(activeProjectsWithoutCurrentProject);
  }, [
    allProjects,
    userDoc.archivedProjects,
    activeProjectsWithoutCurrentProject,
  ]);

  const copyArticleToProject = (project, article) => {
    const clonedArticleDataPayload = {
      ...article,
      createdBy: currentUser.uid,
      createdAt: Date.now(),
      indexingStatus: {
        status: "pending",
      },
    };
    removeUndefinedNullRecursive(clonedArticleDataPayload);
    createArticle({
      projectId: project.uid,
      articleId: article.uid,
      payload: clonedArticleDataPayload,
    });
    !multiDocSelected &&
      toast({
        title: "Article copied to project",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    return;
  };

  const handleCopyToProject = (project) => {
    if (multiDocSelected && selectedArticlesData.length > 0) {
      selectedArticlesData.forEach((article) => {
        copyArticleToProject(project, article);
      });
      toast({
        title: "Articles copied to project",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    copyArticleToProject(project, articleData);
  };

  return (
    <>
      <MenuItemBttn
        position="relative"
        bg="white"
        icon={<FaRegFolderClosed fontSize="12px" />}
        _hover={{
          bg: "#f7f7f7",
        }}
        onMouseEnter={() => {
          setShowArticleCopyMenu(true);
        }}
        fontWeight="600"
        label="Copy to Project"
        color={textColor}
      >
        <Flex gap="2" align="center" justify="space-between">
          Copy to project
          <FaCaretRight />
        </Flex>
      </MenuItemBttn>
      <AllProjectList
        sortedAndActiveProjects={sortedAndActiveProjects}
        display={showArticleCopyMenu}
        addArticleToProject={(project) => {
          handleCopyToProject(project);
        }}
        closeMenu={() => {
          setShowArticleCopyMenu(false);
        }}
      />
    </>
  );
};

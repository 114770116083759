// import GrDiamond from "react-icons/gr";
import { BsRocketTakeoff } from "react-icons/bs";
import { LiaFlagCheckeredSolid } from "react-icons/lia";
import { MdOutlineDiamond } from "react-icons/md";
import {
  MONTHLY_AI_CREDITS,
  MONTHLY_PDF_PAGES_LIMIT,
} from "../../../data/constants";

export const plansList = {
  free: {
    id: "free",
    title: "Starter",
    priceMonth: "0",
    priceYear: "0",
    icon: (props) => <LiaFlagCheckeredSolid {...props} />,
    perks: [
      {
        value: "Unlimited Projects",
        compareValue: true,
        compareName: "Unlimited Projects",
      },
      {
        value: "Maximum 100 articles / files uploads",
        compareValue: "Maximum 100 articles / files uploads",
        compareName: "Unlimited articles / file uploads",
      },
      {
        value: "Maximum 2500 pages of PDFs analyzed",
        compareValue: "2500 pages of PDFs analyzed in total",
        compareName: "PDF Pages",
      },
      {
        value: "Projects shareable in view only",
        compareValue: "View-only",
        compareName: "Sharing and collaboration",
      },
      {
        value: "100 AI responses in total",
        compareValue: "100 in total",
        compareName: "AI responses",
      },
    ],
  },
  premium: {
    id: "premium",
    title: "Premium",
    priceMonth: "59",
    priceYear: "499",
    icon: (props) => <MdOutlineDiamond {...props} />,
    perks: [
      {
        value: "Unlimited Projects",
        compareValue: true,
        compareName: "Unlimited Projects",
      },
      {
        value: "Unlimited articles / file uploads",
        compareValue: true,
        compareName: "Unlimited articles / file uploads",
      },
      {
        value: `${MONTHLY_PDF_PAGES_LIMIT} pages of PDFs analyzed per month`,
        compareValue: `${MONTHLY_PDF_PAGES_LIMIT} pages of PDFs analyzed per month`,
        compareName: "PDF Pages",
      },
      {
        value: "Integration with Google Drive (coming soon)",
        compareValue: true,
        compareName: "Integration",
      },
      {
        value: "Fully Collaborative projects (coming soon)",
        compareValue: "Fully Collaborative projects (coming soon)",
        compareName: "Sharing and collaboration",
      },
      {
        value: `${MONTHLY_AI_CREDITS} AI responses per month`,
        compareValue: `${MONTHLY_AI_CREDITS} every month`,
        compareName: "AI responses",
      },
    ],
  },
  enterprise: {
    id: "enterprise",
    title: "Enterprise",
    priceMonth: "15",
    priceYear: "150",
    icon: (props) => <BsRocketTakeoff {...props} />,
    perks: [
      {
        // compareName: "Unlimited Projects",
        // compareValue: true,
        value: "Workshops",
      },
      {
        // compareName: "Unlimited documents volume and size",
        // compareValue: true,
        value: "Audit & strategy",
      },
      {
        // compareName: "Unlimited manual annotations",
        // compareValue: true,
        value: "Buy platform, components, or white label",
      },
      {
        // compareName: "Connect your Google Drive",
        // compareValue: true,
        value: "Buy & deploy the assistant only on your existing stack",
      },
      {
        // compareName: "Share your projects",
        // compareValue: true,
        value: "Project based customization",
      },
      {
        // compareName: "X AI Credits",
        // compareValue: true,
        value: "Custom integrations with your stack",
      },
      {
        // compareName: "X AI Credits",
        // compareValue: true,
        value: "Custom AI capabilities",
      },
    ],
  },
};

import {
  getStripePayments,
  getProducts,
  createCheckoutSession,
} from "@invertase/firestore-stripe-payments";
import { app, auth } from "../firebase";
import axios from "axios";
// import { db, addDoc, collection, onSnapshot } from "../firebase";

//configures the stripe payments to use the products and customers collections
export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});

export const handleStripePayment = async (plan, successUrl, cancelUrl) => {
  const origin = window.location.origin;
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });


  const premiumProductId = process.env.REACT_APP_PREMIUM_PLAN_PRODUCT_ID;
  const product = products.find((product) => product.id === premiumProductId);

  // get only active prices
  const activePrices = product.prices.filter((price) => price.active);

  // grab the price with the corresponding interval
  const price = activePrices.find((price) => price.interval === plan);

  const session = await createCheckoutSession(payments, {
    price: price.id,
    success_url: `${origin}/${successUrl}`,
    cancel_url: `${origin}/${cancelUrl}`,
  });
  return session;
  // return null;
};

/**
 * Checkout session for AI Credits
 *
 * @returns
 */
export const handleStripeAiCreditsPayment = async (
  nCredits,
  successUrl,
  cancelUrl
) => {
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });

  const productId = process.env[`REACT_APP_AI_CREDITS_PRODUCT_ID`];
  const product = products.find((product) => product.id === productId);

  // get only active prices
  const activePrices = product.prices.filter((price) => price.active);
  // check if there is a env var for this number of nCredits
  if (!process.env[`REACT_APP_AI_CREDITS_${nCredits}_PRICE_ID`]) {
    console.error(`Price ID for ${nCredits} AI credits not found`);
    return null;
  }
  // grab the price with the corresponding interval
  const price = activePrices.find(
    (price) =>
      price.id === process.env[`REACT_APP_AI_CREDITS_${nCredits}_PRICE_ID`]
  );

  const session = await createCheckoutSession(payments, {
    mode: "payment",
    price: price.id,
    success_url: `${window.location.origin}/${successUrl}`,
    cancel_url: `${window.location.origin}/${cancelUrl}`,
  });
  return session;
};

export const getUserSubscriptionStatus = async () => {
  try {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    return decodedToken?.claims;
  } catch (err) {
    console.error("Error checking premium status:", err);
    return false;
  }
};

export const deleteSubscription = async (subscriptionId) => {
  try {
    const queryParams = {
      subscription_id: subscriptionId,
    };
    const decodedToken = await auth.currentUser.getIdToken(true);
    const headers = {
      Authorization: decodedToken,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_STRIPE_API_URL}/cancelUserSubscription`,
      {
        params: queryParams,
        headers: headers,
      }
    );

    if (response.status === 200) {
      return true;
    }

    return false;
  } catch (err) {
    console.error("Error deleting subscription:", err);
    return false;
  }
};

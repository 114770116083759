export const CLIENT_CUSTOMIZATION_DATA = {
  default: {
    name: "MosaiQ",
    logo: `${process.env.PUBLIC_URL}/mosaiq-logo-new.png`,
    template: {
      name: "Template",
      namePlural: "Templates",
      enableFillTemplate: false,
      // smart templates
      smartTemplates: true,
      // show template insights in smart template (filters for cards)
      showTemplateInsights: false,
    },
    assistant: {
      showCustomPrompts: false,
      isOpen: false,
      name: "Symba",
      nameCasual: "Symba",
      removeReferenceLinks: false,
      headerTemplateProminent: false,
      openingMessage: (assistantName) => {
        return `**Welcome to ${assistantName}, Your AI Assistant!**

${assistantName} uses the information you add to your projects to answer questions and find solutions.

**Ask**: Select relevant documents and ask questions, to get the answers you need right away. For example, “What are the main ideas in these documents?”

**Save**: Highlight and save important insights.

**Organize**: Create templates to structure your insights.

Start exploring what ${assistantName} can do for you today!`;
      },
    },
    aiModules: {
      hideLibrary: false,
    },
    disableProductTour: false,
    disableOnboarding: false,
    homePage: {
      showExtensionMissingNotice: true,
      noProjectText: "Create your first project!",
    },
    brief: {
      heading: "Welcome to your brief.",
      body: "Add your project questions so that IntelQ's AI can automatically answer them with insights from your documents. You can also manually add insights!",
    },
    infoTooltips: {
      sentimentCenter: "",
      controlCenter: "",
      fillTemplate: "",
      templates: "",
      history: "",
      customAI: "",
      briefs: "",
      aiModules: "",
      fillBrief: "",
      sentiments: "",
    },
    savedInsights: {
      // linkToVideo: "about:blank",
    },
    contentTypeIds: ["file", "text", "link"],
    // skips creation of a sample project for new users
    skipSampleProject: false,
    // in the project page articles table, swap "type" for "length"; made for Listn deployment
    // to show this data for audio files
    swapTypeForLength: false,
    // meta tags
    meta: {
      // tab title
      tabTitle: "MosaiqLabs - Knowledge Assistant",
      // meta description
      description:
        "Next Generation, AI powered Document Curation and Research Labs",
      // path to favicon (tab icon)
      icon: `${process.env.PUBLIC_URL}/favicon.ico`,
    },
    // account dropdown menu customization
    accountMenu: {
      // hide product tour button
      hideProductTour: false,
      // hide chrome extension link button
      hideChromeExtensionLink: false,
    },
    documentMenu: {
      hideOpenInBrowser: false,
      hideMarkAsLink: false,
    },
    articleDetailDefaultTab: "aiInsight",
  },
  "p001-intelq": {
    name: "IntelQ",
    logo: `${process.env.PUBLIC_URL}/intelq-logo.png`,
    template: {
      name: "Brief",
      namePlural: "Briefs",
      enableFillTemplate: true,
      showTemplateInsights: true,
    },
    assistant: {
      isOpen: true,
      name: "IntelQ AI Assistant",
      nameCasual: "IntelQ assistant",
      headerTemplateProminent: true,
      openingMessage: (assistantName) => {
        return `**Welcome to your ${assistantName}!**

It uses the information you add to your projects to answer questions and help you find solutions.

**Ask**: Select relevant documents and ask questions, to get the answers you need right away. For example, “What are the main ideas in these documents?”

**Save**: Highlight and save important insights.

**Organize**: Create Briefs to structure your insights.

Start asking questions to explore what your assistant can do for you!`;
      },
    },
    aiModules: {
      hideLibrary: true,
    },
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
      noProjectText: "Create your first project!",
    },
    brief: {
      heading: "Welcome to your brief.",
      body: "Add your project questions so that IntelQ's AI can automatically answer them with insights from your documents. You can also manually add insights!",
    },
    infoTooltips: {
      aiModules: "Run IntelQ's custom analysis on selected documents",
      sentimentCenter:
        "Assess the openness and  level of confidence of your sources to better contextualize their insights.",
      controlCenter:
        "Assess the sources and insights used to answer your project questions",
      fillTemplate:
        "Automatically classify information from selected documents to populate the sections you created",
      fillBrief:
        "Use AI to answer your project questions with insights from your selected documents",
      briefs:
        "Answer your project questions with AI and manually-generated insights",
      templates:
        "Open, add sections / questions, and automatically classify information from documents in those sections",
      history: "View past conversations and pick up where you left off",
      customAI:
        "Open, select and run custom analysis modules on your documents",
    },
    savedInsights: {
      // linkToVideo: "about:blank",
    },
    contentTypeIds: ["intelqVerbatim", "intelqQna", "file", "text", "link"],
    meta: {
      tabTitle: "IntelQ",
      description: "IntelQ",
      icon: `${process.env.PUBLIC_URL}/intelq.ico`,
    },
    accountMenu: {
      hideProductTour: true,
      hideChromeExtensionLink: true,
    },
  },
  "p002-listn": {
    name: "Listn",
    logo: `${process.env.PUBLIC_URL}/listn-logo.png`,
    assistant: {
      name: "Zazu",
      nameCasual: "Zazu",
      isOpen: true,
      removeReferenceLinks: true,
    },
    aiModules: {
      hideLibrary: true,
    },
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
    },
    contentTypeIds: ["audio"],
    skipSampleProject: true,
    swapTypeForLength: true,
    meta: {
      tabTitle: "LISTN - Meeting intelligence",
      description: "LISTN - Meeting intelligence",
      icon: `${process.env.PUBLIC_URL}/listn.ico`,
    },
    accountMenu: {
      hideProductTour: true,
      hideChromeExtensionLink: true,
    },
    documentMenu: {
      hideOpenInBrowser: true,
      hideMarkAsLink: true,
    },
  },
  "p003-sdv": {
    name: "SwissDataVision",
    logo: `${process.env.PUBLIC_URL}/sdv-logo.png`,
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
    },
    meta: {
      tabTitle: "SwissDataVision",
      description: "SwissDataVision",
      icon: `${process.env.PUBLIC_URL}/sdv.ico`,
    },
  },
};

export const DOMAIN_PROJECT_MAP = {
  "swissdatavision.web.app": "p003-sdv",
};

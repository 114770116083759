import { useAuth } from "../Auth/AuthProvider";

/**
 * This is a hook is made to keep track of all things related to the user's limitations,
 * such as AI creditcs, PDF pages, articles, etc.
 *
 * @returns {Object} limitations
 */
export function useLimitations() {
  const { userDoc } = useAuth();
  const aiCreditsPeriodic = userDoc.aiCreditsPeriodic || 0;
  const aiCreditsExtra = userDoc.aiCreditsExtra || 0;
  const aiCreditsTotal = aiCreditsPeriodic + aiCreditsExtra;
  const assistantDisabled = aiCreditsTotal <= 0;

  return {
    aiCreditsPeriodic,
    aiCreditsExtra,
    aiCreditsTotal,
    assistantDisabled,
  };
}

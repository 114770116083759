import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  AiModulePermission,
  definitionConverter,
  moduleDataSubCollection,
  permissionsSubCollection,
} from "./AiModuleTypes";
import { MultistepModuleDefinition } from "./multistepAiModuleTypes";

export const createModulePermission = async ({
  moduleId,
  userId,
  userEmail,
  role,
  addedBy,
}: {
  moduleId: string;
  userId: string;
  userEmail: string;
  role: AiModulePermission["role"];
  addedBy?: string;
}): Promise<void> => {
  const permissionDocRef = doc(permissionsSubCollection(moduleId), userId);
  await setDoc(permissionDocRef, {
    userId,
    userEmail,
    role,
    addedBy: addedBy || userId,
    addedAt: Date.now(),
  });
};

export const createNewMultistepModule = async (
  moduleConfig: MultistepModuleDefinition,
  userId: string,
  userEmail: string
) => {
  const newModuleId = uuidv4();

  // create permission document
  await createModulePermission({
    moduleId: newModuleId,
    userId,
    userEmail,
    role: "owner",
  });

  const versionId = "initial";
  const definitionDocRef = moduleDataSubCollection(
    newModuleId,
    versionId,
    "definition"
  ).withConverter(definitionConverter);
  await setDoc(definitionDocRef, moduleConfig);
};

export const releaseNewMultistepModuleVersion = async (
  moduleId: string,
  moduleDefinition: MultistepModuleDefinition
) => {
  const newVersionId = uuidv4();

  const definitionDocRef = moduleDataSubCollection(
    moduleId,
    newVersionId,
    "definition"
  ).withConverter(definitionConverter);

  await setDoc(definitionDocRef, moduleDefinition);
};

import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsChatLeftFill } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const LinearBgFooter = () => {
  return (
    <Flex
      position="fixed"
      bottom="0"
      right="0"
      w="100%"
      h="12px"
      bg="linear-gradient(90deg, #191D30 0%, #49558D 100%, #4E5B96 100%);"
    />
  );
};

export const ForwardBttn = ({
  handleArrowClicks,
  showText,
  allowForwardNav,
  setAllowForwardNav,
  endOfSteps,
}) => {
  return (
    <Flex
      h="fit-content"
      justify="center"
      align="center"
      onClick={() => {
        if (!allowForwardNav && !endOfSteps) return;
        setAllowForwardNav(true);
        handleArrowClicks("right");
      }}
      gap="38px"
      pos="absolute"
      right="2%"
      cursor={endOfSteps ? "not-allowed" : "pointer"}
      opacity={allowForwardNav && !endOfSteps ? 1 : 0.5}
    >
      <Text
        fontSize="12px"
        color="primary"
        visibility={showText ? "visible" : "hidden"}
      >
        GO FORWARD
      </Text>
      <FaChevronRight width="29px" height="33px" fontSize="40px" />
    </Flex>
  );
};

export const BackwardBttn = ({
  handleArrowClicks,
  setAllowForwardNav,
  step,
}) => {
  return (
    <Flex
      opacity={step === 1 ? 0.5 : 1}
      h="fit-content"
      justify="center"
      align="center"
      onClick={() => {
        if (step === 1) return;
        handleArrowClicks("left");
        setAllowForwardNav(true);
      }}
      gap="38px"
      pos="absolute"
      left="2%"
      cursor="pointer"
    >
      <FaChevronLeft width="29px" height="33px" fontSize="40px" />
    </Flex>
  );
};

export const CustomForm = ({ title, options, showOther, submitFormValue }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedOption === value) {
      setSelectedOption("");
      submitFormValue("");
    } else {
      setSelectedOption(value);
      submitFormValue(value);
    }
  };

  return (
    <Flex flexDir="column" gap="12px" w="100%">
      <Heading fontWeight="bold" fontSize="13px">
        {title}
      </Heading>
      <VStack align="start" spacing={2} color="#727272">
        {options.map((option, index) => (
          <Checkbox
            display="flex"
            key={index}
            value={option.value}
            isChecked={selectedOption === option.value}
            onChange={handleCheckboxChange}
            colorScheme="primary"
            sx={{
              ".chakra-checkbox__control": {
                borderColor: "#999999",
                _checked: {
                  bg: "transparent",
                  borderColor: "#999999",
                },
              },
              ".chakra-checkbox__control svg": {
                color: "black",
              },
            }}
          >
            <Text
              fontSize="12px"
              color={selectedOption === option.value ? "primary" : "#727272"}
            >
              {option.label}
            </Text>
          </Checkbox>
        ))}
        <Checkbox
          value="other"
          isChecked={selectedOption === "other"}
          onChange={handleCheckboxChange}
          display={showOther ? "flex" : "none"}
          colorScheme="primary"
          sx={{
            ".chakra-checkbox__control": {
              borderColor: "#999999",
              _checked: {
                bg: "transparent",
                borderColor: "#999999",
              },
            },
            ".chakra-checkbox__control svg": {
              color: "black",
            },
          }}
        >
          <Text fontSize="12px" color="#727272">
            Other
          </Text>
        </Checkbox>
        {selectedOption === "other" && (
          <Input
            placeholder="Text here"
            value={otherValue}
            onChange={(event) => {
              setOtherValue(event.target.value);
              submitFormValue(event.target.value);
            }}
            _focusVisible={{
              outline: "none",
            }}
            bg="white"
            border="1px solid #999999"
            fontSize="13px"
            _placeholder={{
              color: "#999999",
            }}
          />
        )}
      </VStack>
    </Flex>
  );
};

export const TestimonialCard = ({ name, role, testimony, img }) => {
  return (
    <Flex w="100%" flexDir="column" justify="center" align="center" gap="10px">
      <Flex
        boxSize="120px"
        bg={`url(${img})`}
        bgSize="cover"
        bgPos="center"
        borderRadius="7px"
      />
      <Flex flexDir="column" align="center" gap="10px">
        <Heading fontSize="14px" fontWeight="bold">
          {name}
        </Heading>
        <Text fontSize="12x" color="black" fontWeight="md">
          {role}
        </Text>
        <Text
          textAlign="center"
          fontSize="12px"
          opacity="0.54"
        >{`"${testimony}"`}</Text>
      </Flex>
    </Flex>
  );
};

export const StepProgressCounter = ({ currentStep, totalSteps }) => {
  return (
    <Flex
      position="absolute"
      bottom="5%"
      left="-5%%"
      w="100%"
      justify="center"
      align="center"
      gap="24px"
      color="black"
      fontSize="12px"
      fontWeight="bold"
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <Flex
          key={index}
          boxSize={index === 0 || index === totalSteps - 1 ? "16px" : "8px"}
          bg={
            currentStep === index + 1 || index + 1 < currentStep
              ? "primary"
              : "#D9D9D9"
          }
          borderRadius="100px"
        />
      ))}
    </Flex>
  );
};

export const BookChatBttn = () => {
  return (
    <Button
      position="absolute"
      right="3%"
      bottom="10%"
      rightIcon={<BsChatLeftFill />}
      bg="#08B2E3"
      fontSize="12px"
      borderRadius="200px"
      color="white"
      _focusVisible={{
        bg: "#08B2E3",
      }}
      as={Link}
      href="https://calendly.com/mosaiqlabs/intro-call"
      _hover={{
        textDecor: "none",
      }}
      target="_blank"
    >
      Book a Chat to learn more
    </Button>
  );
};

import { Icon, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsGlobe } from "react-icons/bs";

const ImageWithIconFallback = ({
  iconSrc: imgSrc_legacy, // deprecated santop 2024-05-08 Wed 21:39:05
  imgSrc,
  fallbackIcon = BsGlobe,
  ...rest
}) => {
  if (!imgSrc && imgSrc_legacy) {
    console.warn(
      "ImageWithIconFallback: iconSrc is deprecated, use imgSrc instead"
    );
    imgSrc = imgSrc_legacy;
  }

  const [hasError, setHasError] = useState(!imgSrc);

  if (!imgSrc || hasError) {
    return <Icon as={fallbackIcon} boxSize={4} {...rest} />;
  }

  return (
    <Image
      src={imgSrc}
      onError={() => setHasError(true)}
      borderRadius="full"
      boxSize={4}
      {...rest}
    />
  );
};

export default ImageWithIconFallback;

import {
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import {
  Link as ReactRouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import { auth, signInWithGoogle } from "../../firebase";
import { logUserInFromExtension } from "../Utils/auth";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const Login = () => {
  const [initSignInWithGoogle, setInitSignInWithGoogle] = useState(false);
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [validEmail, setValidEmail] = useState();
  const [unMaskPassword, setUnMaskPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showSignInProgress, setShowSignInProgress] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  useEffect(() => {
    if (user) {
      //Send user to dashboard
      history.replace(`/user${location.search}`);
      logUserInFromExtension();
    }
  }, [user]);

  const updateEmailText = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(e)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
    setSignInEmail(e);
  };

  const startSignIn = () => {
    setShowSignInProgress(true);
    signInWithEmailAndPassword(auth, signInEmail, signInPassword)
      .then((res) => {
        if (res.user) {
          setUser(res.user);
        } else {
          console.log("No user found");
        }
      })
      .catch(() => {
        setInvalidEmail(true);
        setShowSignInProgress(false);
      });
  };

  const handleSignInWithGoogle = () => {
    setInitSignInWithGoogle(true);
    signInWithGoogle()
      .then((res) => {
        if (res.user) {
          setUser(res.user);
        }
      })
      .catch(() => {
        setInitSignInWithGoogle(false);
        toast({
          title: "There was an error",
          description: "Please try again",
          status: "error",
          duration: 4000,
          isClosable: false,
          position: "top-right",
        });
      });
  };

  return (
    <Flex flexDir="column" align="center">
      <Heading fontSize="24px" color="#1D1D1D" fontWeight="500">
        Welcome to {customization.name}
      </Heading>
      <Flex>
        <Button
          leftIcon={
            initSignInWithGoogle ? (
              <Spinner
                size="sm"
                display={initSignInWithGoogle ? "block" : "none"}
              />
            ) : (
              <FcGoogle fontSize="16px" />
            )
          }
          mt={8}
          color="tertiary"
          fontWeight="600"
          fontSize="14px"
          border="1px solid rgba(0, 0, 0, 0.12)"
          onClick={() => {
            handleSignInWithGoogle();
          }}
        >
          <Text>Continue with Google</Text>
        </Button>
      </Flex>
      <Flex w="100%" my={8} flexDir="row" align="center" justify="center">
        <Divider borderColor="rgba(0, 0, 0, 0.12)" />
        <Text mx={4} color="tertiary" fontSize="14px">
          Or
        </Text>
        <Divider borderColor="rgba(0, 0, 0, 0.12)" />
      </Flex>

      <Flex w="100%" gap={2} flexDir="column">
        <FormControl flexDir="column">
          <FormLabel
            mb="0.1rem"
            fontWeight="400"
            color="tertiary"
            fontSize="14px"
          >
            Email
          </FormLabel>
          <Input
            px="0.3rem"
            w="100%"
            fontSize="14px"
            type="email"
            border="1px solid rgba(0, 0, 0, 0.12)"
            value={signInEmail}
            onChange={(e) => {
              setInvalidEmail(false);
              updateEmailText(e.target.value);
            }}
            borderColor={invalidEmail ? "red" : "rgba(0, 0, 0, 0.12)"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="400" color="tertiary" fontSize="14px" m="0">
            Password
          </FormLabel>
          <InputGroup display="block">
            <InputRightElement>
              <AiOutlineEyeInvisible
                cursor="pointer"
                style={{ display: unMaskPassword ? "block" : "none" }}
                onClick={() => {
                  setUnMaskPassword(!unMaskPassword);
                }}
              />
              <AiOutlineEye
                cursor="pointer"
                style={{ display: unMaskPassword ? "none" : "block" }}
                onClick={() => {
                  setUnMaskPassword(!unMaskPassword);
                }}
              />
            </InputRightElement>
            <Input
              px="0.3rem"
              w="100%"
              fontSize="14px"
              type={unMaskPassword ? "text" : "password"}
              autoComplete="current-password"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={signInPassword}
              onChange={(e) => {
                setInvalidEmail(false);
                setSignInPassword(e.target.value);
              }}
              borderColor={invalidEmail ? "red" : "rgba(0, 0, 0, 0.12)"}
            />
            <Text
              fontSize="14px"
              visibility={invalidEmail ? "visible" : "hidden"}
              color="red"
            >
              Invalid email or password
            </Text>
          </InputGroup>
        </FormControl>
        <Link
          as={ReactRouterLink}
          to="/resetpassword"
          fontSize="14px"
          color="link"
        >
          Forgot password?
        </Link>
        <Button
          isDisabled={!(signInPassword.length >= 6 && validEmail)}
          fontSize="14px"
          fontWeight="bold"
          bg="primary"
          onClick={() => {
            startSignIn();
          }}
          position="relative"
        >
          <CircularProgress
            display={showSignInProgress ? "block" : "none"}
            left="3%"
            top="25%"
            position="absolute"
            isIndeterminate
            color="white"
            size="20px"
            mr={6}
          />
          Sign in
        </Button>
      </Flex>

      <Flex mt={8}>
        <Text color="primary" fontSize="14px">
          Don&rsquo;t have an account?{" "}
          <Link color="#007AFF" as={ReactRouterLink} to="/signup">
            Sign up
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};

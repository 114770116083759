import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Input,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { DocumentData, deleteDoc, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { collection, db, doc, onSnapshot } from "../../firebase";
import { getUserId } from "../ProjectPage/SharingView/SharingSettingModal";
import { createModulePermission } from "./multistepAiModuleUtils";

function getModuleDocRef(moduleId: string) {
  return doc(db, `aiModules/${moduleId}`);
}

function getIsPublic(moduleData: DocumentData) {
  return moduleData?.inLibrary;
}

const AddUser = ({ email, setEmail, handleAddUser }) => {
  return (
    <>
      <Text
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Add users to module
      </Text>

      <Input
        width={"md"}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fontWeight="normal"
        fontSize="12px"
        background="white"
        p="8px"
        placeholder="Enter user email address and press 'Add user'"
      />
      <Button
        width={"md"}
        color="#727272"
        fontSize="12px"
        onClick={() => {
          handleAddUser({ email });
        }}
      >
        Add user
      </Button>
    </>
  );
};

const handleDeleteUser = async ({ userId, moduleId }) => {
  const permissionDocRef = doc(
    db,
    `aiModules/${moduleId}/aiModulePermissions/${userId}`
  );
  await deleteDoc(permissionDocRef);
};

const useCurrentPermissions = (moduleId) => {
  const [currentPermissions, setCurrentPermissions] = React.useState<
    DocumentData[]
  >([]);

  /**
   * Subscribe to the permissions collection of the module
   */
  useEffect(() => {
    if (!moduleId) {
      return;
    }
    const permissionsCollectionRef = collection(
      db,
      `aiModules/${moduleId}/aiModulePermissions`
    );
    const unsubscribe = onSnapshot(permissionsCollectionRef, (snapshot) => {
      const permissionDocs = snapshot.docs.map((doc) => doc.data());
      setCurrentPermissions(permissionDocs);
    });
    return () => {
      setCurrentPermissions([]);
      unsubscribe();
    };
  }, [moduleId]);

  console.log("currentPermissions :>> ", currentPermissions);
  return currentPermissions;
};

const PublicOrPrivateSwitch = ({ moduleId, moduleData }) => {
  const toast = useToast();

  const isPublic = getIsPublic(moduleData);
  return (
    <>
      <Text
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Module visibility in the library
      </Text>
      <Flex gap={"10px"} align={"center"}>
        <Text fontSize="10px" fontWeight="500">
          {"Private"}
        </Text>
        <Switch
          size="md"
          background="transparent"
          isChecked={isPublic}
          onChange={() => {
            const moduleDataDocRef = doc(db, `aiModules/${moduleId}`);
            updateDoc(moduleDataDocRef, {
              inLibrary: !isPublic,
            });
            toast({
              description: `Module visibility set to ${
                isPublic ? "private" : "public"
              }`,
              status: "success",
              duration: 1000,
              isClosable: true,
              position: "top-right",
            });
          }}
        />

        <Text fontSize="10px" fontWeight="500">
          {"Public"}
        </Text>
      </Flex>
    </>
  );
};

/**
 * Allows to set the category of the module -- a string use for filtering. Contains a text field and a save button.
 *
 * Initial value is the current category of the module.
 */
const CategoryControl = ({ moduleId, moduleData }) => {
  console.log("moduleData :>> ", JSON.stringify(moduleData));
  const toast = useToast();
  const [category, setCategory] = React.useState<string>(moduleData?.category);

  useEffect(() => {
    setCategory(moduleData?.category);
  }, [moduleData?.category]);

  if (!getIsPublic(moduleData)) {
    return null;
  }

  return (
    <>
      <Text
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Module category
      </Text>
      <Flex gap={"10px"} align={"center"}>
        <Input
          width={"md"}
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          fontWeight="normal"
          fontSize="12px"
          background="white"
          p="8px"
          placeholder="Enter category"
        />
        <Button
          color="#727272"
          fontSize="12px"
          onClick={() => {
            const moduleDataDocRef = doc(db, `aiModules/${moduleId}`);
            updateDoc(moduleDataDocRef, {
              category,
            });
            toast({
              description: `Category set to ${category}`,
              status: "success",
              duration: 1000,
              isClosable: true,
              position: "top-right",
            });
          }}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};

/**
 * Allows to set the top rated status of the module -- a boolean. Contains a switch.
 *
 * Prop used on moduleData is `isTopRated`.
 */
const TopRatedControl = ({ moduleId, moduleData }) => {
  const toast = useToast();
  const isTopRated = moduleData?.isTopRated;

  if (!getIsPublic(moduleData)) {
    return null;
  }

  return (
    <>
      <Text
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Top rated module
      </Text>
      <Flex gap={"10px"} align={"center"}>
        <Text fontSize="10px" fontWeight="500">
          {"No"}
        </Text>
        <Switch
          size="md"
          background="transparent"
          isChecked={isTopRated}
          onChange={() => {
            const moduleDataDocRef = doc(db, `aiModules/${moduleId}`);
            updateDoc(moduleDataDocRef, {
              isTopRated: !isTopRated,
            });
            toast({
              description: `Module set as ${
                isTopRated ? "not top rated" : "top rated"
              }`,
              status: "success",
              duration: 1000,
              isClosable: true,
              position: "top-right",
            });
          }}
        />

        <Text fontSize="10px" fontWeight="500">
          {"Yes"}
        </Text>
      </Flex>
    </>
  );
};

const CurrentUsersList = ({ moduleId, currentPermissions }) => {
  const toast = useToast();
  return (
    <>
      <Text
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Current users
      </Text>
      {currentPermissions.map((permission) => (
        <Flex key={permission.userId} justify="space-between" w={"md"}>
          <Text
            color="black"
            fontSize="12px"
            fontWeight="normal"
            display="flex"
            alignItems="center"
          >
            {permission.userEmail}
            {!permission.userEmail && (
              <Tooltip label="Email not available, because this permission was set using previous permission system.">
                <Text>{permission.userId}</Text>
              </Tooltip>
            )}{" "}
            ({permission.role})
          </Text>
          <Button
            leftIcon={<DeleteIcon />}
            color="#727272"
            fontSize="12px"
            onClick={() => {
              handleDeleteUser({
                userId: permission.userId,
                moduleId,
              }).then(() => {
                toast({
                  description: `${permission.userEmail} is removed`,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  position: "top-right",
                });
              });
            }}
          >
            Remove
          </Button>
        </Flex>
      ))}
    </>
  );
};

const AdminControls = () => {
  const [email, setEmail] = React.useState("");
  const toast = useToast();
  const { moduleId } = useParams<{ moduleId: string }>();
  const [moduleData, setModuleData] = React.useState<DocumentData | undefined>(
    {}
  );
  const currentPermissions = useCurrentPermissions(moduleId);
  const { currentUser } = useAuth();

  /**
   * Subscribe to module data
   */
  useEffect(() => {
    if (!moduleId) {
      return;
    }
    const moduleDataDocRef = getModuleDocRef(moduleId);
    const unsubscribe = onSnapshot(moduleDataDocRef, (doc) => {
      setModuleData(doc.data());
    });
    return () => {
      unsubscribe();
    };
  }, [moduleId]);

  const handleAddUser = async ({ email }) => {
    // check if user already in currentPermissions
    const userAlreadyAdded = currentPermissions.some(
      (permission) => permission.userEmail === email
    );
    if (userAlreadyAdded) {
      toast({
        title: "User already added",
        description: `User ${email} is already added to the module`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    let userId: string;
    try {
      userId = await getUserId(email, true);
      console.log(userId);
    } catch (error) {
      toast({
        title: "User doesn't exist",
        description: "Enter an existing user's email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    await createModulePermission({
      moduleId,
      userId,
      userEmail: email,
      role: "user",
      addedBy: currentUser.uid,
    });
    toast({
      description: `${email} added`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    setEmail("");
  };

  if (!moduleId) {
    return (
      <Text
        color="black"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Administration is only possible for published modules. Publish the
        module first, then open it in edit mode.
      </Text>
    );
  }

  if (!moduleData) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <Text
        color="black"
        fontSize="12px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Admin controls. Note that changes take effect immediately -- you do not
        need to Publish the module.
      </Text>
      <Flex direction={"column"} gap={"12px"}>
        <PublicOrPrivateSwitch moduleId={moduleId} moduleData={moduleData} />
        <CategoryControl moduleId={moduleId} moduleData={moduleData} />
        <TopRatedControl moduleId={moduleId} moduleData={moduleData} />
        <Divider />
        <AddUser
          email={email}
          setEmail={setEmail}
          handleAddUser={handleAddUser}
        />
        <CurrentUsersList
          moduleId={moduleId}
          currentPermissions={currentPermissions}
        />
      </Flex>
    </>
  );
};

export default AdminControls;

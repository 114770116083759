import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React, { memo, useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { FiEdit2, FiEye, FiEyeOff, FiMoreHorizontal } from "react-icons/fi";
import {
  MdModeComment,
  MdOutlineComment,
  MdOutlineContentCopy,
} from "react-icons/md";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import {
  SNIPPET_TYPE_DATA,
  VIEW_IN_SOURCE_ENABLED,
} from "../../../data/SnippetTypeData";
import { addSnippetComment, deleteSnippetComment } from "../../../db/comment";
import { deleteSnippet, updateSnippet } from "../../../db/snippet";
import useInsight from "../../../hooks/useInsight";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { copyInsightCardTextToClipboard } from "../../Utils/insightUtils";
import { firstNWordsFromString } from "../../Utils/shortenText";
import { ManualInsightSourceList } from "../../assistant/ManualInsightSource/ManualInsightSourceList";
import CommentModal from "../CommentModal";
import { DeleteAlert } from "../DeleteAlert";
import ImageViewer from "../ImageViewer";
import InsightCardContent from "../InsightCardContent";
import DefaultAndCompactCard from "./DefaultAndCompactCard";

const customization = getCustomizationData();

const handleSnippetHover = ({ insight, setActiveSnippet }) => {
  if (!insight || !insight.articleUrl) {
    setActiveSnippet(null);
    return;
  }
  setActiveSnippet(insight.uid);
};

const ShowTextAndIcon = ({ icon, text, handler, isDisabled }) => {
  return (
    <Stack
      cursor={isDisabled ? "not-allowed" : "pointer"}
      onClick={!isDisabled ? handler : null}
      direction={"row"}
      align={"center"}
    >
      <Flex align={"center"} justify={"center"} rounded={"full"}>
        <Icon
          color={isDisabled ? "gray.400" : "gray.600"}
          as={icon}
          w={4}
          h={4}
        />
      </Flex>
      <Text color={isDisabled ? "gray.400" : "gray.600"} fontSize="xs">
        {text}
      </Text>
    </Stack>
  );
};

const InsightCard = ({
  insight,
  articleText,
  projectId,
  viewMode,
  isInsightPage,
  isLastItem,
}) => {
  const {
    activeInsight,
    setActiveInsight,
    updateSnippetCurated,
    addArticleToProject,
  } = useInsight(projectId);

  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newNoteText, setNewNoteText] = useState(insight.text);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [currentSnippetForCommentModal, setCurrentSnippetForCommentModal] =
    useState();
  const [activeSnippet, setActiveSnippet] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isReader } = useUserPermission();

  const viewedInsight = useRef();

  useEffect(() => {
    if (viewedInsight.current) {
      setCurrentSnippetForCommentModal(insight);
    }
  }, [insight]);

  const displayActionButtons = (type) => {
    const displayButtons = () => {
      if (insight.type === "image" || insight.type === "manualInsight") {
        return false;
      }
      return true;
    };
    if (type !== "inTextLink") {
      return (
        <>
          {VIEW_IN_SOURCE_ENABLED.includes(type) && (
            <>
              {activeInsight !== insight.uid ? (
                <ShowTextAndIcon
                  text="View in Source"
                  icon={FiEye}
                  handler={() => setActiveInsight(insight.uid)}
                />
              ) : (
                <ShowTextAndIcon
                  text="Close"
                  icon={FiEyeOff}
                  handler={() => setActiveInsight(null)}
                />
              )}
            </>
          )}

          {displayButtons() && (
            <>
              {!insight.curated ? (
                <ShowTextAndIcon
                  text="Save"
                  icon={BsBookmark}
                  handler={() => updateSnippetCurated(insight.uid, true)}
                  isDisabled={isReader()}
                />
              ) : (
                <ShowTextAndIcon
                  text="Unsave"
                  icon={BsBookmarkFill}
                  handler={() => updateSnippetCurated(insight.uid, false)}
                  isDisabled={isReader()}
                />
              )}
            </>
          )}
        </>
      );
    }
  };

  const isInsightNote = insight.type === "articleNote";

  const CardTitle = () => {
    if (insight.title) {
      return insight.title;
    }
    let title = "";
    if (insight.articleTitle) {
      title = insight.articleTitle;
    } else if (insight.articleUrl) {
      title = insight.articleUrl;
    }

    if (title) {
      return `${SNIPPET_TYPE_DATA[insight.type].name} from ${title}`;
    } else {
      return SNIPPET_TYPE_DATA[insight.type].name;
    }
  };

  const displayViewModeContent = () => {
    if (viewMode !== "COMFORT") {
      return (
        <CardBody p={0} className={isLastItem && "aiInsightSampleCard"}>
          <DefaultAndCompactCard
            viewInSourceHandler={setActiveInsight}
            insight={insight}
            activeInsight={activeInsight}
            commentHandler={() => {
              setCurrentSnippetForCommentModal(insight);
              setShowCommentModal(true);
              viewedInsight.current = insight.uid;
            }}
            saveHandler={updateSnippetCurated}
            mode={viewMode}
            relatedArticleHandler={addArticleToProject}
            isExpanded={isExpanded}
            isInsightPage={isInsightPage}
            setIsExpanded={setIsExpanded}
            updateSnippetCurated={updateSnippetCurated}
            fullText={articleText}
            insightId={insight.uid}
            projectId={projectId}
          />
        </CardBody>
      );
    }
    return (
      <CardBody>
        <Grid templateColumns="repeat(16, 1fr)" gap={2}>
          <GridItem colSpan={1}>
            <Avatar
              bg={SNIPPET_TYPE_DATA[insight.type]?.color}
              color="black"
              icon={
                <Icon
                  as={SNIPPET_TYPE_DATA[insight.type].icon}
                  fontSize="15px"
                />
              }
              zIndex={insight.sourceArticles?.length + 1}
              w="20px"
              h="20px"
            />
            {insight.type === "assistantInsight" && (
              <ManualInsightSourceList
                iconsLists={insight.sourceArticles}
                iconsToRender={3}
                iconsGaps="-33%"
                counterGap={"-35%"}
              />
            )}
          </GridItem>
          <GridItem colSpan={15}>
            <Flex>
              <Box flex="1">
                <Link
                  isExternal
                  href={
                    customization.documentMenu.hideOpenInBrowser
                      ? null
                      : insight.articleUrl
                  }
                  _hover={
                    activeSnippet === insight.uid && {
                      textDecoration: "underline",
                      cursor: "pointer",
                    }
                  }
                  cursor="auto"
                >
                  <Text
                    color="rgba(0, 0, 0, 0.54)"
                    fontSize="xs"
                    onMouseEnter={() =>
                      handleSnippetHover({ insight, setActiveSnippet })
                    }
                    onMouseLeave={() =>
                      handleSnippetHover({ insight: null, setActiveSnippet })
                    }
                  >
                    <CardTitle />
                    {activeSnippet === insight.uid && (
                      <chakra.span>
                        <Icon mb={-1} as={BiLinkExternal} boxSize={4} />
                      </chakra.span>
                    )}
                  </Text>
                </Link>
                <Box maxH="486px" my={4} overflowY="scroll">
                  <Box
                    display={
                      !isInsightNote || !showEditModal ? "block" : "none"
                    }
                  >
                    {insight.type === "image" && (
                      <Image
                        my={4}
                        src={insight.imageUrl}
                        fallbackSrc="https://via.placeholder.com/200"
                        maxW="80%"
                        maxH="150px"
                        onClick={onOpen}
                        cursor="pointer"
                      />
                    )}
                    <InsightCardContent
                      type={insight.type}
                      activeHighlight={activeInsight}
                      text={insight.text}
                      bold={insight.bold}
                      insightId={insight.uid}
                      fullText={articleText}
                      projectId={projectId}
                    />
                  </Box>

                  <Flex
                    display={isInsightNote && showEditModal ? "flex" : "none"}
                    flexDir="column"
                  >
                    <Input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={true}
                      fontSize="14px"
                      p="4px"
                      h="fit-content"
                      type="text"
                      value={newNoteText}
                      onChange={(e) => setNewNoteText(e.target.value)}
                    />
                    <Flex flexDir="row" mt="1rem" gap={2}>
                      <Button
                        fontSize="13px"
                        h="fit-content"
                        p="8px 8px"
                        bg="#171923"
                        color="white"
                        border="1px solid transparent"
                        onClick={() => {
                          updateSnippet({
                            projectId: projectId,
                            snippetId: insight.uid,
                            text: newNoteText,
                          });
                          setShowEditModal(false);
                        }}
                      >
                        Save Edit
                      </Button>
                      <Button
                        fontSize="13px"
                        h="fit-content"
                        p="8px"
                        border="1px solid #171923"
                        color="#171923"
                        onClick={() => {
                          setShowEditModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Box display={isInsightNote ? "block" : "none"}>
                  <Menu>
                    <MenuButton
                      mt="-2"
                      as={IconButton}
                      aria-label="Options"
                      icon={<FiMoreHorizontal color="black" fontSize="20" />}
                      variant="solid"
                      color="black"
                      pr={3}
                    />
                    <MenuList rounded="sm" bg="primary" minW="40px">
                      <MenuItem
                        onClick={() => {
                          setShowEditModal(true);
                        }}
                        bg="primary"
                        color="white"
                        icon={<FiEdit2 />}
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        bg="primary"
                        color="white"
                        icon={<AiOutlineDelete />}
                        onClick={() => setShowDeleteAlert(true)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </Flex>
            <Flex minWidth="max-content" alignItems="center" gap="8">
              <Box>
                <ShowTextAndIcon
                  handler={() => {
                    setCurrentSnippetForCommentModal(insight);
                    setShowCommentModal(true);
                    viewedInsight.current = insight.uid;
                  }}
                  text="Comments"
                  icon={
                    insight?.comments?.length > 0
                      ? MdModeComment
                      : MdOutlineComment
                  }
                />
              </Box>
              <Spacer />
              <Flex gap="4">
                <ShowTextAndIcon
                  text="Copy"
                  icon={MdOutlineContentCopy}
                  handler={() => {
                    copyInsightCardTextToClipboard(insight.text);
                  }}
                />
                {displayActionButtons(insight.type)}
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    );
  };

  return (
    <>
      <CommentModal
        close={() => {
          setShowCommentModal(false);
        }}
        isOpen={showCommentModal}
        content={currentSnippetForCommentModal}
        createFunc={addSnippetComment}
        deleteFunc={deleteSnippetComment}
        rootId={projectId}
        commentTitle={`${firstNWordsFromString(
          currentSnippetForCommentModal?.text,
          5
        )}...`}
      />
      <Card shadow="sm" mb={2} color="primary">
        {displayViewModeContent()}
        <DeleteAlert
          action={() => {
            deleteSnippet(projectId, insight.uid);
            setShowDeleteAlert(false);
          }}
          isOpen={showDeleteAlert}
          onClose={() => setShowDeleteAlert(false)}
          title="Delete Note"
          body="Are you sure you want to delete this article's note?"
        />
      </Card>
      <ImageViewer
        imageUrl={insight.imageUrl}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default memo(InsightCard);

import {
  Box,
  Flex,
  Heading,
  Progress,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineToken } from "react-icons/md";
import { MONTHLY_AI_CREDITS } from "../../../data/constants";
import { formatDateStripe } from "../../Utils/dateutils";
import { subscriptionIsCancelled } from "../../Utils/stripe";

const ToolTipCard = ({ values }) => {
  return (
    <Flex gap={2} flexDir="column" bg="#D9D9D9">
      {values.map((value, index) => (
        <Flex key={index}>
          <Text color="#6d6d6d">{value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
export const AICreditsUsageOverview = ({
  maxCredits,
  remainingCredits,
  isLoading,
  refreshCreditsTs,
  userCurrentSubscription,
}) => {
  const AICreditPercentUsed = (remainingCredits / maxCredits) * 100;
  const AICreditPercentRemaining = 100 - AICreditPercentUsed;
  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex w="100%" h="100%" gap={2}>
        <Flex>
          <MdOutlineToken fontSize="38px" style={{ marginTop: "-3px" }} />
          <Box>
            <Tooltip
              bg="#D9D9D9"
              placement="top"
              label={
                <ToolTipCard
                  values={[
                    "Use AI credits to organise, manage, and analyse information to produce fast deliverables.",
                    "1 credit = 1 AI response",
                    "1 credit = 500 pages of documents processed ",
                  ]}
                />
              }
            >
              <Box>
                <AiOutlineInfoCircle fontSize="12px" color="primary" />
              </Box>
            </Tooltip>
          </Box>
        </Flex>

        <Flex flexDir="column" h="100%" justify="space-between">
          <Flex flexDir="column">
            <Flex mb={2} alignItems="left" flexDir="column" gap={2}>
              <Heading fontSize="13px" fontWeight="medium">
                Monthly AI credits
              </Heading>
              <Heading
                gap={1}
                display="flex"
                alignItems="center"
                fontSize="24px"
                fontWeight="medium"
              >
                {remainingCredits}
                <MdOutlineToken fontSize="12px" style={{ marginTop: "-3px" }} />
              </Heading>
            </Flex>
            <Tooltip
              bg="#D9D9D9"
              label={
                <ToolTipCard
                  values={[
                    `Used ${
                      maxCredits - remainingCredits
                    } out of ${maxCredits} (${AICreditPercentRemaining}%
                    ) of your Monthly AI credits`,
                  ]}
                />
              }
              placement="top"
            >
              <Progress
                mt="2"
                colorScheme="aiAccent"
                h="16px"
                borderRadius="5px"
                bg="#e3e3e3"
                value={AICreditPercentUsed}
              />
            </Tooltip>

            <Text fontSize="12px" mt={4}>
              Monthly AI credits remaining{" "}
              <Text color="#808080" display="inline-block">
                ({AICreditPercentUsed}%)
              </Text>
            </Text>
          </Flex>
          <Flex mt="4" gap={6} flexDir="column">
            <Text color="#808080" fontSize="12px" fontWeight="bold">
              {userCurrentSubscription
                ? subscriptionIsCancelled(userCurrentSubscription)
                  ? `Your subscription is cancelled and AI Credits will not be replenished at the end of the month. Upgrade to Premium to get ${MONTHLY_AI_CREDITS} AI credits every month.`
                  : `Monthly AI credits replenish to ${MONTHLY_AI_CREDITS} every month. Your next
              replenishment is on ${formatDateStripe(refreshCreditsTs * 1000)}.`
                : `Upgrade to Premium to get ${MONTHLY_AI_CREDITS} AI credits every month.`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Skeleton>
  );
};

import { Button } from "@chakra-ui/react";
import { useArticle } from "../../../Context/ArticleContext";
import { ARTICLE_TYPE_DATA } from "../../../data/articleTypeData";
import { deleteArticle } from "../../../db/article";
import { updateArticleTypeParams } from "../../ProjectPage/TableComponents/HeaderSwitches";

const order = {
  html: 1,
  pdf: 2,
  docx: 3,
  pptx: 4,
  table: 5,
  supportingLink: 6,
};

export const sortTableArticles = (articles, sortBy) => {
  let sortedData = [...articles];

  switch (sortBy) {
    case "name":
      sortedData.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case "name-desc":
      sortedData.sort((a, b) => b.title.localeCompare(a.title));
      break;
    case "type":
      sortedData.sort((a, b) => order[a.type] - order[b.type]);
      break;
    case "type-desc":
      sortedData.sort((a, b) => order[b.type] - order[a.type]);
      break;
    case "source":
      sortedData.sort((a, b) => a.source.localeCompare(b.source));
      break;
    case "source-desc":
      sortedData.sort((a, b) => b.source.localeCompare(a.source));
      break;
    case "date added":
      sortedData.sort((a, b) => a.createdAt - b.createdAt);
      break;
    case "date added-desc":
      sortedData.sort((a, b) => b.createdAt - a.createdAt);
      break;
    case "length":
      sortedData.sort((a, b) => a.length - b.length);
      break;
    case "length-desc":
      sortedData.sort((a, b) => b.length - a.length);
      break;
    default:
      return articles;
  }

  sortedData.sort((a, b) => {
    if (a.starred && !b.starred) {
      return -1;
    } else if (!a.starred && b.starred) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedData;
};

export const handleDeleteMultipleArticle = async (projectId, articleIds) => {
  articleIds.forEach((articleId) => {
    deleteArticle(projectId, articleId);
  });
};

export const handleDownload = (dataList) => {
  console.log(dataList);
};

export const MoreActionMenuItem = ({ text, icon, id, action }) => {
  const { selectedArticlesData } = useArticle();

  const handleDownloadAllArticles = () => {
    handleDownload(selectedArticlesData);
  };
  const Icon = icon;
  return (
    <Button
      p="0"
      fontSize="12px"
      color="#707070"
      w="100%"
      justifyContent="start"
      leftIcon={<Icon fontSize="16px" />}
      fontWeight="600"
      px="4"
      py="1"
      h="fit-content"
      pr={4}
      _hover={{
        bg: "#f7f7f7",
      }}
      onClick={() => {
        if (id === "download") {
          handleDownloadAllArticles();
        } else if (id === "delete") {
          action();
        }
      }}
    >
      {text}
    </Button>
  );
};
// export const getArticleTypeText = (type) => {
//   switch (type) {
//     case "html":
//       return "Web Pages";
//     case "pdf":
//       return "PDFs";
//     case "docx":
//       return "Docs";
//     case "table":
//       return "Tables";
//     case "supportingLink":
//       return "Supporting Links";
//     case "xlsx":
//       return "Excel";
//     case "pptx":
//       return "Slides";
//     default:
//       return "Unknown";
//   }
// };

export const updateSelectedTypedArr = (
  type,
  selectedArticleTypes,
  routerHistory
) => {
  if (selectedArticleTypes.includes(type)) {
    updateArticleTypeParams(type, false, routerHistory);
  } else {
    updateArticleTypeParams(type, true, routerHistory);
  }
};

export const getArticleTypes = (allUnfilteredArticles) => {
  let articleTypes = [];
  allUnfilteredArticles?.reduce((accumulator, article) => {
    const { type } = article;

    if (!accumulator[type]) {
      accumulator[type] = {
        count: 1,
        id: type,
        type: ARTICLE_TYPE_DATA[type].uiNamePlural,
      };
    } else {
      accumulator[type].count += 1;
    }

    articleTypes[type] = accumulator[type];
    return accumulator;
  }, {});

  return articleTypes;
};

export const calculateAndSetVisibleItems = ({
  menuContainerRef,
  sortedArticleType,
  setVisibleItems,
  setHiddenItems,
}) => {
  const containerWidth = menuContainerRef?.current.offsetWidth;

  let totalWidth = 0;
  const newVisibleItems = [];

  sortedArticleType?.forEach((type) => {
    const estimatedWidth = 95;
    if (totalWidth + estimatedWidth * 2 <= containerWidth) {
      newVisibleItems.push(type);
      totalWidth += estimatedWidth;
    }
  });
  if (newVisibleItems.length < sortedArticleType.length) {
    const allHiddenItems =
      newVisibleItems.length > 0
        ? sortedArticleType.slice(newVisibleItems.length)
        : sortedArticleType;
    setHiddenItems(allHiddenItems);
  }
  setVisibleItems(newVisibleItems);
};

import { addDoc, auth, collection, db } from "../firebase.js";

export const saveRequestModule = async (data) => {
  try {
    const moduleRequestCollectionRef = collection(
      db,
      "aiModuleSupportRequests"
    );
    const docRef = await addDoc(moduleRequestCollectionRef, {
      displayName: auth.currentUser.displayName,
      email: auth.currentUser.email,
      ...data,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoMdDownload } from "react-icons/io";
import {
  MdOutlineChatBubbleOutline,
  MdOutlineEdit,
  MdSwapHoriz,
} from "react-icons/md";
import { useAuth } from "../../../Auth/AuthProvider";
import { useArticle } from "../../../Context/ArticleContext";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import { ACTIONS, ARTICLE_TYPE_DATA } from "../../../data/articleTypeData";
import { deleteArticle } from "../../../db/article";
import { getStorageDownloadUrl } from "../../../services/firebaseStorageSrv";
import { DeleteAlert } from "../../Elements/DeleteAlert";
import SwitchAlert from "../../Elements/SwitchAlert";
import { OpenInBrowserIcon } from "../../Icons/ExternalLinkIconComponent";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { handleDownload } from "../../Utils/viewerUtils";
import { MenuItemBttn } from "./TableComponents";
import { TableRowCopyToProjectBttn } from "./TableRowCopyToProjectBttn";

const customization = getCustomizationData();

export const TableRowMoreActions = ({
  projectId,
  articleData,
  setShowRename,
  showCommentModalAndUpdateProjectTitle,
}) => {
  const { isReader } = useUserPermission();
  const { isPowerUser } = useAuth();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showSwitchAlert, setShowSwitchAlert] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [newType, setNewType] = useState();
  const { setShowArticleCopyMenu } = useArticle();

  const handleDeleteArticle = async () => {
    deleteArticle(projectId, articleData.uid);
  };

  const { type: articleType, url: articleUrl, pathToPdf } = articleData;

  const getDownloadUrl = async () => {
    let url;
    if (articleType === "voice") {
      url = await getStorageDownloadUrl(pathToPdf);
    } else {
      if (pathToPdf) {
        url = await getStorageDownloadUrl(pathToPdf);
      } else {
        url = articleUrl;
      }
    }
    return url;
  };

  const downloadMenuItem = {
    label: ARTICLE_TYPE_DATA[articleType].downloadBttnTxt || "Download",
    icon: <Icon as={IoMdDownload} fontSize="12px" />,
    onClick: async () => {
      await handleDownload(await getDownloadUrl(), articleData.title);
    },
    isDisabled: false,
  };

  let PROJECT_ARTICLE_MENU = [
    {
      label: "Copy to Project",
      isCustom: true,
      render: <TableRowCopyToProjectBttn articleData={articleData} />,
    },
    {
      label: "Comments",
      icon: <MdOutlineChatBubbleOutline fontSize="12px" />,
      onClick: () => {
        showCommentModalAndUpdateProjectTitle();
      },
      isDisabled: isReader(),
    },
    {
      label: "Open In Browser",
      icon: <OpenInBrowserIcon fontSize="12px" />,
      onClick: () => {
        window.open(articleData.url, "_blank");
      },
      isDisabled: isReader(),
      hide:
        (customization.documentMenu.hideOpenInBrowser && !isPowerUser) ||
        !ARTICLE_TYPE_DATA[articleData.type].actions.includes(
          ACTIONS.GO_TO_LINK
        ),
    },
    {
      label: "Rename",
      icon: <MdOutlineEdit fontSize="12px" />,
      onClick: () => {
        setShowRename(true);
      },
      isDisabled: isReader(),
    },
    {
      label: "Mark as Link",
      icon: <MdSwapHoriz fontSize="12px" />,
      onClick: () => {
        setShowSwitchAlert(true);
        setNewType("supportingLink");
      },
      isDisabled: isReader(),
      fontWeight: "600",
      id: "markAsLink",
      hide: customization.documentMenu.hideMarkAsLink,
    },
    {
      label: "Delete",
      icon: <AiOutlineDelete fontSize="12px" />,
      onClick: () => {
        setShowDeleteAlert(true);
      },
      isDisabled: isReader(),
    },
  ];

  if (
    ARTICLE_TYPE_DATA[articleData.type].actions.includes(ACTIONS.DOWNLOAD) &
    (articleData.source === "local")
  ) {
    // add download menu item after the "Open in Browser" menu item
    PROJECT_ARTICLE_MENU.splice(2, 0, downloadMenuItem);
  }

  // remove menu items if hide property is true
  PROJECT_ARTICLE_MENU = PROJECT_ARTICLE_MENU.filter((item) => !item.hide);

  return (
    <>
      {" "}
      <Menu placement="right-start" bg="white">
        {({ isOpen }) => (
          <>
            <Tooltip
              label="More actions"
              hasArrow
              placement="right"
              isOpen={showTooltip}
            >
              <MenuButton
                p="0"
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreHorizontal fontSize="16px" />}
                variant="solid"
                color="black"
                opacity={0.56}
                cursor="pointer"
                height="auto"
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              />
            </Tooltip>
            {isOpen && (
              <Portal>
                <MenuList
                  rounded="sm"
                  minW="40px"
                  fontSize="10px"
                  bg="white"
                  ml="-40px"
                  borderRadius={4}
                  border={0}
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.04)"
                  py={3}
                >
                  {PROJECT_ARTICLE_MENU.map((item, index) => {
                    if (
                      item.id === "markAsLink" &&
                      ARTICLE_TYPE_DATA[articleData.type].hideMarkAsLink
                    ) {
                      return null;
                    }
                    if (item.isCustom) {
                      return item.render;
                    }
                    return (
                      <MenuItemBttn
                        data-testid={item.label}
                        onClick={item.onClick}
                        bg="white"
                        color="black"
                        opacity={0.56}
                        icon={item.icon}
                        _hover={{
                          bg: "#f7f7f7",
                        }}
                        isDisabled={item.isDisabled}
                        key={index}
                        onMouseOut={() => {
                          if (item.label !== "Copy to Project") {
                            setShowArticleCopyMenu(false);
                          }
                        }}
                        fontWeight="600"
                      >
                        {item.label}
                      </MenuItemBttn>
                    );
                  })}
                </MenuList>
              </Portal>
            )}
          </>
        )}
      </Menu>
      <DeleteAlert
        action={() => {
          handleDeleteArticle();
          // setShowMore(false);
          setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
        title="Delete Article"
        body="Are you sure you want to delete this article?"
      />
      <SwitchAlert
        isOpen={showSwitchAlert}
        onClose={() => setShowSwitchAlert(false)}
        articleId={articleData.uid}
        projectId={projectId}
        newType={newType}
      />
    </>
  );
};

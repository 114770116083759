import {
  Box,
  Button,
  HStack,
  Icon,
  // Input,
  // InputGroup,
  // InputRightElement,
  // Spacer,
  Stack,
  Text,
  // Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { MdOutlineArrowBack } from "react-icons/md";
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../Auth/AuthProvider";
import { LoadingAuth } from "../Auth/LoadingAuth";
import ContentModal from "../Components/ContentModal";
import { default as ArticleCommentModal } from "../Components/Elements/CommentModal";
import AddIconComponent from "../Components/Icons/AddIconComponent";
import Layout, { LAYOUT_PROPS } from "../Components/Layout";
import { LimitationsWarningModal } from "../Components/Limitations/LimitationsWarningModal";
import IndexingStatus from "../Components/PersistentStatus/IndexingStatus";
import ProjectSettings from "../Components/ProjectPage/ProjectSettings";
import { SharingView } from "../Components/ProjectPage/SharingView/SharingView";
import { AllArticleTable } from "../Components/ProjectPage/TableComponents/AllArticleTable";
import { HeaderSwitches } from "../Components/ProjectPage/TableComponents/HeaderSwitches";
import { ProjectPageTour } from "../Components/Tour/ProjectPageTour";
import { sortArticles } from "../Components/Utils/articleUtil";
import { useArticle } from "../Context/ArticleContext";
import { useAssistantContext } from "../Context/AssistantContext";
import { ContentContextProvider } from "../Context/ContentContext";
import { useProjectContext } from "../Context/ProjectContext";
import {
  UserPermissions,
  useUserPermission,
} from "../Context/UserPermissionContext";
import { PDF_PAGE_LIMIT_MSG } from "../data/constants";
import { addArticleComment, deleteArticleComment } from "../db/comment";
import { auth } from "../firebase";
import useFilteredAndSortedArticles from "../hooks/useFilteredAndSortedArticles";
import InsertedCuratedContentPage from "./InsertedCuratedContentPage";
import { NoArticlesPlaceholder } from "./NoArticlesPlaceholder";

function AddContentButton({ setShowUploadModal }) {
  const { isReader } = useUserPermission();
  return (
    <Button
      leftIcon={<Icon as={AddIconComponent} />}
      onClick={() => setShowUploadModal(true)}
      bg="white"
      rounded="lg"
      borderColor="white"
      variant="outline"
      color="primary"
      id="addContent"
      fontSize="12px"
      size="sm"
      minWidth="128px"
      isDisabled={isReader()}
    >
      Add content
    </Button>
  );
}

const ProjectPage = () => {
  const [, loading] = useAuthState(auth);
  const { isPowerUser, isPremium } = useAuth();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const viewedContent = useRef(null);
  const commentType = useRef(null);
  const [showArticleCommentModal, setShowArticleCommentModal] = useState(false);
  const [currentContentForCommentModal, setCurrentArticleForCommentModal] =
    useState();
  const [searchKeyword] = useState("");
  const { assistantIsOpen, setShowUploadModal, showUploadModal } =
    useAssistantContext();
  const { currentProjectData: projectData } = useProjectContext();
  const { userPermission, isEditor } = useUserPermission();
  const childRef = useRef(null);
  const routerHistory = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const showInsight = urlParams.get("showInsight") === "true";

  const { projectId: currentProjectId } = useParams();
  const { allUnfilteredArticles } = useArticle();

  useEffect(() => {
    // This is for the case when someone tries to open a project by URL,
    // but the user has no permission to view the project
    if (!loading && userPermission === UserPermissions.NONE) {
      routerHistory.push({
        pathname: "/user",
      });
    }
  }, [userPermission]);

  useEffect(() => {
    const _setArticles = () => {
      let filteredArticles = allUnfilteredArticles.filter(
        (article) => !article.isHidden
      );
      const sortedArticles = sortArticles(filteredArticles);
      setArticles(sortedArticles);
      setFilteredArticles(sortedArticles);

      // set current article for comment modal
      if (viewedContent.current) {
        if (commentType.current === "ARTICLE") {
          const article = articles.find(
            (article) => article.uid === viewedContent.current
          );
          setCurrentArticleForCommentModal(article);
        }
      }
    };
    _setArticles();
  }, [allUnfilteredArticles]);

  const filteredAndSortedArticles = useFilteredAndSortedArticles(
    articles,
    searchKeyword
  );

  const { isOpen: isManualInsightModalOpen, onClose: closeManualInsightModal } =
    useDisclosure();

  // avoid showing product tour if user arrived on the project from a shared link invite
  const isNotSharedLink =
    new URLSearchParams(location.search).get("source") === "sharing";

  if (userPermission === UserPermissions.LOADING) {
    return <LoadingAuth />;
  }

  if (!projectData) {
    return <LoadingAuth />;
  }

  return (
    <Layout
      rest={
        <>
          <AddContentButton setShowUploadModal={setShowUploadModal} />
          <SharingView projectData={projectData} isEditor={isEditor} />
        </>
      }
    >
      <Box
        pt={6}
        flex={1}
        // h="full"

        className="webkit-scrollbar-display-none"
        height="full"
        pr={assistantIsOpen ? 0 : 8}
      >
        {filteredArticles &&
          !isNotSharedLink &&
          !showInsight &&
          !assistantIsOpen && <ProjectPageTour />}
        <ArticleCommentModal
          close={() => {
            setShowArticleCommentModal(false);
          }}
          isOpen={showArticleCommentModal}
          content={currentContentForCommentModal}
          createFunc={addArticleComment}
          deleteFunc={deleteArticleComment}
          rootId={currentProjectId}
          commentTitle={currentContentForCommentModal?.title}
        />
        <LimitationsWarningModal
          title={
            isPremium
              ? PDF_PAGE_LIMIT_MSG["premium"].title
              : PDF_PAGE_LIMIT_MSG["free"].title
          }
          subHeading="What now?"
          bodyText={
            isPremium
              ? PDF_PAGE_LIMIT_MSG["premium"].body
              : PDF_PAGE_LIMIT_MSG["free"].body
          }
          primaryBttnText="Upgrade to Premium"
          primaryBttnLink="user/profile/plans"
        />

        <ContentContextProvider>
          <ContentModal
            onClose={() => setShowUploadModal(false)}
            isOpen={showUploadModal}
            projectName={projectData?.name}
          />
        </ContentContextProvider>

        <Box w="100%" maxW={LAYOUT_PROPS.maxWidth}>
          <Stack spacing={2} direction="row" align="center">
            <HStack gap={4}>
              <Link to="/user">
                <MdOutlineArrowBack color="primary" fontSize="18px" />
              </Link>
              <Text
                className="projectTitle"
                fontWeight="500"
                color="primary"
                noOfLines={1}
              >
                {projectData.name}
              </Text>
              {isPowerUser && <ProjectSettings projectData={projectData} />}
            </HStack>
          </Stack>
          <HeaderSwitches filteredArticles={articles} />
          <AllArticleTable
            filteredArticles={filteredAndSortedArticles}
            display={!showInsight ? "grid" : "none"}
          />
          {allUnfilteredArticles.length === 0 && !showInsight && (
            <NoArticlesPlaceholder />
          )}
          <Box p={2} id="allProjectSection">
            {showInsight && (
              <InsertedCuratedContentPage
                ref={childRef}
                display={showInsight ? "grid" : "none"}
                isManualInsightModalOpen={isManualInsightModalOpen}
                closeManualInsightModal={closeManualInsightModal}
              />
            )}
          </Box>
        </Box>
        <IndexingStatus articles={articles} />
      </Box>
    </Layout>
  );
};

export default ProjectPage;

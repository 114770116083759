import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
} from "@chakra-ui/react";
import { deleteSubscription } from "../../../services/StripeSvc";
import useNotification from "../../../hooks/useNotification";

export const CancelPlanModal = ({ ...props }) => {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const { notify } = useNotification();
  const showNotification = (title, description, status) => {
    notify({
      title: title,
      description: description,
      status: status,
    });
  };
  const cancelSubscription = async () => {
    setIsCanceling(true);
    const response = await deleteSubscription(props.subscriptionId);
    if (response) {
      showNotification(
        "Subscription canceled.",
        "We've deleted your subscription for you.",
        "success"
      );
      props.onClose();
    } else {
      showNotification(
        "Error canceling subscription.",
        "Please try again later.",
        "error"
      );
    }
    setIsCanceling(false);
  };
  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="12px" fontWeight="bold">
            Are you sure you want to cancel your {props.planName} plan?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="12px" fontWeight="normal">
            Please note that once cancelled your subscription will go back to
            Starter in the end of the billing period.
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="start">
          <Button
            border="1px solid 
            #C2C2C2"
            colorScheme="blue"
            mr={3}
            onClick={props.onClose}
            color="#212121"
            fontSize="12px"
            fontWeight="normal"
          >
            Cancel
          </Button>
          <Button
            border="1px solid 
            #C2C2C2"
            colorScheme="blue"
            mr={3}
            color="#212121"
            fontSize="12px"
            fontWeight="normal"
            isLoading={isCanceling}
            onClick={cancelSubscription}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import {
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { FiPlay } from "react-icons/fi";
import customModuleImg from "../../assets/customModuleImg.png";
import HowItWorksChart from "../../assets/howItWorksChart.png";

import { MdCircle } from "react-icons/md";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useCustomAiModules } from "../../Context/CustomAiModuleContext";
import { updateOnboardingCompleted } from "../../db/projectTour";
import { AiModuleBox } from "../AiModules/AiModuleBox";
import { addUrlParam } from "../Utils/urlUtils";
import { CustomForm, TestimonialCard } from "./OnboardingComponents";

export const OnboardingFlowWrapper = ({
  heading,
  children,
  intro,
  width,
  showDemoBtt,
  subParagraph,
  onOpen,
  height,
}) => {
  return (
    <Flex
      flexDir="column"
      w="100%"
      align="center"
      boxSizing="border-box"
      maxH={height || "400px"}
      minH={height || "400px"}
    >
      <Flex flexDir="column" gap="16px" align="center" boxSizing="border-box">
        <Text
          textTransform="uppercase"
          letterSpacing="3%"
          fontSize="12px"
          fontWeight="light"
        >
          {heading}
        </Text>
        <Heading fontSize="30px" fontWeight="bold">
          {intro}
        </Heading>
        {subParagraph && (
          <Heading fontWeight="bold" fontSize="13px">
            {subParagraph}
          </Heading>
        )}
        {showDemoBtt && (
          <Button
            color="#08B2E3"
            fontWeight="900"
            rightIcon={<FiPlay />}
            onClick={onOpen}
          >
            Watch demo
          </Button>
        )}
      </Flex>
      <Flex w={width || "600px"} m="0 auto">
        {children}
      </Flex>
    </Flex>
  );
};

export const Welcome = ({ setAllowForwardNav }) => {
  const { currentUser } = useAuth();
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  return (
    <OnboardingFlowWrapper
      height="auto"
      heading="lets get started!"
      intro={`Nice to meet you ${currentUser?.displayName}, welcome to MosaiQ!`}
    />
  );
};

export const UsingMosaiQ = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  const options = [
    { value: "professional", label: "Professional use" },
    { value: "personal", label: "Personal use" },
    { value: "playing_around", label: "Not sure, just playing around" },
  ];
  return (
    <OnboardingFlowWrapper
      heading="mosaiq labs"
      intro="How will you use MosaiQ?"
    >
      <Flex pt="87px">
        <CustomForm
          title="What do you want to use the product for? "
          options={options}
          showOther={false}
          submitFormValue={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              setAllowForwardNav(false);
            }
          }}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const Industry = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  const options = [
    {
      value: "finance",
      label: "Finance",
    },
    {
      value: "consulting",
      label: "Consulting",
    },
    {
      value: "prandcomms",
      label: "Pr & Comms",
    },
    { value: "marketing", label: "Marketing" },
    { value: "legal", label: "Legal" },
  ];
  return (
    <OnboardingFlowWrapper heading="Hello!" intro="What industry are you in?">
      <Flex w="100%" mt="39px">
        <CustomForm
          title="If you had a magic wand, what would you like AI to help you with in your work?"
          options={options}
          showOther={true}
          submitFormValue={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              console.log("hfhf");
              setAllowForwardNav(false);
            }
          }}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const HowCanWeHelp = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  const options = [
    {
      value: "organize",
      label: "Organize information and prepare deliverables faster",
    },
    {
      value: "useInfo",
      label:
        "Use all the information out there effectively to improve work quality",
    },
    {
      value: "focus",
      label:
        "Focus on the stimulating aspects of my work such as critical thinking, drivingrevenue, while AI helps me take care of the redundant, time consuming tasks",
    },
    { value: "useAi", label: "Use AI to find information faster" },
    { value: "useServerClient", label: "Use AI to serve clients faster" },
  ];
  return (
    <OnboardingFlowWrapper heading="MOsaiq LABS" intro="How can we help?">
      <Flex w="100%" mt="39px">
        <CustomForm
          title="If you had a magic wand, what would you like AI to help you with in your work?"
          options={options}
          showOther={true}
          submitFormValue={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              console.log("hfhf");
              setAllowForwardNav(false);
            }
          }}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const Testimonials = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const testimonial = [
    {
      name: "James Pattinson",
      img: "https://randomuser.me/api/portraits/men/43.jpg",
      role: "Consultant, MONEY INC",
      testimony:
        "“Vestibulum, cum nam non amet consectetur morbi aenean condimentum eget. Ultricies integer nunc neque accumsan laoreet. Viverra nibh ultrices.”",
    },
    {
      name: "Liza Nilsson",
      img: "https://randomuser.me/api/portraits/women/10.jpg",

      role: "Process Manager, BIZZ",
      testimony:
        "“Vestibulum, cum nam non amet consectetur morbi aenean condimentum eget. Ultricies integer nunc neque accumsan laoreet. Viverra nibh ultrices.”",
    },
    {
      name: "Tevor Mitchell",
      img: "https://randomuser.me/api/portraits/men/12.jpg",
      role: "Senior Anaylst, MONEY",
      testimony:
        "“Vestibulum, cum nam non amet consectetur morbi aenean condimentum eget. Ultricies integer nunc neque accumsan laoreet. Viverra nibh ultrices.”",
    },
  ];

  return (
    <OnboardingFlowWrapper
      heading="Testimonials"
      intro="How others using MosaiQ"
      width="900x"
    >
      <Flex w="100%" mt="39px" gap="38px">
        {testimonial.map((item, index) => (
          <TestimonialCard
            key={index}
            name={item.name}
            img={item.img}
            role={item.role}
            testimony={item.testimony}
          />
        ))}
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const AIAssistantRelevance = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  const options = [
    {
      value: "discoverig",
      label: "I am discovering and playing around for now",
    },
    {
      value: "notUrgent",
      label: "Not urgent but important, I need to understand better",
    },
    {
      value: "strategic",
      label: "It's a strategic priority",
    },
  ];
  return (
    <OnboardingFlowWrapper
      heading="Your Ai Assistant"
      intro="How urgent is it for you to adopt AI?  "
    >
      <Flex w="100%" mt="39px">
        <CustomForm
          title="Let’s get into the magic. You can select one or more options"
          options={options}
          showOther={true}
          submitFormValue={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              console.log("hfhf");
              setAllowForwardNav(false);
            }
          }}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const OverviewOfMosaiQ = ({ setAllowForwardNav, onOpen }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const scalingPoints = [
    "A platform that supports your workflow: manage and analyze information, collaborate with AI, and create deliverables efficiently.",
    "A library of task specific AI modules to chose from, matching your most precise needs",
    "A tool to create custom AI modules with zero technical knowledge enablingaffordable and scalable customization",
  ];

  return (
    <OnboardingFlowWrapper
      heading="Overview of the MosaiQ ecosystem"
      intro="A platform to scale AI in your work"
      showDemoBtt={true}
      onOpen={onOpen}
      width="fit-content"
    >
      <Flex w="100%" mt="39px" gap="38px">
        <UnorderedList
          w="700px"
          minWidth="700px"
          maxWidth="700px"
          p="42px"
          bg="white"
          borderRadius="3.6px"
          gap="20px"
          display="flex"
          flexDir="column"
          ml="0 !important"
        >
          {scalingPoints.map((item, index) => (
            <ListItem opacity="0.5" fontSize="16px" key={index}>
              {item}
            </ListItem>
          ))}
        </UnorderedList>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const HowItWorks = ({ setAllowForwardNav, onOpen = { onOpen } }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  return (
    <OnboardingFlowWrapper
      heading="Overview of the MosaiQ ecosystem"
      intro="How It works"
      showDemoBtt={true}
      width="900px"
      onOpen={onOpen}
    >
      <Flex w="100%" mt="39px" gap="38px">
        <Flex bg="white" p="36px" borderRadius="4px">
          <Image src={HowItWorksChart} w="830px" h="100%" />
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const CustomModules = ({ setAllowForwardNav, onOpen }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);
  const customModulePoints = [
    "Use a certain tone",
    "Bow all adjustment data",
    "Be precise and concise",
    "Write in style of your company",
    "structure results in paragraphs",
  ];

  return (
    <OnboardingFlowWrapper
      heading="Overview of the MosaiQ ecosystem"
      intro="What is a custom AI modules?"
      showDemoBtt={true}
      subParagraph="A module Is a custom AI shell, that support and perform work tasks the way you would do It"
      width="900px"
      onOpen={onOpen}
    >
      <Flex w="100%" mt="39px" gap="38px">
        <Flex
          w="100%"
          bg="white"
          px="70px"
          py="44px"
          borderRadius="4px"
          gap="45px"
        >
          <Flex w="50%">
            <Image src={customModuleImg} w="830px" h="137px" />
          </Flex>
          <Flex w="50%">
            <List
              w="100%"
              gap="15px"
              display="flex"
              flexDir="column"
              ml="0 !important"
            >
              {customModulePoints.map((item, index) => (
                <ListItem
                  opacity="0.7"
                  fontWeight="md"
                  fontSize="12px"
                  color="#191D30"
                  key={index}
                >
                  <ListIcon as={MdCircle} color="#CCC9DC" />
                  {item}
                </ListItem>
              ))}
            </List>
          </Flex>
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const PublicModules = ({ setAllowForwardNav, onOpen }) => {
  const { publicCustomAiModules } = useCustomAiModules();
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const displayedModules = useMemo(() => {
    return publicCustomAiModules.slice(0, 6);
  }, [publicCustomAiModules]);

  return (
    <OnboardingFlowWrapper
      heading="OVERVIEW OF THE MOSAIQ ECOSYSTEM"
      intro="Module Library & Editor"
      showDemoBtt={true}
      subParagraph="You will be able to choose from modules. For examples, would some of the modules seem useful to you?"
      width="900px"
      onOpen={onOpen}
    >
      <Flex w="100%" mt="39px" gap="38px">
        <Grid
          w="100%"
          bg="white"
          px="90px"
          py="35px"
          borderRadius="4px"
          gap="10px"
          columnGap="10px"
          rowGap="10px"
          gridTemplateColumns="33% 33% 33%"
        >
          {displayedModules.map((moduleData) => {
            return (
              <AiModuleBox
                key={moduleData.uid}
                moduleData={moduleData}
                isLoading={false}
                actionAlwaysOn={true}
                actionOnlyOnIcon={true}
                action="ADD"
                width="100%"
              />
            );
          })}
        </Grid>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const GetStarted = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);
  const { currentUser } = useAuth();
  const history = useHistory();

  return (
    <OnboardingFlowWrapper
      heading=""
      intro=""
      showDemoBtt={false}
      subParagraph=""
      width="900px"
    >
      <Flex
        w="100%"
        mt="39px"
        px="203px"
        pt="95px"
        pb="64px"
        bg="white"
        borderRadius="3.6px"
      >
        <Flex w="100%" gap="24px" align="center" flexDir="column">
          <Heading fontSize="30px">You&apos;re all set</Heading>
          <Flex flexDir="column" align="center" gap="32px">
            <Button
              fontSize="14px"
              color="primary"
              bg="#CCC9DC"
              onClick={() => {
                updateOnboardingCompleted(currentUser.uid);
                history.replace(addUrlParam("/user", "fromOnboarding", "true"));
              }}
            >
              Get started now!
            </Button>
            <Link
              color="#191D30"
              fontSize="14px"
              textDecor="underline"
              opacity="0.7"
              as={RouterLink}
              to="/help"
            >
              Request support at any time to learn more or get guidance
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

import { updateProfile } from "firebase/auth";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { auth, storage } from "../firebase";

export const readAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const uploadToGCS = async (url, body) => {
  const response = await fetch(url, {
    method: "PUT",
    body: body,
  });
  if (!response.ok) {
    throw new Error(`GCS upload failed: ${response.statusText}`);
  }
  return response;
};

export const generateDownloadUrl = async (filename) => {
  const url = await getDownloadURL(storageRef(storage, filename));
  return url;
};

// export const saveUserImage = async (file) => {
//   const fileName = `${uuidv4()}-${file.name}`;
//   const storageReference = storageRef(
//     storage,
//     `usersUpdatedImages/${fileName}`
//   );
//   await uploadBytes(storageReference, file);
//   const downloadUrl = await getDownloadURL(storageReference);
//   updateProfile(auth.currentUser, {
//     photoURL: downloadUrl,
//   });
//   return downloadUrl;
// };

// export const saveInsightImage = async (file) => {
//   const fileName = `${uuidv4()}-${file.name}`;
//   const storageReference = storageRef(
//     storage,
//     `manualInsightImages/${fileName}`
//   );
//   await uploadBytes(storageReference, file);
//   const downloadUrl = await getDownloadURL(storageReference);
//   return downloadUrl;
// };

export const getStorageDownloadUrl = (pathOnStorage) => {
  const storageReference = storageRef(storage, pathOnStorage);
  const downloadUrl = getDownloadURL(storageReference);
  return downloadUrl;
};

export const saveFile = async ({
  file,
  pathName,
  isProfilePicture = false,
}) => {
  const fileName = `${uuidv4()}-${file.name}`;
  const storageReference = storageRef(storage, `${pathName}/${fileName}`);
  await uploadBytes(storageReference, file);
  const downloadUrl = await getDownloadURL(storageReference);
  if (isProfilePicture) {
    updateProfile(auth.currentUser, {
      photoURL: downloadUrl,
    });
  }
  return downloadUrl;
};

import {
  Flex,
  Grid,
  GridItem,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";
import { useHistory } from "react-router-dom/";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useArticle } from "../../../Context/ArticleContext";
import { addArticleComment, deleteArticleComment } from "../../../db/comment";
import CommentModal from "../../Elements/CommentModal";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { IndividualTableRow, TABLE_PROPS } from "./IndividualTableRow";
import SelectControlButton from "./SelectArticlesControlBttn";
import { TableHead } from "./TableHead";

const customization = getCustomizationData();

export const AllArticleTable = ({ filteredArticles, display }) => {
  const history = useHistory();
  const currentSearchParams = new URLSearchParams(location.search);
  const sortByParams = currentSearchParams.get("sortBy") || "";
  const poppedArticle = currentSearchParams.get("poppedArticle");
  const [showArticleCommentModal, setShowArticleCommentModal] = useState(false);
  const { projectId } = useParams();
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [searchedArticle, setSearchedArticle] = useState([]);

  const findArticleById = () => {
    const article = filteredArticles.find(
      (article) => article.uid === currentArticleId
    );
    return article;
  };

  const { selectedArticleIds, clearSelectedArticles, setSelectedArticleIds } =
    useArticle();

  const addAllArticleToSelectedArticleContext = () => {
    const articleIds = filteredArticles.map((article) => article.uid);
    setSelectedArticleIds(articleIds);
  };

  useEffect(() => {
    if (filteredArticles) {
      if (poppedArticle) {
        const article = filteredArticles?.find(
          (article) => article?.uid === poppedArticle
        );
        if (article) {
          setSearchedArticle([article]);
        }
      } else {
        setSearchedArticle(filteredArticles);
      }
    }
  }, [poppedArticle, filteredArticles]);

  const MENU_ITEMS = [
    { name: "All", onClick: () => addAllArticleToSelectedArticleContext() },
    {
      name: "None",
      onClick: () => clearSelectedArticles(),
    },
    {
      name: "Starred",
      onClick: () => {
        const starredArticles = filteredArticles.filter(
          (article) => article.starred
        );
        const articleIds = starredArticles.map((article) => article.uid);
        setSelectedArticleIds(articleIds);
      },
    },
    {
      name: "Unstarred",
      onClick: () => {
        const unstarredArticles = filteredArticles.filter(
          (article) => !article.starred
        );
        const articleIds = unstarredArticles.map((article) => article.uid);
        setSelectedArticleIds(articleIds);
      },
    },
  ];

  return (
    <Flex mt={6} display={display}>
      <TableContainer overflow="hidden">
        <Table size="sm" variant="unstyled" w="100%">
          <HStack w="100%" gap={0} alignItems="top">
            <Grid
              alignContent="center"
              alignItems="center"
              as={Thead}
              templateColumns={`repeat(${TABLE_PROPS.gridSize}, 1fr)`}
              w="100%"
            >
              <GridItem
                userSelect="none"
                pb="10px"
                as={Th}
                colSpan={TABLE_PROPS.checkBoxAndStarred}
                overflow="hidden"
                pl="0"
                pr={`${TABLE_PROPS.checkBoxAndStarredPR - 3}px`}
                w={TABLE_PROPS.checkBoxAndStarredWidth}
              >
                <Flex
                  minW="2%"
                  justify="center"
                  display={selectedArticleIds.length > 0 ? "flex" : "none"}
                >
                  {selectedArticleIds.length > 0 && (
                    <SelectControlButton
                      icon={{ type: MdOutlineIndeterminateCheckBox }}
                      color="primary"
                      onClick={() => {
                        clearSelectedArticles();
                      }}
                      items={MENU_ITEMS}
                    />
                  )}
                </Flex>
                <Flex
                  minW="2%"
                  justify="center"
                  display={!selectedArticleIds.length > 0 ? "flex" : "none"}
                >
                  {selectedArticleIds.length === filteredArticles.length &&
                  filteredArticles.length > 0 ? (
                    <SelectControlButton
                      icon={{ type: MdCheckBox }}
                      color="#727272"
                      onClick={() => {
                        clearSelectedArticles();
                      }}
                      items={MENU_ITEMS}
                    />
                  ) : (
                    <SelectControlButton
                      icon={{ type: MdCheckBoxOutlineBlank }}
                      color="#727272"
                      onClick={() => {
                        addAllArticleToSelectedArticleContext();
                      }}
                      items={MENU_ITEMS}
                    />
                  )}
                </Flex>
              </GridItem>
              <TableHead
                text="Name"
                articleData={filteredArticles}
                history={history}
                colSpan={TABLE_PROPS.title}
                isSelected={sortByParams.includes("name")}
              />
              {customization.swapTypeForLength ? (
                <TableHead
                  text="Length"
                  articleData={filteredArticles}
                  history={history}
                  colSpan={TABLE_PROPS.type}
                  isSelected={sortByParams.includes("length")}
                  gridItemProps={{ minW: TABLE_PROPS.typeMinWidth }}
                />
              ) : (
                <TableHead
                  text="Type"
                  articleData={filteredArticles}
                  history={history}
                  colSpan={TABLE_PROPS.type}
                  isSelected={sortByParams.includes("type")}
                  gridItemProps={{ minW: TABLE_PROPS.typeMinWidth }}
                />
              )}

              <TableHead
                text="Source"
                articleData={filteredArticles}
                history={history}
                colSpan={TABLE_PROPS.source}
                isSelected={sortByParams.includes("source")}
                gridItemProps={{ minW: TABLE_PROPS.sourceMinWidth }}
              />
              <TableHead
                text="Date Added"
                articleData={filteredArticles}
                history={history}
                colSpan={TABLE_PROPS.dateAdded}
                isSelected={sortByParams.includes("date")}
                gridItemProps={{ minW: TABLE_PROPS.dateAddedMinWidth }}
              />
              {/* {customization.uniteAiInsightsAndViewer ? ( */}
              <GridItem
                userSelect="none"
                pb="10px"
                as={Th}
                colSpan={TABLE_PROPS.actions}
                overflow="hidden"
                pl="0"
                pr="0"
                minW={TABLE_PROPS.actionsMinWidth}
              />
              {/* ) : (
                <TableHead
                  text="Insights"
                  notSortAble
                  colSpan={TABLE_PROPS.actions}
                  gridItemProps={{ minW: TABLE_PROPS.actionsMinWidth }}
                />
              )} */}
            </Grid>
          </HStack>
          <Tbody>
            {searchedArticle?.map((currentArticleData, index) => {
              // Determine if the current item is the last item of its type.
              // Used to create a larger gaps between different types of articles
              // when sorting by type.
              const isLastItem =
                currentArticleData?.type !== searchedArticle[index + 1]?.type;
              return (
                <React.Fragment key={currentArticleData.uid}>
                  <IndividualTableRow
                    articleData={currentArticleData}
                    index={index}
                    showCommentModalAndUpdateProjectTitle={() => {
                      setShowArticleCommentModal(true);
                      setCurrentArticleId(currentArticleData.uid);
                    }}
                    isLastItem={isLastItem && sortByParams?.includes("type")}
                  />
                </React.Fragment>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {showArticleCommentModal && (
        <CommentModal
          close={() => {
            setShowArticleCommentModal(false);
            setCurrentArticleId(null);
          }}
          isOpen={showArticleCommentModal}
          content={findArticleById()}
          createFunc={addArticleComment}
          deleteFunc={deleteArticleComment}
          rootId={projectId}
        />
      )}
    </Flex>
  );
};

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import React, { forwardRef, useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { BiBookmark, BiLinkExternal } from "react-icons/bi";
import { BsGlobe } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { EmptyCuratedContentPage } from "../Components/Elements/EmptyCuratedContentPage";
import ImageWithIconFallback from "../Components/Elements/ImageWithIconFallback";
import PageLoader from "../Components/Elements/PageLoader";
import ViewModeMenu from "../Components/Elements/ViewModeMenu";
import SavedContentCard from "../Components/SavedContents/Card";
import { InsightPageTour } from "../Components/Tour/InsightPageTour";
import { groupSnippetsByDocument } from "../Components/Utils/curatedContent";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { useArticle } from "../Context/ArticleContext";
import { SNIPPET_TYPE_DATA } from "../data/SnippetTypeData";
import { sortSnippets } from "../db/snippet";
import useFilteredAndSortedArticles from "../hooks/useFilteredAndSortedArticles";
import useGetSnippets from "../hooks/useGetSnippets";

const customization = getCustomizationData();

const SORT_MODES = {
  BY_DOCUMENT: "By Source",
  ALL: "All",
};

function IconAndTitle({
  data,
  activeSource,
  activeDocuments,
  handleSourceHover,
}) {
  return (
    <HStack flex={1} gap={4}>
      <Tooltip hasArrow label={data.source} bg="primary" openDelay={1000}>
        <Box>
          <ImageWithIconFallback
            imgSrc={data.icon || ""}
            fallbackIcon={data.fallbackIcon || BsGlobe}
          />
        </Box>
      </Tooltip>
      <Link
        isExternal
        href={data.url}
        _hover={
          activeSource === data.uid && {
            textDecoration: "underline",
            cursor: "pointer",
          }
        }
        cursor="auto"
      >
        <Text
          fontSize="12px"
          color="black"
          fontWeight={activeDocuments.includes(data.uid) ? "bold" : "normal"}
          onMouseEnter={() => handleSourceHover(data)}
          onMouseLeave={() => handleSourceHover(null)}
        >
          {data.title}
          {activeSource === data.uid && (
            <chakra.span>
              <Icon mt={-2} as={BiLinkExternal} boxSize={4} />
            </chakra.span>
          )}
        </Text>
      </Link>
    </HStack>
  );
}

const NoSnippetsPlaceholder = () => {
  return (
    <Flex
      h="262px"
      align={"center"}
      justify={"flex-end"}
      direction={"column"}
      gap="8px"
    >
      <Icon as={BiBookmark} fontSize={"32px"} />
      <Flex pb="2px" />
      <Text fontSize={"16px"} fontWeight="medium">
        Nothing to show yet
      </Text>
      {customization.savedInsights.linkToVideo && (
        <Link href={customization.savedInsights.linkToVideo} isExternal>
          <Text opacity={0.54} fontSize={"12px"} decoration="underline">
            Watch how to save insights
          </Text>
        </Link>
      )}
    </Flex>
  );
};

const InsertedCuratedContentPage = forwardRef((props, ref) => {
  const [activeSource, setActiveSource] = useState();
  const { projectId } = useParams();
  // const dispatch = useDispatch();
  const [currentSnippets, setCurrentSnippets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState("DEFAULT");
  const [sortMode, setSortMode] = useState(SORT_MODES.BY_DOCUMENT);
  const [activeDocuments, setActiveDocuments] = useState([]);
  const [groupedSnippets, setGroupedSnippets] = useState([]);

  const { snippets } = useGetSnippets({
    projectId: projectId,
    curated: true,
  });

  const { allUnfilteredArticles: articles } = useArticle();

  const filteredAndSortedArticles = useFilteredAndSortedArticles(articles);

  const handleSourceHover = (sourceData) => {
    if (sourceData?.url) {
      setActiveSource(sourceData.uid);
    } else {
      setActiveSource(null);
    }
  };

  useEffect(() => {
    if (snippets) {
      // discard snippets which shouldn't be shown in curated content
      const filteredSnippets = snippets.filter(
        (snippet) => SNIPPET_TYPE_DATA[snippet.type]?.showInCurated
      );
      const sortedData = sortSnippets(filteredSnippets);
      setCurrentSnippets(sortedData);
      setIsLoading(false);
    }
  }, [snippets]);

  const categorizeData = (sortMode) => {
    if (sortMode === SORT_MODES.BY_DOCUMENT) {
      const categorizedResult = groupSnippetsByDocument(
        filteredAndSortedArticles,
        currentSnippets
      );
      setGroupedSnippets(categorizedResult);
      setSortMode(sortMode);
      return;
    }
    setSortMode(sortMode);
  };

  useEffect(() => {
    if (currentSnippets?.length > 0) {
      if (sortMode) {
        categorizeData(sortMode);
      }
    }
  }, [sortMode, currentSnippets, filteredAndSortedArticles]);

  const DisplaySavedContentCard = ({ contents }) => {
    return (
      <Box id="allSavedInsightsCard">
        {contents.map((content, index) => (
          <SavedContentCard
            key={content.uid}
            data={content}
            projectId={projectId}
            viewMode={viewMode}
            index={index}
          />
        ))}
      </Box>
    );
  };

  return (
    <>
      {!isLoading && <InsightPageTour />}
      <Box ref={ref} overflowY="hidden" h="95vh" className="insightPage">
        {isLoading ? (
          <Box>
            <PageLoader />
          </Box>
        ) : (
          <Droppable droppableId="CARDS" isDropDisabled={true}>
            {(provided, snapshot) => (
              <Grid
                gap={4}
                {...props}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <GridItem colSpan={7}>
                  <Box
                    display={groupedSnippets?.length > 0 ? "flex" : "none"}
                    gap={4}
                    pb={"4px"}
                  >
                    <HStack flex={1} id="selectSourceBttn" w="fit-content">
                      <Flex className="showMenuOpt" align="center" gap={2}>
                        <Text
                          fontSize="12px"
                          color="rgba(40, 44, 62, 0.54)"
                          fontWeight="500"
                        >
                          Show:
                        </Text>
                        <Menu>
                          <MenuButton
                            color="#282C3E"
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            p={0}
                            fontWeight="500"
                            fontSize="12px"
                          >
                            {sortMode}
                          </MenuButton>
                          <MenuList>
                            {Object.values(SORT_MODES).map((mode) => (
                              <MenuItem
                                key={mode}
                                onClick={() => categorizeData(mode)}
                              >
                                {mode}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      </Flex>
                    </HStack>
                    <Box id="selectCardStyle">
                      <ViewModeMenu
                        setViewMode={(id) => setViewMode(id)}
                        viewMode={viewMode}
                      />
                    </Box>
                  </Box>
                  {groupedSnippets?.length === 0 && <NoSnippetsPlaceholder />}
                  <Box
                    id="allInsightsCard"
                    h="75vh"
                    overflowY="scroll"
                    className="webkit-scrollbar-display-none"
                    pb="20"
                  >
                    {sortMode === SORT_MODES.ALL ? (
                      <Box position="relative">
                        {currentSnippets?.length > 0 ? (
                          <DisplaySavedContentCard contents={currentSnippets} />
                        ) : (
                          <EmptyCuratedContentPage />
                        )}
                      </Box>
                    ) : (
                      <Box id="insightPageSnippetList">
                        {groupedSnippets?.length > 0 &&
                          groupedSnippets.map((data) => (
                            <Box key={data.uid}>
                              <Card shadow="sm" mb="4" color="primary">
                                <CardBody p={3}>
                                  <Flex px={4}>
                                    <IconAndTitle
                                      data={data}
                                      activeSource={activeSource}
                                      activeDocuments={activeDocuments}
                                      handleSourceHover={handleSourceHover}
                                    />
                                    <HStack>
                                      <NumberOfCards
                                        activeDocuments={activeDocuments}
                                        data={data}
                                      />
                                      <SourceOpenCloseControl
                                        activeDocuments={activeDocuments}
                                        data={data}
                                        setActiveDocuments={setActiveDocuments}
                                      />
                                    </HStack>
                                  </Flex>
                                </CardBody>
                              </Card>
                              {activeDocuments.includes(data.uid) && (
                                <Box pl={10}>
                                  <DisplaySavedContentCard
                                    contents={data.cards}
                                  />
                                </Box>
                              )}
                            </Box>
                          ))}
                      </Box>
                    )}
                  </Box>
                </GridItem>
              </Grid>
            )}
          </Droppable>
        )}
      </Box>
    </>
  );
});

InsertedCuratedContentPage.displayName = "InsertedCuratedContentPage";

export default InsertedCuratedContentPage;

function SourceOpenCloseControl({ activeDocuments, data, setActiveDocuments }) {
  return activeDocuments.includes(data.uid) ? (
    <Icon
      onClick={() =>
        setActiveDocuments(activeDocuments.filter((item) => item !== data.uid))
      }
      as={ChevronUpIcon}
      boxSize={4}
      cursor="pointer"
    />
  ) : (
    <Icon
      onClick={() => {
        setActiveDocuments([...activeDocuments, data.uid]);
      }}
      as={ChevronDownIcon}
      boxSize={4}
      cursor="pointer"
    />
  );
}

function NumberOfCards({ activeDocuments, data }) {
  return (
    <Text
      fontSize="12px"
      color="black"
      fontWeight={activeDocuments.includes(data.uid) ? "bold" : "normal"}
    >
      ({data.cards?.length})
    </Text>
  );
}

import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import {
  BackwardBttn,
  BookChatBttn,
  ForwardBttn,
  LinearBgFooter,
  StepProgressCounter,
} from "../../Components/onboardingFlow/OnboardingComponents";
import { updatedOnboardingFlowData } from "../../Components/onboardingFlow/onboardingFlowData";
import { VideoModal } from "../../Components/onboardingFlow/VideoModal";
import { updateOnboardingStep } from "../../db/projectTour";

export const UpdatedOnboardingFlow = () => {
  const [allowForwardNav, setAllowForwardNav] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  const history = useHistory();
  let query = new URLSearchParams(location.search);
  let step = query.get("step") || 1;
  const paymentStatus = query.get("payment");
  const { currentUser, userDoc } = useAuth();

  const handleArrowClicks = (type) => {
    if (type === "left") {
      if (Number(step) === 1) return;
      step = Number(step) - 1;
    } else if (type === "right") {
      if (
        Number(step) === updatedOnboardingFlowData.length ||
        allowForwardNav === false
      )
        return;
      step = Number(step) + 1;
    }
    history.replace(`/welcome?step=${step}`);
    updateOnboardingStep(currentUser?.uid, Number(step));
  };

  useEffect(() => {
    step = Number(userDoc.onboardingProcess?.step || "1");
    let updatedUrl = "";
    if (step === 5 && paymentStatus === "success") {
      updatedUrl = `/welcome?step=${step + 1}&payment=${paymentStatus}`;
    } else {
      if (step) {
        updatedUrl = `/welcome?step=${step}`;
      }
    }
    history.replace(updatedUrl);
  }, [currentUser, history]);

  const currentStepData = updatedOnboardingFlowData.find(
    (data) => data.step === Number(step)
  );

  const StepComponent =
    currentStepData?.content || (() => <Box>Step not found</Box>);

  return (
    <Flex h="100vh" w="100%" pos="relative" justify="center" userSelect="none">
      <Flex
        w="100%"
        px="50px"
        pb="30px"
        align="center"
        pos="relative"
        bg="#F8F8F8"
      >
        <BackwardBttn
          setAllowForwardNav={setAllowForwardNav}
          handleArrowClicks={handleArrowClicks}
          step={Number(step)}
        />
        <Flex w="930px" m="0 auto" h="auto">
          <StepComponent
            setAllowForwardNav={setAllowForwardNav}
            onOpen={onOpen}
          />
        </Flex>
        <ForwardBttn
          allowForwardNav={allowForwardNav}
          setAllowForwardNav={setAllowForwardNav}
          handleArrowClicks={handleArrowClicks}
          showText={Number(step) === 1}
          endOfSteps={Number(step) === updatedOnboardingFlowData.length}
        />
      </Flex>
      <LinearBgFooter />
      <StepProgressCounter
        currentStep={Number(step)}
        totalSteps={updatedOnboardingFlowData.length}
      />
      <BookChatBttn />
      <VideoModal
        isOpen={isOpen}
        onClose={onClose}
        videoUrl="https://www.youtube.com/embed/DIToNTcBmw8"
      />
    </Flex>
  );
};

export default UpdatedOnboardingFlow;

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  Heading,
  MenuButton,
  MenuList,
  Skeleton,
  Text,
  MenuItem,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { handleStripeAiCreditsPayment } from "../../../services/StripeSvc";
import { MdOutlineToken } from "react-icons/md";

const AI_CREDIT_PRICES = {
  100: 1000,
  250: 2000,
  500: 3500,
  1000: 6000,
};

export const ExtraAICreditOverview = ({ extraAICredit, isLoading, canBuy }) => {
  const [credits, setCredits] = useState(500);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const BUTTON_WIDTH = "190px"; // Set this to the width of the longest option

  const handleBuyCredits = async (credits) => {
    if (isLoadingPayment) return;
    setIsLoadingPayment(true);
    const session = await handleStripeAiCreditsPayment(
      credits,
      "user/profile/plans?payment=success",
      "user/profile/plans?payment=cancel"
    );
    window.location.assign(session.url);
    setIsLoadingPayment(false);
  };

  const AiCreditsMenuItem = ({ nCredits }) => {
    return (
      <MenuItem
        px="6"
        fontWeight="black"
        borderColor="primary"
        fontSize="12px"
        color="primary"
        w="100%"
        _hover={{ bg: "primary", color: "white" }}
        onClick={() => setCredits(nCredits)}
      >
        {nCredits} Credits - ${AI_CREDIT_PRICES[nCredits] / 100} USD
      </MenuItem>
    );
  };

  return (
    <Skeleton isLoaded={!isLoading} w="100%">
      <Flex flexDir="column" h="100%" justify="space-between">
        <Flex gap={2} flexDir="column">
          <Heading fontSize="13px" fontWeight="medium">
            Extra AI Credits
          </Heading>
          <Heading
            gap={1}
            display="flex"
            alignItems="center"
            fontSize="24px"
            fontWeight="medium"
          >
            {extraAICredit}
            <MdOutlineToken fontSize="12px" style={{ marginTop: "-3px" }} />
          </Heading>
          <Flex flexDir="column" gap={2}>
            <Text color="#808080" fontWeight="500" fontSize="12px">
              Extra AI credits are used when you run out of Monthly AI credits.
            </Text>
            <Text color="#808080" fontWeight="500" fontSize="12px">
              Unused Extra AI credits will roll over to the next month.
            </Text>
          </Flex>
        </Flex>
        <Flex mt="4" gap={6} flexDir="column">
          {canBuy ? (
            <Flex gap={2}>
              <Menu placement="bottom">
                <MenuButton
                  px="3"
                  fontWeight="black"
                  border="1px solid"
                  borderColor="primary"
                  fontSize="12px"
                  color="primary"
                  minWidth={BUTTON_WIDTH}
                  as={Button}
                  rightIcon={<ChevronDownIcon fontSize="14px" />}
                >
                  {credits} Credits - ${AI_CREDIT_PRICES[credits] / 100} USD
                </MenuButton>
                <MenuList minWidth={BUTTON_WIDTH}>
                  <Flex flexDir="column" gap={2} alignItems={"center"}>
                    <AiCreditsMenuItem nCredits={100} />
                    <AiCreditsMenuItem nCredits={250} />
                    <AiCreditsMenuItem nCredits={500} />
                    <AiCreditsMenuItem nCredits={1000} />
                  </Flex>
                </MenuList>
              </Menu>
              <Button
                fontSize="12px"
                bg="primary"
                color="white"
                onClick={() => handleBuyCredits(credits)}
                isLoading={isLoadingPayment}
              >
                Buy
              </Button>
            </Flex>
          ) : (
            <Text color="#808080" fontSize="12px" fontWeight="bold">
              It is possible to buy extra AI credits only if you have a premium
              plan.
            </Text>
          )}
        </Flex>
      </Flex>
    </Skeleton>
  );
};

import { getProducts } from '@invertase/firestore-stripe-payments';
import { useEffect, useState } from 'react'
import { payments } from '../services/StripeSvc';

const useProducts = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      });
      // console.log("products", products);
      setProducts(products);
    };
    fetchProducts();
  }, []);

  return { products };
};

export default useProducts;

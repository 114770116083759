import { Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { MainProfileNav } from "../../Components/UserPage/Navigation/MainProfileNav";
export const UserProfileWrapper = ({ children }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });
  }, [user]);

  if (!user) {
    return (
      <Flex h="100vh" align="center" justify="center" bg="white">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary"
          size="xl"
        />
      </Flex>
    );
  }
  return (
      <Flex h="100%" position="relative">
        <Flex py={0} w="100%">
          <Flex w={{ base: "20%", "2xl": "15%" }} bg="white">
            <MainProfileNav />
          </Flex>

          <Flex px="30px" py="30px" w={{ base: "80%", "2xl": "70%" }}>
            {children}
          </Flex>
        </Flex>
      </Flex>
  );
};
